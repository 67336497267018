import React, { ReactElement } from 'react'

import { Row, Col } from 'react-bootstrap'
import Card from 'src/components/cards/Card'
import Paragraph from 'src/components/Paragraph/Paragraph'
import Icon from 'src/components/buttons/IconButton'
import { faEye, faListOl } from '@fortawesome/free-solid-svg-icons'

import { toMoney } from 'src/utils/utilities'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { formatDateFromMillis } from 'src/utils/formatters'

interface IProps {
  item: IPurchaseItem
  canSeeInventory: boolean
  seeMoneyValues: boolean
  toSeeInventory: (detail: IPurchaseDetail, serie?: string) => void
  toSeeSeries: (detail: IPurchaseDetail) => void
}

interface IBetween {
  label: string
  value: string
}

const DetailBetween = ({ label, value }: IBetween): ReactElement => {
  return (
    <div className={'space-between'}>
      <Paragraph>{label}</Paragraph>
      <Paragraph bold>{value}</Paragraph>
    </div>
  )
}

/** Show purchase item detail information
 * @param {IPurchaseItem} item To see information
 * @param {boolean} canSeeInventory Indicate if the user can see inventory
 * @param {boolean} seeMoneyValues Indicate if the user can see money values
 * @param {function} toSeeInventory Function to see inventory
 * @param {function} toSeeSeries Function to see item series
 * **/
const PurchaseDetailItem = ({
  item,
  canSeeInventory,
  seeMoneyValues,
  toSeeInventory,
  toSeeSeries,
}: IProps) => {
  // @ts-ignore
  return (
    <div>
      <Card white title={item.warehouseName || 'ítems'}>
        <Row>
          {item.items.map(i => {
            let subtotal: number, tax: number, total: number
            if (haveAnyValue(i.tax) && i.tax > 0) {
              total = i.subtotal
              tax = i.tax
              subtotal = i.subtotal - i.tax
            } else {
              subtotal = i.subtotal
              tax = 0
              total = i.subtotal
            }

            const price: number = subtotal / i.quantity

            return (
              <Col xl={12} key={i.productId}>
                <div className={'column'}>
                  <div className={'d-flex'}>
                    <div
                      style={{
                        width: canSeeInventory || i.series?.length > 0 ? '93%' : '100%',
                      }}
                      className={'px-1'}>
                      <div className={'column'}>
                        <DetailBetween
                          label={`${i.productName}`}
                          value={`${i.quantity}u`}
                        />
                        {haveAnyValue(i.batch) && (
                          <DetailBetween label={`Lote`} value={i.batch} />
                        )}
                        {haveAnyValue(i.expirationDate) && (
                          <DetailBetween
                            label={`Fecha de expiración`}
                            value={formatDateFromMillis(i.expirationDate, false, true)}
                          />
                        )}
                        {haveAnyValue(i.listCategories) &&
                          i.listCategories?.length > 0 && (
                            <div className={'ml-2'}>
                              {/* @ts-ignore */}
                              <ListVariations
                                data={[]}
                                selected={i.listCategories || []}
                              />
                            </div>
                          )}
                        {seeMoneyValues && (
                          <div>
                            <DetailBetween
                              label={`Costo unitario`}
                              value={toMoney(price)}
                            />

                            {tax > 0 && (
                              <div>
                                <DetailBetween
                                  label={`Subtotal`}
                                  value={toMoney(subtotal)}
                                />
                                <DetailBetween label={`Impuesto`} value={toMoney(tax)} />
                              </div>
                            )}

                            <DetailBetween label={`Total`} value={toMoney(total)} />
                          </div>
                        )}
                      </div>
                    </div>

                    {(canSeeInventory || i.series?.length > 0) && (
                      <div className={'column flex-row-reverse'} style={{ width: '7%' }}>
                        {canSeeInventory && (
                          <Icon
                            icon={faEye}
                            size={'1_5x'}
                            tooltip={'Ver inventario'}
                            onClick={() => toSeeInventory(i)}
                            dataCy={'iconInventory'}
                          />
                        )}
                        {i.series?.length > 0 && (
                          <Icon
                            icon={faListOl}
                            size={'1_5x'}
                            tooltip={'Ver series'}
                            onClick={() => toSeeSeries(i)}
                            dataCy={'iconSeries'}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <hr style={{ width: '100%' }} />
                </div>
              </Col>
            )
          })}
        </Row>
      </Card>
    </div>
  )
}
export default PurchaseDetailItem
