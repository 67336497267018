import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Row, Col, ProgressBar, Container } from 'react-bootstrap'
import { faEye, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'

import Button from '../buttons/Button'

import {
  types,
  getInfoReference,
  onSetModalPurchase,
} from '../../actions/utilities.actions'
import { selectReference } from '../../selectors/utilities.selector'

import { loadingSelector } from '../../selectors/loading.selector'
import {
  singleErrorSelector,
  hasErrorsSelector,
  handlerError,
  hasErrors,
} from '../../selectors/error.selector'
import { formatDateFromMillis } from '../../utils/formatters'
import Gallery, { imageTypes } from '../gallery/Gallery'

import { toMoney } from '../../utils/utilities'
import { Icon, TableV2 } from '../index'
import { selectCurrentCompany } from 'src/selectors/user.selector'
import { ListVariations } from 'src/content/Production/Categorization/Variation/ListVariations'
import { showAlert } from 'src/actions/alert.actions'
import { ReadDynamicFieldInForm } from 'src/components/CompanyDynamicFields/presentations/ReadDynamicFieldInForm'

const CustomReference = props => {
  const dispatch = useDispatch()

  const reference = useSelector(selectReference)
  const companyId = useSelector(selectCurrentCompany)
  const loading = useSelector(state => loadingSelector([types.CUSTOM_REFERENCE])(state))
  const hasError = useSelector(state => hasErrors([types.CUSTOM_REFERENCE])(state))

  const [loadingI, setLoading] = useState(true)
  const [show, setShow] = useState(false)
  const [alert, setAlert] = useState({ title: '' })
  const [action, setAction] = useState(false)

  useEffect(() => {
    if (props.show) {
      setShow(props.show)
    }
  }, [props])

  useEffect(() => {
    if (show) {
      dispatch(
        getInfoReference(props.documentModule, props.documentType, props.documentId),
      )
      setLoading(false)
    }
  }, [show])

  useEffect(() => {
    if (loading) setAction(true)
    else if (action) {
      setAction(false)
      if (hasError)
        dispatch(
          showAlert({
            ...handlerError(hasError.message),
            onConfirm: () => onHide(),
          }),
        )
    }
  }, [loading])

  const onHide = () => {
    setAction(false)
    setShow(false)
    setAlert({ ...alert, show: false })
    props.onHide()
  }

  const withDetail = item => {
    let withD = false
    if (item.headers && item.items && item.items.length > 0) withD = true
    return withD
  }

  const getHeaders = ref => {
    const headerArr = ref.headers.split(',')

    let headerRes = []
    headerArr.forEach(header => {
      if (header === 'Acción') {
        headerRes.push({
          label: 'Acción',
          value: ['value'],
          action: true,
          show: true,
          select: true,
          className: 'mini',
          custom: item => (
            <Icon
              icon={faEye}
              tooltip={'ver'}
              onClick={() => {
                dispatch(onSetModalPurchase(item.value))
              }}
            />
          ),
        })
      } else {
        let type = 'text'
        if (header === ' Descuento' || header === ' Total' || header === ' Subtotal')
          type = 'currency'

        if (header === ' Nombre' || header === ' Ítem')
          headerRes.push({
            label: header,
            show: true,
            className: 'medium',
            value: [header.trim()],
            type: type,
            custom: item => {
              return (
                <>
                  <label
                    style={{
                      display: 'flex',
                      marginBottom: '0px',
                    }}>
                    {item[header.trim()]} <br />
                  </label>
                  <div style={{ marginLeft: 15 }}>
                    {item.categorizations && (
                      <ListVariations selected={item.categorizations} hideMessage />
                    )}
                    {item.listLocations && item.listLocations.length > 0 && (
                      <ListVariations
                        selected={item.listLocations}
                        hideMessage
                        isLocations
                      />
                    )}
                  </div>
                </>
              )
            },
          })
        else
          headerRes.push({
            label: header,
            show: true,
            className: 'mini',
            value: [header.trim()],
            type: type,
          })
      }
    })
    headerRes.push({ config: true, show: true, label: '', className: 'mini' })
    return headerRes
  }

  const getItems = ref => {
    const headers = getHeaders(ref)
    let itemsRes = []
    ref.items.forEach(item => {
      let producto = {}
      item.items.forEach((val, i) => {
        producto[headers[i]['value']] = val.value
      })
      if (item.categorizations && item.categorizations.length > 0)
        producto.categorizations = item.categorizations
      if (item.listLocations && item.listLocations.length > 0)
        producto.listLocations = item.listLocations

      itemsRes.push(producto)
    })
    return itemsRes
  }

  const getDetail = texts => {
    return texts?.map(
      (text, i) =>
        text && (
          <div key={i}>
            {i === 0 ? <b>{text}</b> : text} <br />
          </div>
        ),
    )
  }

  const getStringFormated = string => {
    if (string) {
      let texts = string.split('<br/>')
      if (texts.length === 1) texts = string.split(':')
      return getDetail(texts)
    }
  }

  return (
    <div>
      <Modal
        show={show}
        centered
        size={withDetail(reference) ? 'lg' : 'md;'}
        onHide={() => onHide()}>
        <Modal.Header closeButton>
          <Modal.Title>{loadingI || loading ? 'Cargando...' : 'Detalle'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className={'invoice column'}>
            {loadingI || loading ? (
              <ProgressBar animated now={100} />
            ) : (
              <Row>
                <Col>
                  <Col className={'text-center mt-3'}>
                    <h3>
                      <b>{reference.title}</b>
                    </h3>
                    <h2>{reference.subTitle}</h2>
                  </Col>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                    }}>
                    {reference.responsibleName && (
                      <Col className={'text-center'}>
                        <h2>{reference.responsibleName}</h2>
                      </Col>
                    )}
                    {reference.subResponsibleName && (
                      <Col className={'text-center'}>
                        <h2>{reference.subResponsibleName}</h2>
                      </Col>
                    )}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      gap: '10px',
                      marginTop: '0',
                    }}>
                    <Col className={'text-center'}>
                      <h2> Código: {reference.numberReference}</h2>
                    </Col>

                    <Col className={'text-center'}>
                      <h2>
                        Fecha: &nbsp;
                        {formatDateFromMillis(reference.date)}
                      </h2>
                    </Col>
                  </div>
                  <Col className="text-center mb-3">
                    {getStringFormated(reference.description)}
                  </Col>
                  <Row>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        flexWrap: 'wrap',
                        gap: '5px',
                      }}>
                      <Col className="text-center">
                        {getDetail(['Estado', reference.status])}
                      </Col>
                      {props.balancePermission !== undefined &&
                      props.balancePermission === false ? (
                        <></>
                      ) : (
                        <Col className="text-center">
                          {getDetail(['Total', toMoney(reference.total)])}
                        </Col>
                      )}
                      {props.documentModule === 1 && props.documentType === 1 && (
                        <Col className="text-center">
                          {getDetail([
                            'No. Depósito',
                            reference.receipt ? reference.receipt : '-----',
                          ])}
                        </Col>
                      )}
                      {reference.subTitles &&
                        reference.subTitles.map((st, i) => (
                          <Col className="text-center" key={i}>
                            {getStringFormated(st)}
                          </Col>
                        ))}
                    </div>
                  </Row>
                </Col>
                <Col xl={12} className={'mt-3 ml-1'}>
                  <ReadDynamicFieldInForm fields={reference.fields ?? []} />
                </Col>
              </Row>
            )}
          </Container>
          <Row>
            {withDetail(reference) && (
              <Col xl={12} md={12} sm={12}>
                <TableV2
                  headers={getHeaders(reference)}
                  items={getItems(reference)}
                  storageKey={`custonRefFact-${props.documentType}-${props.documentModule}`}
                  customClass={'scroll-x-without-height'}
                  mobileAuto
                  balancePermission={props.balancePermission}
                />
              </Col>
            )}
          </Row>
          {props.documentType === 4 && (
            <Gallery
              company={companyId}
              imageType={imageTypes.DEPOSITS}
              entityId={props.documentId}
              noItemLegend={'Sin depósito para mostrar'}
              showUploader={false}
              maxWidth={300}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Row className={'button-container'}>
            <Button icon={faWindowClose} variant={'info'} onClick={() => onHide()}>
              Cerrar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />
    </div>
  )
}
export default CustomReference
