import { renderLineQuantities } from 'src/utils/utilitiesV2'

const getItemsFormatted = (items: ITransferDetail[]): ITransferDetail[] => {
  const itemsGrouped: ITransferDetail[] = []

  items.forEach(item => {
    const key =
      (item.baseProductId || item.productId) +
      '_' +
      item.categories +
      '_' +
      item.locations

    const index = itemsGrouped.findIndex(i => i.key === key)
    let itemToGroup: ITransferDetail
    if (index < 0) {
      itemToGroup = {
        ...item,
        baseProductQuantity:
          item.baseProductId !== null ? item.baseProductQuantity : item.realQuantity,
      }
      itemsGrouped.push(itemToGroup)
    } else {
      itemToGroup = itemsGrouped[index]
      itemToGroup.baseProductQuantity +=
        item.baseProductId !== null ? item.baseProductQuantity : item.realQuantity
      itemToGroup.realQuantity += item.realQuantity
      itemToGroup.quantity += item.quantity
      itemsGrouped[index] = itemToGroup
    }
  })

  return itemsGrouped.flatMap((item: ITransferDetail) => {
    const response: ITransferDetail[] = []

    if (item?.line?.length > 0) {
      const listProduct: ILineItem[] = renderLineQuantities(
        item.baseProductQuantity,
        item.productData.useDecimals || item.productData.decimals > 0,
        item.line,
        true,
      )
      listProduct.forEach(p =>
        response.push({
          id: p.id,
          date: item.date,
          productData: p.productData,
          productId: p.productId,
          quantity: p.quantity,
          warehouseId: item.warehouseId,
          warehouseName: item.warehouseName,
          realQuantity: p.currentQuantity,
          listLocations: item.listLocations,
          listCategories: item.listCategories,
          commentary: item.commentary,
          batch: item.batch,
        }),
      )
    } else response.push(item)
    return response
  })
}

const getItemsQuantity = (items: ITransferDetail[]): number => {
  return items.reduce((quantity, item) => quantity + item.realQuantity, 0)
}

export { getItemsQuantity, getItemsFormatted }
