import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadingSelector } from 'src/selectors/loading.selector'
import { actionTypes as pos, setPOSItemsExistence } from 'src/actions/restaurant.actions'

import {
  selectBillItems,
  selectGetTableOrder,
  selectPosSelected,
  selectTableCategoryItems,
  selectTableItems,
  selectTableSearchItems,
} from 'src/selectors/restaurant.selector'
import { getBaseExistence } from 'src/content/Restaurant/POSFunctions'

import useAllTrue from 'src/hooks/useAllTrue'

const usePOSExistence = (isSearchActive: boolean, isCategorySelected: boolean) => {
  const dispatch = useDispatch()

  const posSelected = useSelector(selectPosSelected)
  const tableOrder = useSelector(selectGetTableOrder)

  const loadingItems = useSelector(state =>
    loadingSelector([
      pos.GET_TABLE_ITEMS,
      pos.GET_TABLE_SEARCH_ITEMS,
      pos.GET_TABLE_CATEGORY_ITEMS,
      pos.REPLACE_TABLE_ITEMS,
      pos.REPLACE_TABLE_SEARCH_ITEMS,
      pos.REPLACE_TABLE_CATEGORY_ITEMS,
    ])(state),
  )

  const loadingTableOrder = useSelector(state =>
    loadingSelector([pos.GET_TABLE_ORDER])(state),
  )

  const loadingItemsBill = useSelector(state =>
    loadingSelector([pos.UPDATE_BILL_ITEMS])(state),
  )

  const items = useSelector(selectTableItems)
  const categoryItems = useSelector(selectTableCategoryItems)
  const searchItems = useSelector(selectTableSearchItems)

  const itemsBill = useSelector(selectBillItems)
  const itemsBillExistences = getBaseExistence(itemsBill)

  const [flagItems, setFlagItems] = useState<boolean>(false)
  const [flagTable, setFlagTable] = useState<boolean>(false)

  const [flagItemsBill, setFlagItemsBill] = useState<boolean>(false)
  const [flagTableObtained, setFlagTableObtained] = useState<boolean>(false)

  const [flagItemsObtained, setFlagItemObtained] = useState<boolean>(false)

  const [seeExistence, setSeeExistence] = useState<boolean>(false)

  const ready = useAllTrue([seeExistence, flagItemsObtained])

  useEffect(() => {
    if (posSelected) setSeeExistence(posSelected.seeExistence ?? false)
  }, [])

  useEffect(() => {
    if (loadingItems) setFlagItems(true)
    else if (flagItems) {
      setFlagItems(false)
      setFlagItemObtained(true)
    }
  }, [loadingItems])

  useEffect(() => {
    if (loadingTableOrder) setFlagTable(true)
    else if (flagTable) {
      setFlagTable(false)
      setFlagTableObtained(true)
      setSeeExistence(tableOrder.seeExistence)
    }
  }, [loadingTableOrder])

  useEffect(() => {
    if (!ready) return
    setFlagItemObtained(false)

    const existenceList = new Map<string, IItemPOS[]>()

    if (isSearchActive) {
      existenceList.set('posItemsExistenceSearch', searchItems)
    } else if (isCategorySelected) {
      existenceList.set('posItemsExistenceCategory', categoryItems)
    } else {
      existenceList.set('posItemsExistence', items)
    }

    setUp(existenceList)
  }, [ready])

  useEffect(() => {
    if (!flagTableObtained) return
    if (!seeExistence) return
    if (loadingItemsBill) setFlagItemsBill(true)
    else if (flagItemsBill) {
      setFlagItemsBill(false)

      const existenceList = new Map<string, IItemPOS[]>()
      existenceList.set('posItemsExistence', items)
      if (isSearchActive) {
        existenceList.set('posItemsExistenceSearch', searchItems)
      } else if (isCategorySelected) {
        existenceList.set('posItemsExistenceCategory', categoryItems)
      }
      setUp(existenceList)
    }
  }, [loadingItemsBill])

  const setUp = (list: Map<string, IItemPOS[]>) => {
    dispatch(setPOSItemsExistence(list, itemsBillExistences))
  }
}
export default usePOSExistence
