import './RecipeV2/RecipeItems.scss'

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import { Media } from 'react-breakpoints'
import { Title, Card, Button, Paragraph, Icon, TableV2, FABV2 } from 'src/components'
import {
  faCheckDouble,
  faEdit,
  faEye,
  faPlusCircle,
  faTrash,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons'

import { onGetEnumerations } from 'src/actions/enumeration.actions'
import { selectEnumerations } from 'src/selectors/enumeration.selector'

import {
  actionTypes,
  onCreateRecipeTransformation,
} from 'src/actions/transformation.actions'

import { getWarehouseLite } from 'src/actions/warehouse.actions'

import { showAlert } from 'src/actions/alert.actions'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { loadingSelector } from 'src/selectors/loading.selector'

import CreateOrUpdateRecipe from './RecipeV2/CreateOrUpdateRecipe'
import { ConversionWarehouse } from './ConversionType/ConversionWarehouse'

interface IUpdateManufactureProps {
  show: boolean
  fast: boolean
  showQuantity: boolean
  save: boolean
  recipe: IRecipe
  quantity: number
  title: string
}

/**
 * Page where multiple manufactures can be created
 * */
const CreateManufacture = () => {
  const dispatch = useDispatch()

  const enumerations: IEnumeration[] = useSelector(selectEnumerations)

  const loadingManufacture = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_TRANSFORMATION])(state),
  )
  const errorManufacture = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_TRANSFORMATION])(state),
  )

  const [flag, setFlag] = useState<boolean>(false)

  const [seeEnumerations, setSeeEnumerations] = useState<boolean>(false)
  const [manufactures, setManufactures] = useState<IManufacture[]>([])
  const [updateManufacture, setUpdateManufacture] = useState<IUpdateManufactureProps>({
    show: false,
    fast: false,
    showQuantity: false,
    save: false,
    recipe: undefined,
    quantity: 1,
    title: '',
  })

  useEffect(() => {
    dispatch(onGetEnumerations(false))
    dispatch(getWarehouseLite())
  }, [])

  useEffect(() => {
    if (loadingManufacture) setFlag(true)
    else if (flag) {
      setFlag(false)
      const alert = errorManufacture
        ? {
            ...handlerError(errorManufacture.message),
          }
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              window.history.back()
            },
          }

      dispatch(showAlert(alert))
    }
  }, [loadingManufacture])

  const onValidate = () => {
    if (manufactures.length === 0)
      dispatch(
        showAlert({
          ...handlerError('Es necesario seleccionar al menos una receta.'),
        }),
      )
    else setUpdateManufacture({ ...updateManufacture, showQuantity: true, save: true })
  }

  const onSave = data => {
    const request = {
      conversionDescription: data.conversionDescription,
      fromWarehouseId: data.fromWarehouse.value,
      toWarehouseId: data.toWarehouse.value,
      isTransformation: true,
      onConsignment: false,
      detailList: manufactures.map(manufacture => {
        return {
          enumeration: manufacture.recipe.id,
          quantity: manufacture.quantity,
          name: manufacture.recipe.name,
          description: manufacture.recipe.description,
          products: manufacture.recipe.items.map(item => ({
            productId: item.product,
            quantity: item.quantity,
            detailType: item.type,
            expireDate: item.date,
            batch: item.inBatches ? item.batch : null,
          })),
        }
      }),
    }
    dispatch(onCreateRecipeTransformation(request))
  }

  const onDeleteManufacture = (manufacture: IManufacture) => {
    const index: number = manufactures.findIndex(
      m => m.recipe.id === manufacture.recipe.id,
    )
    manufactures.splice(index, 1)
    setManufactures([...manufactures])
  }

  const onUpdateManufacture = (quantity: number, recipe: IRecipe) => {
    const index: number = manufactures.findIndex(m => m.recipe.id === recipe.id)
    if (index >= 0) {
      manufactures[index].quantity = quantity
      manufactures[index].recipe = recipe
    } else manufactures.push({ quantity, recipe })
    setManufactures([...manufactures])
  }

  const formatRecipeByEnumeration = (enumeration: IEnumeration): IRecipe => {
    return {
      id: enumeration.id,
      name: enumeration.name,
      description: enumeration.description,
      items: enumeration.enumerationDetails.map(enumDetail => ({
        product: enumDetail.productId,
        name: enumDetail.productName,
        code: enumDetail.productCode,
        quantity: enumDetail.quantity,
        decimals: enumDetail.decimals,
        type: enumDetail.detailType,
      })),
    }
  }

  const renderRecipeList = (
    <Row>
      {enumerations?.map(enumeration => (
        <Col key={enumeration.id} xl={12}>
          <div className={'d-flex b-item'}>
            <div className={'column'} style={{ width: '90%' }}>
              <Paragraph>{enumeration.name}</Paragraph>
              <Paragraph dim>{enumeration.description}</Paragraph>
            </div>
            <div className={'column'}>
              <Icon
                icon={faEye}
                size={'1_5x'}
                tooltip={'Ver receta'}
                onClick={() => {
                  setUpdateManufacture({
                    show: true,
                    fast: false,
                    showQuantity: false,
                    save: false,
                    quantity: 1,
                    title: 'Selección de receta',
                    recipe: formatRecipeByEnumeration(enumeration),
                  })
                  setSeeEnumerations(false)
                }}
                color={'primary'}
              />
              <Icon
                icon={faPlusCircle}
                size={'1_5x'}
                tooltip={'Agregar receta'}
                onClick={() => {
                  setUpdateManufacture({
                    show: false,
                    fast: false,
                    showQuantity: true,
                    save: false,
                    quantity: 1,
                    title: 'Selección de receta',
                    recipe: formatRecipeByEnumeration(enumeration),
                  })
                  setSeeEnumerations(false)
                }}
                color={'secondary'}
              />
            </div>
          </div>
        </Col>
      ))}
    </Row>
  )

  const headers: ITableHeader[] = [
    {
      show: true,
      label: 'Receta',
      type: 'text',
      value: ['recipe', 'name'],
    },
    {
      show: true,
      label: 'Descripción',
      type: 'text',
      value: ['recipe', 'description'],
    },
    {
      show: true,
      label: 'Cant',
      type: 'number',
      value: ['quantity'],
    },
    {
      show: true,
      config: true,
      type: 'text',
      value: [''],
      label: 'Acciones',
      custom: manufacture => (
        <div className={'d-flex'}>
          <Icon
            icon={faEdit}
            size={'1_5x'}
            tooltip={'Editar'}
            onClick={() =>
              setUpdateManufacture({
                fast: false,
                show: true,
                save: false,
                recipe: manufacture.recipe,
                showQuantity: false,
                quantity: manufacture.quantity,
                title: 'Edición de la receta',
              })
            }
            color={'primary'}
          />
          <Icon
            icon={faTrash}
            size={'1_5x'}
            tooltip={'Eliminar'}
            onClick={() => onDeleteManufacture(manufacture)}
            color={'secondary'}
          />
        </div>
      ),
    },
  ]

  return (
    <Media>
      {({ breakpoints, currentBreakpoint }) => {
        const tablet = breakpoints[currentBreakpoint] >= breakpoints.tablet

        return (
          <div>
            <Title
              title={'Crear manufactura'}
              action
              dontHide
              actionTitle={'Conversión libre'}
              onClick={() =>
                setUpdateManufacture({
                  show: true,
                  fast: true,
                  showQuantity: false,
                  save: false,
                  quantity: 1,
                  recipe: undefined,
                  title: 'Conversión libre',
                })
              }
            />

            <Row>
              {tablet && (
                <Col xl={4} lg={4} md={5} sm={12} xs={12}>
                  <Card title={'Recetas'} white>
                    <div className={'manufacture-recipe-container'}>
                      {renderRecipeList}
                    </div>
                  </Card>
                </Col>
              )}
              <Col
                xl={tablet ? 8 : 12}
                lg={tablet ? 8 : 12}
                md={tablet ? 7 : 12}
                sm={12}
                xs={12}>
                <Card
                  title={'Manufacturas'}
                  white
                  button={
                    !tablet ? (
                      <Button color={'accent'} onClick={() => setSeeEnumerations(true)}>
                        Ver recetas
                      </Button>
                    ) : undefined
                  }>
                  <TableV2
                    // @ts-ignore
                    headers={headers}
                    items={manufactures}
                  />
                </Card>
              </Col>
            </Row>

            <CreateOrUpdateRecipe
              title={updateManufacture.title}
              show={updateManufacture.show}
              onHide={update => {
                if (!update) setUpdateManufacture({ ...updateManufacture, show: false })
              }}
              onCallBack={recipe =>
                setUpdateManufacture({
                  ...updateManufacture,
                  show: false,
                  recipe: { ...recipe, id: recipe.id || new Date().valueOf() },
                  showQuantity: true,
                })
              }
              customRecipe={updateManufacture.recipe}
              fast={updateManufacture.fast}
            />

            <ConversionWarehouse
              quantity={updateManufacture.quantity}
              show={updateManufacture.showQuantity}
              onHide={() =>
                setUpdateManufacture({ ...updateManufacture, showQuantity: false })
              }
              onSave={response => {
                if (updateManufacture.save) onSave(response)
                else onUpdateManufacture(response.quantity, updateManufacture.recipe)

                setUpdateManufacture({
                  ...updateManufacture,
                  showQuantity: false,
                  save: false,
                })
              }}
              hideQuantity={updateManufacture.save}
              fast={updateManufacture.save}
            />

            <Modal
              show={seeEnumerations}
              size={'lg'}
              centered
              onHide={() => setSeeEnumerations(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Listado de recetas</Modal.Title>
              </Modal.Header>
              <Modal.Body className={'custom-modal-body'}>{renderRecipeList}</Modal.Body>
              <Modal.Footer>
                <Row className={'container-buttons'}>
                  <Button
                    color={'secondary'}
                    icon={faWindowClose}
                    onClick={() => setSeeEnumerations(false)}>
                    Cerrar
                  </Button>
                </Row>
              </Modal.Footer>
            </Modal>

            <FABV2
              show
              title={'Crear'}
              icon={faCheckDouble}
              loading={loadingManufacture}
              onClick={() => onValidate()}
            />
          </div>
        )
      }}
    </Media>
  )
}
export default CreateManufacture
