import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchDynamicFieldsByEntityWaste } from 'src/actions/dynamicfield.actions'
import { useDynamicFields } from 'src/components/CompanyDynamicFields/hook/useDynamicFields'
import { selectEntityWasteFields } from 'src/selectors/dynamicFields.selector'

export const useWasteDynamicFields = (withValues?: DynamicFieldRequest[]) => {
  const dispatch = useDispatch()
  const entityFields = useSelector(selectEntityWasteFields)

  useEffect(() => {
    dispatch(fetchDynamicFieldsByEntityWaste())
  }, [])

  return useDynamicFields(entityFields, withValues)
}
