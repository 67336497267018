import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal, Row, Col } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import Alert from 'sweetalert-react'
import Stepper from 'react-stepzilla'

import {
  FormText,
  Icon,
  Switch,
  Card,
  Title,
  Action,
  FABV2,
  Button,
  ItemsToSelect,
} from 'src/components'

import {
  actionTypes as typeP,
  actionTypes,
  actionTypes as typesPr,
  getProviderByNit,
  getProviders,
  onCreateProvider,
} from 'src/actions/producer.actions'
import { selectAllProviders } from 'src/selectors/producer.selector'

import { getWarehousesByUser } from 'src/actions/warehouse.actions'

import {
  actionTypes as typesP,
  setInfo,
  onCreatePurchase,
  seeAuto,
  getPurchase,
  onCreateOrderPurchase,
  onChangeOrderPurchase,
  updatePurchase,
} from 'src/actions/purchase.actions'
import { selectPurchase, selectSinglePurchase } from 'src/selectors/purchase.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  hasErrorsSelector,
  singleErrorSelector,
  handlerError,
  handlerSuccess,
  handlerInfo,
} from 'src/selectors/error.selector'

import { isAllowed } from 'src/selectors/modules.selector'

import { trim, valNit } from 'src/selectors/utilities.selector'

import Form from './PurchaseForm'
import Process from './PurchaseProcess'
import {
  faCheckDouble,
  faFileInvoice,
  faIdCard,
  faSave,
} from '@fortawesome/free-solid-svg-icons'
import CustomCreate from 'src/components/inputs/Creable/CustomCreate'
import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectCompanyFieldEditPricePurchase,
  selectCompanyFieldValue,
} from 'src/selectors/company.selector'
import {
  getCompanyField,
  getCompanyFieldEditPricePurchase,
} from 'src/actions/company.actions'
import { onSetModalPurchase } from 'src/actions/utilities.actions'
import { actionTypes as typesC, getInfoNIT } from 'src/actions/clients.actions'
import { selectNITinfo } from 'src/selectors/clients.selector'

/* ------------- NIT --------------*/
import useDebounce from 'src/hooks/useDebounce'
import InfoNit from 'src/components/InfoNit/InfoNit'
import { toMoney } from 'src/utils/utilities'

import { Country } from 'src/enums/countries'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'
import { PurchasePayments } from 'src/enums/purchaseEnums'
import { expensePermissions } from 'src/enums/permissions'
import { useSession } from 'src/hooks/useSession'
import companyFields, { companyFieldsPurchase } from 'src/enums/companyFields'
import { haveAnyValue } from 'src/utils/utilitiesV2'
import { identifications } from 'src/enums/identificationTypes'
import { getPurchaseDefaultIdentification } from 'src/content/Purchase/Detail/PurchaseDetail/getPurchaseFunctions'
import { usePurchaseDynamicFields } from 'src/content/Purchase/hooks/usePurchaseDynamicFields'
import {
  mapFieldsToRequest,
  validateRequiredFields,
} from 'src/components/CompanyDynamicFields/DynamicFieldFunctions'
import { showAlert } from 'src/actions/alert.actions'

let interval = null

const NewPurchase = props => {
  const dispatch = useDispatch()

  const country = useSelector(selectCompanyCountry)

  const user = useSelector(selectCurrentUser)
  const purchase = useSelector(selectPurchase)
  const infoNIT = useSelector(selectNITinfo)

  const created = useSelector(state => state.purchase.created)
  const loading = useSelector(state => loadingSelector([typesP.CREATE])(state))
  const hasError = useSelector(state => hasErrorsSelector([typesP.CREATE])(state))
  const error = useSelector(state => singleErrorSelector([typesP.CREATE])(state))

  const loadingUS = useSelector(state => loadingSelector([typesP.UPDATE_STATUS])(state))
  const hasErrorUS = useSelector(state =>
    hasErrorsSelector([typesP.UPDATE_STATUS])(state),
  )
  const errorUS = useSelector(state => singleErrorSelector([typesP.UPDATE_STATUS])(state))
  const fieldEditPrice = useSelector(selectCompanyFieldEditPricePurchase)

  const providers = useSelector(selectAllProviders)
  const loadingProv = useSelector(state =>
    loadingSelector([actionTypes.GET_PROVIDERS])(state),
  )

  const loadingP = useSelector(state => loadingSelector([typesPr.GET_BY_NIT])(state))

  const { validateFields, dynamicFields, setFields } = usePurchaseDynamicFields(
    purchase?.extraFields,
  )

  const [alert, setAlert] = useState({ title: '' })
  const [isPurchase, setIsPurchase] = useState(true)
  const [actions, setActions] = useState({
    create: false,
    nit: false,
    get: false,
  })
  const [currentStep, setStep] = useState(0)
  const [create, setCreate] = useState({
    isLoading: false,
    options: providers,
    value: undefined,
  })
  const [provider, setProvider] = useState({ name: '', nit: '' })
  const [showCreate, setShowCreate] = useState({})

  const location = useLocation()
  const isEdit = location.pathname.includes('/editar')
  const editPurchase = useSelector(selectSinglePurchase)
  const [detailPurchase, setDetailPurchase] = useState([])
  const [actionEdit, setActionEdit] = useState(false)

  const loadingPurchase = useSelector(state =>
    loadingSelector([typesP.GET_SINGLE])(state),
  )

  const [order] = useState(window.location.href.includes('/orden'))

  const [importation] = useState(window.location.href.includes('/importaciones'))
  const [isExpense] = useState(window.location.href.includes('/gasto'))

  const [errors, setErrors] = useState({})
  const [deleted, setDeleted] = useState([])

  /*Permissions*/
  const process = useSelector(state => isAllowed(state, [1960, 6060]))
  const skipInvoicePurchase = useSelector(state => isAllowed(state, [1961, 6061]))
  const specialInvoice = useSelector(state => isAllowed(state, [1968, 6068]))
  const skipInvoiceExpense = useSelector(state =>
    isAllowed(state, [expensePermissions.skipInvoice]),
  )

  const loadingCU = useSelector(state => loadingSelector([typeP.CREATE_PROVIDER])(state))
  const errorCU = useSelector(state =>
    singleErrorSelector([typeP.CREATE_PROVIDER])(state),
  )
  const hasErrorCU = useSelector(state =>
    hasErrorsSelector([typeP.CREATE_PROVIDER])(state),
  )

  const loadingS = useSelector(state => loadingSelector([typesP.UPDATE_SIMPLE])(state))
  const errorS = useSelector(state => singleErrorSelector([typesP.UPDATE_SIMPLE])(state))
  const hasErrorS = useSelector(state => hasErrorsSelector([typesP.UPDATE_SIMPLE])(state))

  const loadingN = useSelector(state => loadingSelector([typesC.GET_NIT_INFO])(state))
  const hasErrorN = useSelector(state => hasErrorsSelector([typesC.GET_NIT_INFO])(state))

  const [session, saveSession, removeSession] = useSession(
    'kolo-' + (isExpense ? 'expense' : 'purchase'),
  )

  const [purchaseText, setPurchaseText] = useState('')

  const [selectedItems, setSelectedItems] = useState([])

  const useDrafts =
    useSelector(state =>
      selectCompanyFieldById(
        state,
        !isExpense ? companyFields.useDraftPurchases : companyFields.useDraftExpenses,
      ),
    )?.value === 'true'

  const useTaxDetailed = useSelector(state =>
    selectCompanyFieldValue(state, companyFieldsPurchase.addTaxDetailed),
  )

  useEffect(() => {
    const isPurchase = window.location.href.includes('/compras')

    setIsPurchase(isPurchase)
    dispatch(getProviders())
    dispatch(getWarehousesByUser(user.id))
    dispatch(getCompanyField(90))
    dispatch(getCompanyFieldEditPricePurchase())
    dispatch(getCompanyField(companyFields.useDraftExpenses))
    dispatch(getCompanyField(companyFields.useDraftPurchases))
    dispatch(getCompanyField(companyFieldsPurchase.addTaxDetailed))
    if (isEdit) {
      dispatch(getPurchase(props.match.params.id))
    } else {
      const initialPurchase = {
        payment: isPurchase ? null : { value: PurchasePayments.EXPENSE, label: 'Gasto' },
        nit: '',
        invoice: '',
        serie: '',
        checked: !order,
        products: [],
        discount: 0,
        warehouses: [],
        importation,
        addInventory: true,
        addPayments: true,
        emissionAt: new Date(),
      }

      dispatch(
        setInfo(initialPurchase, () => setPurchaseText(JSON.stringify(initialPurchase))),
      )
    }
  }, [])

  useEffect(() => {
    if (session !== undefined && !isEdit && useDrafts) {
      setAlert({
        ...handlerInfo(
          'Recuperación',
          '¿Desea recuperar la orden almacenada como borrador?',
        ),
        show: true,
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Sí',
        onConfirm: () => {
          saveSession(session)
          setSelectedItems(
            session.products?.map(item => ({
              ...item,
              expirationDate: item.expirationDate ? new Date(item.expirationDate) : null,
            })),
          )
          setCreate({ ...create, value: session.provider })
          setAlert({ ...alert, show: false })
          convertLineToSingleItems(session.products, session)
        },
        onCancel: () => {
          removeSession()
          setAlert({ ...alert, show: false })
        },
      })
    }
  }, [session])

  useEffect(() => {
    if (purchaseText === '' || purchaseText === JSON.stringify(purchase)) return
    setPurchaseText(JSON.stringify(purchase))
  }, [purchase])

  useEffect(() => {
    if (purchaseText !== JSON.stringify(purchase)) return
    if (!isEdit && useDrafts) {
      saveSession({
        ...purchase,
        provider: create.value,
        save: false,
        products: selectedItems.map(item => ({
          ...item,
          expirationDate: item.expirationDate?.valueOf(),
        })),
      })
    }
  }, [purchaseText])

  const formatItems = items => {
    return items.map(item => {
      return {
        ...item,
        categories: item.listCategories,
        id: item.detailId,
        quantity: item.quantity,
        subtotal: item.subtotal,
        price: item.price,
        listCategorization: item.listCategorization,
        key: item.detailId,
        expirationDate: haveAnyValue(item.expirationDate)
          ? new Date(item.expirationDate)
          : null,
      }
    })
  }

  useEffect(() => {
    if (loadingPurchase) setActionEdit(true)
    else if (actionEdit) {
      setActionEdit(false)
      setProvider({ nit: editPurchase.nit })
      const purchaseProvider = {
        id: editPurchase.providerId,
        label: editPurchase.provider,
        value: editPurchase.nit,
      }
      setCreate({
        ...create,
        value: isEdit
          ? providers.length > 0
            ? providers.find(s => s.id === editPurchase.providerId)
            : purchaseProvider
          : null,
      })
      const products = formatItems(editPurchase.details)

      setDetailPurchase(products)
      dispatch(
        setInfo({
          ...editPurchase,
          id: props.match.params.id,
          payment: {
            value: editPurchase.paymentType,
            label: editPurchase.paymentType === 1 ? 'Crédito' : 'Contado',
          },
          paymentDate: editPurchase.paymentDate
            ? new Date(editPurchase.paymentDate)
            : null,
          nit: editPurchase.nit,
          serie: editPurchase.serie,
          invoice: editPurchase.invoice,
          checked: false,
          products: products || [],
          warehouse: {
            value: editPurchase.warehouseId,
            label: editPurchase.warehouseName,
          },
          emissionAt: purchase.createdAt ? new Date(purchase.createdAt) : new Date(),
          providerName: editPurchase.provider,
        }),
      )
    }
  }, [loadingPurchase])

  useEffect(() => {
    if (loadingProv) setActions({ ...actions, get: true })
    else if (actions.get) {
      setActions({ ...actions, get: false })
      setCreate({
        ...create,
        options: providers,
      })
    }
  }, [loadingProv])

  useEffect(() => {
    if (loadingN) setActions({ ...actions, search: true })
    else if (actions.search) {
      if (hasErrorN) {
        setShowCreate({ ...showCreate, name: '' })
        setAlert({
          ...handlerError('No se pudo obtener la información con el NIT indicado'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        if (!showCreate.show) {
          setProvider({ ...provider, name: infoNIT.clientName, nit: infoNIT.NIT })
          setCreate({ ...create, value: { label: infoNIT.clientName, nit: infoNIT.NIT } })
          dispatch(
            setInfo({
              ...purchase,
              nit: infoNIT.NIT ? infoNIT.NIT : '',
              name: infoNIT.clientName,
            }),
          )
        } else {
          setShowCreate({ ...showCreate, name: infoNIT.clientName })
        }
      }
    }
  }, [loadingN])

  useEffect(() => {
    if (loading) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasError) {
        setAlert({
          ...handlerError(error.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({ ...alert, show: false })
        setProvider({ nit: '', name: '' })
        dispatch(setInfo({ products: [], warehouses: [] }))
        setTimeout(() => dispatch(onSetModalPurchase(created.id)), 500)
        props.history.goBack()
      }
    }
  }, [loading])

  useEffect(() => {
    if (loadingS) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      if (hasErrorS) {
        setAlert({
          ...handlerError(errorS.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Cambios aplicados exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(seeAuto(created.id, created.invoice))
            dispatch(setInfo({ products: [], warehouses: [] }))
            props.history.goBack()
          },
        })
      }
    }
  }, [loadingS])

  useEffect(() => {
    if (loadingUS) setActions({ ...actions, update: true })
    else if (actions.update) {
      setActions({ ...actions, update: false })
      if (hasErrorUS) {
        setAlert({
          ...handlerError(errorUS.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        setAlert({
          ...handlerSuccess('Operación creada satisfactoriamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            dispatch(seeAuto(created.id, created.invoice))
            dispatch(setInfo({ products: [], warehouses: [] }))
            props.history.goBack()
          },
        })
      }
    }
  }, [loadingUS])

  useEffect(() => {
    if (loadingP) setActions({ ...actions, nit: true })
    else if (actions.nit) {
      setActions({ ...actions, nit: false })
      dispatch(setInfo({ ...purchase, name: provider?.name || provider }))
    }
  }, [loadingP])

  useEffect(() => {
    if (loadingCU) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCU)
        setAlert({
          ...handlerError(errorCU.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Nuevo proveedor creado exitosamente'),
          onConfirm: () => {
            setCreate({
              ...create,
              value: {
                label: showCreate.name,
                name: showCreate.name,
                nit: showCreate.nit,
                value: showCreate.name,
              },
            })
            setProvider({ name: showCreate.name, nit: showCreate.nit })
            dispatch(setInfo({ ...purchase, nit: showCreate.nit, name: showCreate.name }))
            setAlert({ ...alert, show: false })
            setShowCreate({})
          },
        })
    }
  }, [loadingCU])

  const save = p => {
    if (!validateFields()) return

    const fieldsRequest = mapFieldsToRequest(dynamicFields)

    if (order || purchase?.paymentType === 4) {
      saveOrder(fieldsRequest)
      return
    }
    let today = new Date()
    let hour = today.getHours()
    let minute = today.getMinutes()
    let request = {
      purchase: {
        warehouseId: purchase.warehouse ? purchase.warehouse.value : null,
        paymentType: isPurchase
          ? purchase.payment?.value || PurchasePayments.CREDIT
          : PurchasePayments.EXPENSE,
        invoice: purchase.invoice || '',
        serie: purchase.serie || '',
        nit: create?.value?.nit || purchase.nit,
        providerName: create?.value?.name || purchase.name || 'Sin proveedor',
        discount: purchase.discount || 0,
        certificate: purchase.certificate,
        removeInventory: !isPurchase ? purchase.removeInventory : !purchase.addInventory,
        addPayments: purchase.addPayments,
        importation,
        conversion: purchase.importation
          ? purchase.exchangeLocal
            ? 1
            : purchase.conversion
          : null,
        bill: purchase.checked,
      },
      details: [],
      detailsRequest: [],
      createdAt: purchase.createdAt ? purchase.createdAt.valueOf() : null,
      emissionAt: purchase.emissionAt
        ? purchase.emissionAt.setHours(hour, minute, 0, 0).valueOf()
        : null,
      paymentDate: purchase.paymentDate
        ? purchase.paymentDate.setHours(0, 0, 0, 0).valueOf()
        : null,
      categories: purchase.categories ? purchase.categories.map(s => s.id) : null,
      fieldsRequest,
    }
    if (p) {
      request.details = p
    } else {
      request[isEdit ? 'detailsRequest' : 'details'] = Object.assign(
        [],
        purchase.products.map(p => ({
          productId: p.productId,
          quantity: p.quantity,
          price: p.price, //purchase.exchangeLocal ? p.price / purchase.conversion : p.price,
          subtotal: Number(p.subTotal),
          tax: !p.expense
            ? p.tax //  purchase.exchangeLocal
            : //  ? p.tax / purchase.conversion
              // : p.tax
              null,
          purchasePrice: haveAnyValue(p.purchasePrice)
            ? Number(p.purchasePrice.toFixed(2))
            : undefined,
          expense: p.expense,
          customPrice: p.customPrice,
          date: p.expirationDate ? p.expirationDate.valueOf() : null,
          purchaseDetailSeries: p.active
            ? p.series.map(d => ({ quantity: 1, serie: d }))
            : [],
          variations: p.listVariations
            ? p.listVariations.map(x => {
                return x.id
              })
            : [],
          listIdLocations: p.listLocations
            ? p.listLocations.map(x => {
                return x.value || x.id
              })
            : [],
          edited: p.edited !== null ? p.edited : false,
          detailId: p.detailId,
          batch: p.inBatches ? p.batch : null,
        })),
      )
      if (isEdit)
        deleted.forEach(product => {
          const { detailId } = product
          request.detailsRequest.push({
            detailId: detailId ? detailId : null,
            deleted: true,
          })
        })
    }

    if (isEdit) {
      dispatch(updatePurchase(purchase.id, request))
    } else {
      setPurchaseText('')
      removeSession()

      dispatch(
        onCreatePurchase(
          request,
          purchase.description,
          purchase.confirmBy ? purchase.confirmBy.value : null,
        ),
      )
    }
  }

  const saveOrder = fieldsRequest => {
    let today = new Date()
    let hour = today.getHours()
    let minute = today.getMinutes()

    let request = {
      purchase: {
        warehouseId: !order && purchase.warehouse ? purchase.warehouse.value : null,
        paymentType: isPurchase ? purchase?.payment?.value : 3,
        invoice: (!order && purchase.invoice) || '',
        serie: (!order && purchase.serie) || '',
        nit: purchase.nit,
        discount: 0,
        providerName: purchase.name || 'Sin proveedor',
      },
      details: Object.assign(
        [],
        purchase.products.map(p => ({
          productId: p.productId,
          quantity: p.quantity,
          price: fieldEditPrice ? p.price : Number(p.subTotal || 0) / p.quantity,
          date: null,
          purchaseDetailSeries: [],
          variations: p.listVariations
            ? p.listVariations.map(x => {
                return x.id
              })
            : [],
          listIdLocations: p.listLocations
            ? p.listLocations.map(x => {
                return x.value
              })
            : [],
        })),
      ),
      emissionAt:
        !order && purchase.emissionAt
          ? purchase.emissionAt
            ? purchase.emissionAt.setHours(hour, minute, 0, 0).valueOf()
            : null
          : null,
      categories: purchase.categories ? purchase.categories.map(s => s.id) : null,
      fieldsRequest,
    }

    setAlert({
      show: true,
      title: isPurchase
        ? isEdit
          ? 'Actualizar compra'
          : 'Registrar compra'
        : isEdit
        ? 'Actualizar gasto'
        : 'Registrar gasto',
      text: `¿Desea ${isEdit ? 'actualizar' : 'registrar'} ${
        isPurchase ? `la orden de compra` : 'la orden del gasto'
      }?`,
      type: 'info',
      confirmButtonText: 'Registrar',
      confirmButtonColor: '#226095',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      onCancel: () => setAlert({ alert: { ...alert, show: false } }),
      onConfirm: () => {
        setAlert({ ...alert, show: false })
        if (props.match.params.id)
          dispatch(
            onChangeOrderPurchase(
              request,
              purchase.description,
              props.match.params.id,
              order ? 1 : 0,
            ),
          )
        else dispatch(onCreateOrderPurchase(request, purchase.description, null))
      },
    })
  }

  const changeText = ({ target }) => {
    const { value, name } = target
    purchase[name] = name === 'nit' || name === 'invoice' ? trim(value) : value
    if (name === 'nit') getNit(purchase[name])
    dispatch(setInfo({ ...purchase, nit: provider.nit, name: provider.name }))
  }

  const getNit = nit => {
    if (country.id === Country.GT && valNit(nit)) {
      dispatch(getProviderByNit(nit))
    }
  }

  const createOption = label => ({
    value: '',
    label: label,
  })

  const handleChange = newValue => {
    setCreate({ ...create, value: newValue })
    if (newValue) {
      setProvider({ name: newValue.label, nit: newValue.nit ? newValue.nit : '' })
      dispatch(
        setInfo({
          ...purchase,
          nit: newValue && newValue.nit ? newValue.nit : '',
          name: newValue && newValue.label ? newValue.label : '',
        }),
      )
    } else setProvider({ name: '', nit: '' })
  }

  const handleCreate = inputValue => {
    setCreate({ ...create, isLoading: true })
    setTimeout(() => {
      const options = providers
      const newOption = createOption(inputValue)
      setCreate({ isLoading: false, options: [...options, newOption], value: newOption })
      setProvider({
        name: newOption.label,
        nit: country.id === Country.SV ? provider.nit : 'CF',
      })
    }, 500)
  }

  const validateProducts = () => {
    let d =
      purchase.products.length > 0 &&
      purchase.products.filter(p => p.quantity <= 0 || (!order && p.price <= 0))
    return d.length === 0
  }

  const handleNitSearch = useDebounce(nit => {
    if (!nit || nit === 'CF' || country.id === Country.SV || !valNit(nit)) return
    dispatch(getInfoNIT(nit))
  }, 1000)

  const changeNIT = ({ target }) => {
    const { value } = target
    setProvider({ ...provider, nit: value })
    clearTimeout(interval)
    interval = setTimeout(() => {
      if (country.id === Country.SV || valNit(value)) {
        if (value.toUpperCase() !== 'CF') {
          let array = create.options.filter(s => s.nit === value)
          if (array.length > 0) {
            setCreate({ ...create, value: array[0] })
          } else {
            handleNitSearch(value)
          }
        } else {
          let c = order.client ? order.client : {}
          c.nit = value
          setCreate({ ...create, value: c })
        }
      } else {
        if (create.value !== null) {
          setCreate({ ...create, value: null })
        }
      }
    }, 1000)
  }

  const convertLineToSingleItems = (items, aux) => {
    const products = []
    items.forEach(product => {
      product.line &&
        product.line.forEach((line, j) => {
          if (line.quantity !== null && line.quantity > 0) {
            if (
              j === 0 &&
              ((product.tax && product.tax !== 0) ||
                (product.customPrice && product.customPrice !== 0) ||
                (product.subTotal && product.subTotal !== 0))
            ) {
              products.push({
                ...line,
                warehouses: [],
                subTotal: !line.subTotal ? product.subTotal : line.subTotal,
                price: parseFloat(line.ownPrice || 0),
                tax: parseFloat(product.tax || 0),
                listVariations: product.listVariations,
                expirationDate: product.expirationDate ? product.expirationDate : null,
                customPrice: product.customPrice && parseFloat(product.customPrice || 0),
                active: product.activeSeries,
                edited: product.edited,
                detailId: product.detailId ? product.detailId : null,
                batch: product.batch,
                inBatches: product.inBatches,
              })
            } else {
              products.push({
                ...line,
                warehouses: [],
                price: parseFloat(line.ownPrice || 0),
                tax: parseFloat(line.tax || 0),
                listVariations: product.listVariations,
                expirationDate: product.expirationDate ? product.expirationDate : null,
                active: product.activeSeries,
                edited: product.edited,
                detailId: product.detailId ? product.detailId : null,
                batch: product.batch,
                inBatches: product.inBatches,
              })
            }
          }
        })
    })
    dispatch(
      setInfo({
        ...(aux ? aux : purchase),
        products,
      }),
    )
  }

  const identificationLabel =
    identifications[getPurchaseDefaultIdentification(country?.id)]
  const noValidateIdentification = country.id !== Country.GT

  return (
    <div>
      {!isEdit && (
        <Title
          title={
            isExpense
              ? `${isEdit ? 'Editar' : 'Nuevo'} gasto`
              : `${isEdit ? 'Editar' : 'Nueva'} compra`
          }
          action
          actionTitle={'Nuevo proveedor'}
          onClick={() => {
            setShowCreate({ show: true })
          }}
        />
      )}
      <Action
        action
        actionTitle={'Nuevo proveedor'}
        onClick={() => setShowCreate({ show: true })}
      />

      <Card>
        <Row>
          {!order &&
            ((skipInvoicePurchase && isPurchase) ||
              (skipInvoiceExpense && isExpense)) && (
              <Col xl={4} lg={4} md={4}>
                <Switch
                  checked={purchase.checked}
                  label={'Requerir factura'}
                  onChange={({ target }) => {
                    const { checked } = target
                    if (checked) dispatch(setInfo({ ...purchase, checked }))
                    else dispatch(setInfo({ ...purchase, checked, serie: null }))
                  }}
                />
              </Col>
            )}
          {skipInvoicePurchase &&
            isPurchase &&
            purchase.checked &&
            specialInvoice &&
            !order /*&& Number(fel.value) !== 3*/ && (
              <Col xl={8} lg={8} md={8}>
                <Switch
                  checked={purchase.certificate}
                  info={
                    'Al emitirse una factura especial, el 17% del valor total de la compra irán a la cuenta de retenciones por factura especial y el 83% del valor total de la compra irán a los flujos normales de compras.'
                  }
                  label={
                    window.innerWidth < 480
                      ? '¿Desea marcar como factura especial?'
                      : '¿Desea emitir una factura especial con la información de la compra?'
                  }
                  placement={window.innerWidth < 480 ? 'bottom' : ''}
                  onChange={({ target }) => {
                    const { checked } = target
                    dispatch(setInfo({ ...purchase, certificate: checked }))
                  }}
                />
              </Col>
            )}
        </Row>
        <Row>
          <Col xl={3} lg={3} md={3}>
            <InfoNit
              type={'text'}
              name={'nit'}
              label={identificationLabel}
              required={purchase.checked}
              prepend={<Icon icon={faIdCard} spin={loadingN && !showCreate.show} />}
              nit={provider.nit}
              disabled={loadingN && !showCreate.show}
              onChange={ev => {
                changeNIT(ev)
              }}
              noValidate={noValidateIdentification}
            />
          </Col>
          <Col xl={3} lg={3} md={3}>
            <CustomCreate
              label={'Seleccionar proveedor'}
              onChange={handleChange}
              onCreateOption={handleCreate}
              isLoading={create.isLoading}
              isDisabled={create.isLoading}
              disabled={loadingN}
              options={create.options}
              value={create.value}
              placeholder={'Buscar o seleccionar'}
              textLabel={'Nuevo proveedor: '}
              required={purchase.checked}
              subText={
                (create.value?.balance > 0 || create.value?.balanceCpp > 0) && (
                  <div className={'column'}>
                    <div className={'red'}>
                      Cuentas por pagar: {toMoney(create.value?.balanceCpp || 0)}
                    </div>
                    <div className={'blue'}>
                      Saldo a favor: {toMoney(create.value?.balance || 0)}
                    </div>
                  </div>
                )
              }
            />
          </Col>

          {!order && (
            <>
              <Col xl={3} lg={3} md={3}>
                <FormText
                  label={'No. Serie'}
                  name={'serie'}
                  value={purchase.serie}
                  placeholder={'Número de Serie'}
                  prepend={<Icon icon={faFileInvoice} />}
                  onChange={changeText}
                  error={purchase.checked && errors.serie}
                  required={purchase.checked}
                />
              </Col>

              <Col xl={3} lg={3} md={3}>
                <FormText
                  label={purchase.checked ? 'No. Factura' : 'No. Referencia'}
                  name={'invoice'}
                  placeholder={'Número de Factura'}
                  value={purchase.invoice}
                  prepend={<Icon icon={faFileInvoice} tooltip={'factura'} />}
                  onChange={changeText}
                  error={purchase.checked && errors.invoice}
                  required={purchase.checked}
                />
              </Col>
            </>
          )}
        </Row>
      </Card>
      {importation ? (
        <ItemsToSelect
          order={order}
          client={null}
          type={isPurchase ? 4 : 5}
          importation={importation}
          warehouseId={null}
          onSelected={(selected, conversion, localCurrency) => {
            const productos = []
            selected.forEach(product => {
              product.lineItems &&
                product.lineItems.forEach((line, j) => {
                  if (line.quantity !== null && line.quantity > 0) {
                    if (
                      j === 0 &&
                      ((product.tax && product.tax !== 0) ||
                        (product.customPrice && product.customPrice !== 0) ||
                        (product.subTotal && product.subTotal !== 0))
                    ) {
                      productos.push({
                        ...line,
                        warehouses: [],
                        subTotal: !line.subTotal ? product.subTotal : line.subTotal,
                        price: parseFloat(line.ownPrice || 0),
                        tax: parseFloat(product.tax || 0),
                        listVariations: product.listVariations,
                        expirationDate: product.expirationDate
                          ? product.expirationDate
                          : null,
                        customPrice:
                          product.customPrice && parseFloat(product.customPrice || 0),
                        active: product.active,
                        edited: product.edited,
                        detailId: product.detailId ? product.detailId : null,
                      })
                    } else {
                      productos.push({
                        ...line,
                        warehouses: [],
                        price: parseFloat(line.ownPrice || 0),
                        tax: parseFloat(line.tax || 0),
                        listVariations: product.listVariations,
                        expirationDate: product.expirationDate
                          ? product.expirationDate
                          : null,
                        active: product.active,
                        edited: product.edited,
                        detailId: product.detailId ? product.detailId : null,
                      })
                    }
                  }
                })
            })

            dispatch(
              setInfo({
                ...purchase,
                products: productos,
                conversion,
                exchangeLocal: localCurrency,
              }),
            )
          }}
          balancesPermission
          //details={details}
        />
      ) : (
        <ItemSelectionTable
          type={isPurchase ? 4 : 5}
          seeInputTax={useTaxDetailed}
          importation={importation}
          balancePermission
          details={detailPurchase}
          selectedItems={selectedItems}
          onSelected={(selected, deleted) => {
            setDeleted(deleted)
            setSelectedItems(selected)

            convertLineToSingleItems(selected)
          }}
        />
      )}

      <Modal
        show={purchase.show}
        centered
        size={'lg'}
        onHide={() => {
          dispatch(setInfo({ ...purchase, show: false }))
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Información necesaria</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isPurchase && process && !order ? (
            <div className={'column'}>
              <Stepper
                style={{ alignItems: 'center', display: 'flex' }}
                onStepChange={step => setStep(parseInt(step))}
                stepsNavigation={false}
                dontValidate={true}
                showNavigation={false}
                steps={[
                  {
                    name: 'Información',
                    component: (
                      <Form
                        process
                        step
                        loading={loading}
                        isPurchase={isPurchase}
                        certificate={purchase.certificate}
                        importation={importation}
                        save={() => save()}
                        noValidate={noValidateIdentification}
                        dynamicFields={dynamicFields}
                        setFields={setFields}
                      />
                    ),
                  },
                  {
                    name: 'Procesamiento',
                    component: <Process loading={loading} save={p => save(p)} />,
                  },
                ]}
              />
            </div>
          ) : (
            <Form
              loading={loading}
              order={order}
              isPurchase={isPurchase}
              dynamicFields={dynamicFields}
              setFields={setFields}
              save={() => save()}
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showCreate.show}
        centered
        size={'md'}
        onHide={() => setShowCreate({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{'Crear nuevo proveedor'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} lg={12} md={12}>
              <InfoNit
                required
                disabled={loadingCU || loadingN}
                nit={showCreate.nit}
                label={identificationLabel}
                onChange={({ target }) => {
                  const { value } = target
                  setShowCreate({ ...showCreate, nit: value, name: '' })
                  handleNitSearch(value)
                }}
                noValidate={noValidateIdentification}
              />
            </Col>
            <Col xl={12} lg={12} md={12}>
              <FormText
                disabled={loadingCU || loadingN}
                label={'Nombre del proveedor'}
                value={showCreate.name}
                required
                onChange={({ target }) => {
                  const { value } = target
                  setShowCreate({ ...showCreate, name: value })
                }}
              />
            </Col>

            <Col xl={12} lg={12} md={12}>
              <Switch
                checked={showCreate.fpeq}
                topLabel
                label={'Marcar como pequeño contribuyente'}
                onChange={({ target }) => {
                  const { checked } = target
                  setShowCreate({ ...showCreate, fpeq: checked })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingCU}
              disabled={
                !showCreate.name || (country.id === Country.GT && !valNit(showCreate.nit))
              }
              color={'primary'}
              icon={faSave}
              onClick={() => {
                dispatch(onCreateProvider(showCreate))
              }}>
              {'Crear'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Alert {...alert} />

      <FABV2
        show={
          (!order && purchase.checked
            ? country.id === Country.GT
              ? valNit(provider.nit)
              : true
            : true) && validateProducts()
        }
        icon={faCheckDouble}
        onClick={() => {
          let error = {}
          if (purchase.checked && !order) {
            if (!purchase.serie) error.serie = 'Número de serie es necesario'
            if (!purchase.invoice) error.invoice = 'Los datos de factura son necesarios'
          }
          if (purchase.products?.length > 0) {
            if (
              purchase.products.some(
                x =>
                  x.inBatches &&
                  (!haveAnyValue(x.batch) || !haveAnyValue(x.expirationDate)) &&
                  isPurchase,
              )
            )
              error.batches =
                'Existen productos que requieren lote o fecha de vencimiento'
          }
          if (Object.keys(error).length > 0) {
            setErrors(error)
            setAlert({
              ...handlerError(error[Object.keys(error).at(0)]),
              type: 'warning',
              onConfirm: () => setAlert({ ...alert, show: false }),
            })
          } else dispatch(setInfo({ ...purchase, show: true }))
        }}
        title={'Continuar'}
      />
    </div>
  )
}
export default NewPurchase
