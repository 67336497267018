import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchDynamicFieldsByEntityPurchaseExpense } from 'src/actions/dynamicfield.actions'
import { selectEntityPurchaseExpenseFields } from 'src/selectors/dynamicFields.selector'

import { useDynamicFields } from 'src/components/CompanyDynamicFields/hook/useDynamicFields'

export const usePurchaseDynamicFields = (withValues?: DynamicFieldRequest[]) => {
  const dispatch = useDispatch()
  const entityFields = useSelector(selectEntityPurchaseExpenseFields)

  useEffect(() => {
    dispatch(fetchDynamicFieldsByEntityPurchaseExpense())
  }, [])

  return useDynamicFields(entityFields, withValues)
}
