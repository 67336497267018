import 'react-tabs/style/react-tabs.css'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useLocation } from 'react-router-dom'

import { Row, Col, Modal, ProgressBar } from 'react-bootstrap'
import {
  faArchive,
  faBox,
  faFileExcel,
  faFileInvoice,
  faMoneyBill,
  faSync,
  faPlus,
  faMapPin,
  faEllipsisV,
  faWallet,
  faSave,
  faPlusCircle,
  faTimes,
  faTags,
  faEdit,
  faTh,
  faTrash,
  faPercent,
  faCheckDouble,
  faWindowClose,
  faPrint,
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons'
import Alert from 'sweetalert-react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import {
  Title,
  Card,
  Select,
  Icon,
  FAB,
  Dropdown,
  Geocoding,
  Checkbox,
  ModalTable,
  Button,
  FormText,
  FormTextField,
  CustomSummary,
  SelectedDates,
  CustomDate,
  IconImg,
  TableV2,
  Empty,
  FABV2,
  Paragraph,
} from 'src/components'

import Gallery, { imageTypes } from 'src/components/gallery/Gallery'

import CreateOrderPayment from 'src/content/Orders/OrderPayment/CreateOrderPayment'
import {
  actionTypes as orderActions,
  getAllOrders,
  getOrderByNumber,
  cashRegisterReport,
  addRetention,
  printOrder,
  onCreatePaymentSpecificOrder,
  getExcelByOrders,
  getBalanceReport,
  getAllOrdersMap,
  getOrdersCount,
  onUpdateOrderParameters,
  onCreateOrderVisit,
  changeDatesOrder,
  deliverOrder,
  updateListOrders,
  onChangeDateVisit,
  onPrintInvoice,
  getOrderVersion,
} from 'src/actions/orders.actions'

import {
  actionTypes as categorizationTypes,
  assignCategorizationToEntity,
  assignCategorizationToMultipleEntities,
  getAllCategorizations,
  getCustomCategorizationsByEntity,
} from 'src/actions/categorization.actions'

import { actionTypes as productsActions } from 'src/actions/products.actions'

import {
  selectTotalOrders,
  selectGetAllOrders,
  selectBalanceReport,
  selectGetAllOrdersMap,
  selectOrdersCount,
  selectGetAllOrderStatus,
  selectGetInvoiceByOrder,
} from 'src/selectors/orders.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import { selectUsers, selectCurrentUserRoles } from 'src/selectors/modules.selector'

import { selectCurrentModule, selectCurrentUser } from 'src/selectors/user.selector'

import { isAllowed } from 'src/selectors/modules.selector'

import {
  actionTypes as clientActions,
  getSingleClient,
} from 'src/actions/clients.actions'
import { selectSingleClient, simpleClients } from 'src/selectors/clients.selector'

import { getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectWarehouseLite as selectAllWarehousesForSelect } from 'src/selectors/warehouse.selector'

import {
  types as utilitiesActions,
  changeArchivedStatusByList,
  getArchivedData,
  onSetModalOrder,
  setModalOrderTransferInfo,
  changeDeletedStatusByList,
  onSetModalOrderPreSale,
  onRefresh,
  getModalConfirm,
  setModalOrderPaymentsInfo,
} from 'src/actions/utilities.actions'
import { selectArchivedData, selectRefresh } from 'src/selectors/utilities.selector'

import {
  actionTypes as resolutionsActions,
  downloadPrintableInvoiceByOrder,
  sendInvoiceEmail,
  exportDTE,
  exportDTEProducts,
} from 'src/actions/resolutions.actions'

import {
  selectCompanyCountry,
  selectCompanyFieldById,
  selectCompanyIsLimit,
  selectIdentificationTypes,
} from 'src/selectors/company.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import {
  handlerSuccess,
  handlerError,
  singleErrorSelector,
  hasErrorsSelector,
  hasErrors,
  handlerInfo,
} from 'src/selectors/error.selector'
import { formatDateFromMillis } from 'src/utils/formatters'

import ClientIcon from 'src/assets/images/svg/client.svg'
import InventoryReport from 'src/components/inventory/InventoryReport'
import ModalOrderSummary from 'src/components/modal/Orders/ModalOrderSummary'
import IconButton from 'src/components/buttons/IconButton'
import OrdersMap from './Map/OrdersMap'
import RightArrow from 'src/assets/v2/right-arrow.svg'
import IconFlag from 'src/assets/v2/icon-flag.svg'
import ModalOrderReturn from 'src/components/modal/Orders/ModalOrderReturn'

import WarehouseService from 'src/services/warehouse.service'
import CreateOrderVisit from './OrderVisit/CreateOrderVisit'
import OrderMassiveConfirmation from './OrderMassiveConfirmation/OrderMassiveConfirmation'

import {
  actionTypes as depositActions,
  createDeposit,
} from 'src/actions/deposits.actions'
import { selectGetAccountsForSelect } from 'src/selectors/banks.selector'
import { getAccounts } from 'src/actions/banks.actions'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import Folder from 'src/components/folders/Folder'
import { getCategorizationsByEntity } from 'src/actions/categorization.actions'
import {
  selectAllCategorizations,
  selectCategorizationAssignedForSelect,
} from 'src/selectors/categorizations.selector'
import ButtonIcon from 'src/components/buttons/IconButton'
import IconSearch from 'src/assets/v2/icon-search2.svg'
import { getAllPOSByUser, onSetPrintData } from 'src/actions/restaurant.actions'
import { selectAllPOSUser } from 'src/selectors/restaurant.selector'
import CustomCreate from 'src/components/inputs/Creable/CustomCreate'
import { selectProductsLite } from 'src/selectors/products.selector'
import ProductsService from 'src/services/products.service'
import { toMoney, validateGeoLocation } from 'src/utils/utilities'

import {
  actionTypes as restaurantActions,
  createTip,
  deleteTip,
  getTipsAvailable,
} from 'src/actions/restaurant.actions'
import { selectDiscounts } from 'src/selectors/restaurant.selector'
import { selectActivePlans } from 'src/selectors/plans.selector'
import { getCompanyField } from 'src/actions/company.actions'
import { SelectClient } from 'src/components'
import { geolocated } from 'react-geolocated'
import { orderDispatchStatus, orderDispatchType } from 'src/enums/orderDispatchStatus'
import { AlertKolo } from 'src/components/Alert/AlertKolo'
import { Country } from 'src/enums/countries'
import CreateCreditNoteSell from '../CreditNote/sell/CreateCreditNoteSell'
import OrderNullify from './OrderNullify/OrderNullify'
import { PaymentType } from 'src/enums/paymentTypes'
import { orderPermissions } from 'src/enums/permissions'
import { invoiceTicketByInstructions } from 'src/components/Printer/TicketEpson'
import { OrderHistorical } from 'src/content/Orders/OrderDetail/OrderHistorical'
import { companyFieldsSell } from 'src/enums/companyFields'

import {
  actionTypes as invoiceTypes,
  getPrintInstructionsByDocumentData,
} from 'src/actions/invoice.actions'
import { selectInvoicePrintInstructions } from 'src/selectors/invoice.selector'
import { sendPrintInstructions } from 'src/actions/printer.actions'
import { PrintInstructionId, PrintInstructionType } from 'src/enums/printerEnum'
import SelectUsers from 'src/components/custom/SelectorUsers/SelectUsers'
import { useMoneyPermission } from 'src/content/Orders/hooks/useMoneyPermission'

const payments = [
  { value: PaymentType.CASH, label: 'Contado' },
  { value: PaymentType.CREDIT, label: 'Crédito' },
  //{value: 3, label: 'Tarjeta'},
  //{value: 5, label: 'Pago inmediato'},
  { value: PaymentType.BANK_TRANSFER, label: 'Transferencia' },
  { value: PaymentType.CARD, label: 'Cobro con tarjeta' },
]

const OrdersPage = ({
  history,
  match,
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
}) => {
  const dispatch = useDispatch()

  const accounts = useSelector(selectGetAccountsForSelect)
  const country = useSelector(selectCompanyCountry)
  const identificationTypes = useSelector(selectIdentificationTypes)
  const plan = useSelector(selectActivePlans)
  const roles = useSelector(selectCurrentUserRoles)
  const module = useSelector(selectCurrentModule)
  const users = useSelector(selectUsers)
  const clients = useSelector(simpleClients)
  const user = useSelector(selectCurrentUser)
  const warehouses = useSelector(selectAllWarehousesForSelect)
  const refreshValue = useSelector(selectRefresh)
  const balance = useSelector(selectBalanceReport)
  const clientCategorization = useSelector(selectCategorizationAssignedForSelect)
  const categorization = useSelector(selectAllCategorizations)
  const invoices = useSelector(selectGetInvoiceByOrder)
  const isLimit = useSelector(selectCompanyIsLimit)
  const productSelector = useSelector(selectProductsLite)
  const posUser = useSelector(selectAllPOSUser)
  const singleClient = useSelector(selectSingleClient)
  const instructions = useSelector(selectInvoicePrintInstructions)

  const loadingSingleClient = useSelector(state =>
    loadingSelector([clientActions.GET_SINGLE_CLIENTS_REQUEST])(state),
  )
  const loadingDTE = useSelector(state =>
    loadingSelector([resolutionsActions.EXPORT_DTE])(state),
  )
  const errorDTE = useSelector(state => hasErrors([resolutionsActions.EXPORT_DTE])(state))

  const orders = useSelector(selectGetAllOrders)
  const total = useSelector(selectTotalOrders)
  const loadingOrders = useSelector(state =>
    loadingSelector([orderActions.GET_ALL_ORDERS])(state),
  )

  const loadingOrdersMap = useSelector(state =>
    loadingSelector([orderActions.GET_ALL_ORDERS_MAP])(state),
  )
  const ordersMap = useSelector(selectGetAllOrdersMap)
  const ordersCount = useSelector(selectOrdersCount)

  const archived = useSelector(selectArchivedData)
  const loadingArchivedData = useSelector(state =>
    loadingSelector([utilitiesActions.GET_ARCHIVED_DATA])(state),
  )
  const loadingArchived = useSelector(state =>
    loadingSelector([
      utilitiesActions.CHANGE_STATUS_DATA,
      orderActions.UPDATE_PARAMS,
      orderActions.ON_CREATE_VISIT_ORDER,
    ])(state),
  )
  const hasErrorArchived = useSelector(state =>
    hasErrorsSelector([
      utilitiesActions.CHANGE_STATUS_DATA,
      orderActions.UPDATE_PARAMS,
      orderActions.ON_CREATE_VISIT_ORDER,
    ])(state),
  )
  const errorArchived = useSelector(state =>
    singleErrorSelector([
      utilitiesActions.CHANGE_STATUS_DATA,
      orderActions.UPDATE_PARAMS,
      orderActions.ON_CREATE_VISIT_ORDER,
    ])(state),
  )

  const loadingOrderProducts = useSelector(state =>
    loadingSelector([orderActions.GET_PRODUCTS_ON_ORDERS])(state),
  )

  const loadingCashRegisterReport = useSelector(state =>
    loadingSelector([orderActions.CASH_REGISTER_REPORT])(state),
  )

  const loadingProductsSelector = useSelector(state =>
    loadingSelector([productsActions.GET_PRODUCTS_SELECTOR])(state),
  )

  const loadingRetention = useSelector(state =>
    loadingSelector([orderActions.ADD_RETENTION])(state),
  )
  const hasErrorRetention = useSelector(state =>
    hasErrorsSelector([orderActions.ADD_RETENTION])(state),
  )
  const errorRetention = useSelector(state =>
    singleErrorSelector([orderActions.ADD_RETENTION])(state),
  )

  const loadingConfirm = useSelector(state =>
    loadingSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )
  const hasErrorConfirm = useSelector(state =>
    hasErrorsSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )
  const errorConfirm = useSelector(state =>
    singleErrorSelector([orderActions.CONFIRM_SINGLE_ORDER])(state),
  )

  const loadingExcel = useSelector(state =>
    loadingSelector([orderActions.GET_EXCEL])(state),
  )

  const loadingEmail = useSelector(state =>
    loadingSelector([resolutionsActions.SEND_INVOICE_EMAIL])(state),
  )
  const hasErrorEmail = useSelector(state =>
    hasErrorsSelector([resolutionsActions.SEND_INVOICE_EMAIL])(state),
  )
  const errorEmail = useSelector(state =>
    singleErrorSelector([resolutionsActions.SEND_INVOICE_EMAIL])(state),
  )

  const loadingCreateDeposit = useSelector(state =>
    loadingSelector([depositActions.CREATE_DEPOSIT])(state),
  )
  const hasErrorCreateDeposit = useSelector(state =>
    hasErrorsSelector([depositActions.CREATE_DEPOSIT])(state),
  )
  const errorCreateDeposit = useSelector(state =>
    singleErrorSelector([depositActions.CREATE_DEPOSIT])(state),
  )

  const loadingChangeDate = useSelector(state =>
    loadingSelector([orderActions.CHANGES_ORDER_DATE])(state),
  )
  const hasErrorChangeDate = useSelector(state =>
    hasErrorsSelector([orderActions.CHANGES_ORDER_DATE])(state),
  )

  const loadingCategorizations = useSelector(state =>
    loadingSelector([categorizationTypes.GET_ASSIGNED, categorizationTypes.GET_ALL])(
      state,
    ),
  )

  const loadingAddCategorization = useSelector(state =>
    loadingSelector([categorizationTypes.ADD_ENTITY_CATEGORIZATION])(state),
  )
  const hasErrorAddCategorization = useSelector(state =>
    hasErrorsSelector([categorizationTypes.ADD_ENTITY_CATEGORIZATION])(state),
  )

  const loadingInvoice = useSelector(state =>
    loadingSelector([
      invoiceTypes.GET_INVOICE_PRINT_INSTRUCTIONS,
      invoiceTypes.SET_INVOICE_PRINT_INSTRUCTIONS,
    ])(state),
  )
  const hasErrorInvoice = useSelector(state =>
    hasErrors([
      invoiceTypes.GET_INVOICE_PRINT_INSTRUCTIONS,
      invoiceTypes.SET_INVOICE_PRINT_INSTRUCTIONS,
    ])(state),
  )

  const loadingUpdateList = useSelector(state =>
    loadingSelector([orderActions.EDIT_MASSIVE_VALUES])(state),
  )
  const hasErrorUpdateList = useSelector(state =>
    hasErrors([orderActions.EDIT_MASSIVE_VALUES])(state),
  )

  const orderStatus = useSelector(selectGetAllOrderStatus)

  const loadingActionRow = useSelector(state =>
    loadingSelector([
      utilitiesActions.GET_MODAL_ORDER,
      orderActions.PRINT_ORDER,
      orderActions.CONFIRM_SINGLE_ORDER,
      resolutionsActions.DOWNLOAD_PRINTABLE_INVOICE_BY_ORDER,
    ])(state),
  )

  const [customLoadingActionRow, setCustomLoadingActionRow] = useState(false)

  const automaticFel = useSelector(state => selectCompanyFieldById(state, 117))
  const fieldUpdateOrderCompleted = useSelector(state =>
    selectCompanyFieldById(state, 137),
  )

  /*permissions*/
  const isAdmin = useSelector(state => isAllowed(state, [1001, 2001, 11651]))
  const canSeeDateFilters = useSelector(state =>
    isAllowed(state, [orderPermissions.canFilterByDate]),
  )

  const canSeeMoneyPermissions = useMoneyPermission()
  const canSeeGeneralBalance = canSeeMoneyPermissions.moneyValues
  const canSeeSummative = canSeeMoneyPermissions.summativeValues

  const canSeeUtility =
    useSelector(state => isAllowed(state, [1125, 2824])) && canSeeGeneralBalance

  const canCreate = useSelector(state => isAllowed(state, [1102, 2803, 11652]))
  const canIgnoreFinalConsumerClient = useSelector(state =>
    isAllowed(state, [2818, 1118, 11668]),
  )
  const canIgnoreCoords = useSelector(state => isAllowed(state, [2810, 1159, 11673]))
  const canIgnoreQuoteCoords = useSelector(state => isAllowed(state, [6508, 6458]))

  const canEditCompleted = useSelector(state => isAllowed(state, [1147]))
  const canEdit = useSelector(state => isAllowed(state, [1103, 2804, 11653]))
  const canEditQuote = useSelector(state => isAllowed(state, [6460]))
  const canAdjustQuote = useSelector(state => isAllowed(state, [6461]))

  const canSeeInventoryOrigin = useSelector(state =>
    isAllowed(state, [1114, 2814, 11664]),
  )
  const canAddRetention = useSelector(state => isAllowed(state, [2815, 1115, 11665]))
  const canNullify = useSelector(state => isAllowed(state, [1117, 11667]))

  const canCreateVoucher = useSelector(state => isAllowed(state, [1126, 2825]))

  const canChangeOrderDate = useSelector(state => isAllowed(state, [1127, 2826]))
  const canSeeAllOrder = useSelector(state => isAllowed(state, [1129, 2828]))
  const canSeeConfirmedOrders = useSelector(state => isAllowed(state, [1130, 2829]))

  const canEditMassive = useSelector(state => isAllowed(state, [1134]))
  const canConfirmMassive = useSelector(state => isAllowed(state, [1137]))
  const canCancelMassive = useSelector(state => isAllowed(state, [1138]))

  const canDownloadReportSales =
    useSelector(state => isAllowed(state, [1136, 2831])) && canSeeGeneralBalance

  const canCertifyAfter = useSelector(state => isAllowed(state, [1139]))
  const canChangeDate = useSelector(state => isAllowed(state, [1611]))
  const canConfirmWithCompleteDispatch = useSelector(state =>
    isAllowed(state, [orderPermissions.confirmWithFullDispatch]),
  )
  const canDuplicateOrders = useSelector(state => isAllowed(state, [1168]))

  // Permisos visitas
  const canCreateVisit = useSelector(state => isAllowed(state, [1603]))
  const canSeeAllVisits = useSelector(state => isAllowed(state, [1606]))

  const [alert, setAlert] = useState({ title: '' })
  const [alertKolo, setAlertKolo] = useState({ title: '' })
  const [actions, setActions] = useState({
    updateList: false,
    dte: false,
    archive: false,
    ret: false,
    nullify: false,
    map: false,
    visit: false,
    create: false,
  })

  const location = useLocation()
  const isKolo = location.pathname.includes('/kolo')
  const isSale = location.pathname.includes('/ordenes')
  const isQuote = location.pathname.includes('/cotizaciones')
  const isVisit = location.pathname.includes('/visita')
  const isDevolution = location.pathname.includes('/devoluciones')

  const [loading, setLoading] = useState(true)
  const [seeClient, setClient] = useState({ show: false })
  const [seeUser, setUser] = useState({ show: false })
  const [selected, setSelected] = useState([])
  const [seeArchive, setSeeArchive] = useState(false)
  const [seeEmail, setEmail] = useState(false)
  const [seeDates, setDates] = useState(false)
  const [seeConfirm, setConfirmDate] = useState({ show: false })
  const [orderId, setOrderId] = useState(null)
  const [inventoryReport, setInventoryReport] = useState({ title: '', documentId: null })
  const [retention, setRetention] = useState({ show: false })
  const [cancel, setCancel] = useState({ show: false })
  const [all, setAll] = useState(false)
  const [clientModal, setClientModal] = useState({ show: false })
  const [showCreateVisitModal, setShowCreateVisitModal] = useState(false)
  const [depositModal, setDepositModal] = useState({ show: false, own: false })
  const [versions, setVersions] = useState({
    show: false,
    id: null,
  })
  const [createCreditNote, setCreateCreditNote] = useState({
    show: false,
    orderId: null,
    clientId: null,
    number: null,
  })

  const [actionRow, setActionRow] = useState(null)
  const [dateVisit, setDateVisit] = useState({ show: false, date: null, id: null })

  const [showCategorization, setShowCategorization] = useState({
    show: false,
    client: null,
    filter: false,
  })
  const [selectedCategories, setSelectedCategories] = useState([])
  const [receipt, setReceipt] = useState(null)
  const [number, setNumber] = useState(null)
  const [authNumber, setAuthNumber] = useState(null)

  const [printDocument, setPrintDocument] = useState({
    number: null,
    printTicket: false,
    printInvoice: false,
  })

  const [textFilter, setTextFilter] = useState('')
  const [filters, setFilters] = useState({
    allPreSale: true,
    deliveryUsers: [],
    deliveryMan: { value: null, label: '- Todos -' },
    dealerMan: { value: null, label: '- Todos -' },
    size: 10,
    skip: 0,
    search: null,
    start: isQuote ? null : new Date().setHours(0, 0, 0, 0),
    end: isQuote ? null : new Date().setHours(23, 59, 59, 99),
    client: { value: null, label: '- Todos -' },
    warehouse: { value: null, label: '- Todos -' },
    payment: { value: null, label: '- Todos -' },
    categories: '',
    state: { value: null, label: '- Todos -' },
    pos: { value: null, label: '- Todos -' },
    products: '',
    invoiced: { value: null, label: '- Todos -' },
    dispatchStatus: { value: null, label: '- Todos -' },
    dateType: { value: 1, label: 'De confirmación' },
  })
  const [images, setImages] = useState([])

  const setOrdersFilters = (newFilters, updateBalance = true) => {
    setFilters({ ...newFilters, updateBalance })
  }

  const [showOrdersMap, setShowOrdersMap] = useState(false)
  const [orderImagesModal, setOrderImagesModal] = useState({
    number: 0,
    imgS: [],
    show: false,
  })

  const [currentTab, setCurrentTab] = useState(0)

  const [orderReturn, setOrderReturn] = useState({})

  const [massiveEditModal, setMassiveEditModal] = useState({
    show: false,
  })

  const [showOrderSummaryModal, setShowOrderSummaryModal] = useState({
    show: false,
    params: {},
  })

  const [massiveConfirmationModal, setMassiveConfirmationModal] = useState({})

  /* ------------ Descuentos --------------- */
  const [showDiscountPercent, setShowDiscountPercent] = useState(false)
  const discounts = useSelector(selectDiscounts)
  const [modalTip, setModalTip] = useState({ show: false, percentage: 0 })
  const loadingTips = useSelector(state =>
    loadingSelector([restaurantActions.CREATE_OR_DELETE_TIP])(state),
  )
  const hasErrorTips = useSelector(state =>
    hasErrors([restaurantActions.CREATE_OR_DELETE_TIP])(state),
  )
  const [flags, setFlags] = useState({ tip: false, success: false, putPosDelete: false })

  // =========== Abonar =================
  const [paymentModal, setPaymentModal] = useState({ show: false, amount: 0, order: {} })
  const loadingConfirmPayment = useSelector(state =>
    loadingSelector([orderActions.ON_CONFIRM_PAYMENT_ORDER])(state),
  )
  const hasErrorConfirmPayment = useSelector(state =>
    hasErrorsSelector([orderActions.ON_CONFIRM_PAYMENT_ORDER])(state),
  )
  const errorConfirmPayment = useSelector(state =>
    singleErrorSelector([orderActions.ON_CONFIRM_PAYMENT_ORDER])(state),
  )

  const loadingNullifyPayment = useSelector(state =>
    loadingSelector([orderActions.ON_NULLIFY_PAYMENT])(state),
  )
  const hasErrorNullifyPayment = useSelector(state =>
    hasErrorsSelector([orderActions.ON_NULLIFY_PAYMENT])(state),
  )
  const errorNullifyPayment = useSelector(state =>
    singleErrorSelector([orderActions.ON_NULLIFY_PAYMENT])(state),
  )

  const loadingChangeDateVisit = useSelector(state =>
    loadingSelector([orderActions.CHANGE_DATE_VISIT])(state),
  )
  const hasErrorChangeDateVisit = useSelector(state =>
    hasErrorsSelector([orderActions.CHANGE_DATE_VISIT])(state),
  )
  const errorChangeDateVisit = useSelector(state =>
    singleErrorSelector([orderActions.CHANGE_DATE_VISIT])(state),
  )

  useEffect(() => {
    if (loadingInvoice) setActions({ ...actions, invoiceGet: true })
    else if (actions.invoiceGet) {
      setActions({ ...actions, invoiceGet: false })
      if (hasErrorInvoice)
        setAlert({
          ...handlerError(hasErrorInvoice.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        if (printDocument.printTicket) {
          dispatch(
            sendPrintInstructions(
              PrintInstructionId.SELL,
              PrintInstructionType.SELL,
              instructions,
            ),
          )

          dispatch(
            onSetPrintData({
              show: true,
              element: PrintInstructionId.SELL,
              customData: invoiceTicketByInstructions(instructions),
              // logo: invoices[0].posId !== null ? invoices[0]?.logo?.url : null,
            }),
          )
          setPrintDocument({ ...printDocument, printTicket: false })
        }
      }
    }
  }, [loadingInvoice])

  useEffect(() => {
    dispatch(getTipsAvailable(null))
    dispatch(getCompanyField(117))
    dispatch(getCompanyField(companyFieldsSell.canChangeInventoryReturnOptionByCompany))
  }, [])

  useEffect(() => {
    if (!roles || roles.length <= 0) return
    setTextFilter(JSON.stringify(filters))

    if (canSeeAllOrder && !isKolo) {
      dispatch(getUsersChildrenByModule(module, true))
    }
    dispatch(
      getWarehouseLite({
        all: canSeeAllOrder,
      }),
    )

    dispatch(getAllPOSByUser(user.id))
    dispatch(getAccounts(isKolo))
  }, [roles])

  useEffect(() => {
    if (loadingConfirmPayment) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorConfirmPayment) {
        setAlert({
          ...handlerError(errorConfirmPayment.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      } else {
        const previousPaidBalance = paymentModal.order.total - paymentModal.order.balance

        const paidBalance = previousPaidBalance + paymentModal.amount
        const previousPending =
          (paymentModal.order.total * paymentModal.order.advance) / 100 -
          previousPaidBalance
        const pending =
          (paymentModal.order.total * paymentModal.order.advance) / 100 - paidBalance

        if (isQuote && previousPending > 0 && pending <= 0)
          setAlert({
            ...handlerSuccess('¿Desea confirmar la cotización?'),
            title: 'El anticipo de la cotización ha sido pagado.',
            show: true,
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
            onCancel: () => setAlert({ ...alert, show: false }),
            onConfirm: () => {
              setAlert({ ...alert, show: false })
              setPaymentModal({ ...paymentModal, show: false })
              setUp()

              dispatch(getModalConfirm(paymentModal.order.id))
            },
          })
        else
          setAlert({
            ...handlerSuccess('Abono creado exitosamente'),
            onConfirm: () => {
              setAlert({ ...alert, show: false })
              setPaymentModal({ ...paymentModal, show: false })
              setUp()
            },
          })
      }
    }
  }, [loadingConfirmPayment])

  useEffect(() => {
    if (loadingCreateDeposit) setActions({ ...actions, create: true })
    else if (actions.create) {
      setActions({ ...actions, create: false })
      if (hasErrorCreateDeposit)
        setAlert({
          ...handlerError(errorCreateDeposit.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Deposito registrado exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setDepositModal({ ...depositModal, show: false })
            setUp()
          },
        })
    }
  }, [loadingCreateDeposit])

  useEffect(() => {
    if (loadingNullifyPayment) setActions({ ...actions, nullifyPayment: true })
    else if (actions.nullifyPayment) {
      if (hasErrorNullifyPayment)
        setAlert({
          ...handlerError(
            errorNullifyPayment.message || 'Ha ocurrido un error inesperado',
          ),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
      else {
        setUp()
        setAlert({
          ...handlerSuccess('Orden de pago anulada exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
      }
    }
  }, [loadingNullifyPayment, hasErrorNullifyPayment])

  useEffect(() => {
    if (loadingTips) setFlags({ ...flags, tip: true })
    else if (flags.tip) {
      setFlags({ ...flags, tip: false })
      if (hasErrorTips)
        setAlert({
          ...handlerError(hasErrorTips.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        dispatch(getTipsAvailable(null))
        setModalTip({ show: false, percentage: 0 })
        setFlags({ ...flags, success: true })
      }
    }
  }, [loadingTips])

  useEffect(() => {
    if (textFilter === '' || textFilter === JSON.stringify(filters)) return
    setTextFilter(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (textFilter !== JSON.stringify(filters)) return
    setUp(false, receipt)
  }, [textFilter])

  useEffect(() => {
    if (loadingArchived) setActions({ ...actions, archive: true })
    else if (actions.archive) {
      setActions({ ...actions, archive: false })
      if (hasErrorArchived)
        setAlert({
          ...handlerError(errorArchived.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Operación exitosa'),
          onConfirm: () => {
            setSeeArchive(false)
            setAlert({ ...alert, show: false })
            setSelected([])
            setShowCreateVisitModal(false)
            setUp()
          },
        })
    }
  }, [loadingArchived])

  useEffect(() => {
    if (!refreshValue) return
    dispatch(onRefresh(false))
    setUp()
  }, [refreshValue])

  useEffect(() => {
    if (loadingRetention) setActions({ ...actions, ret: true })
    else if (actions.ret) {
      setActions({ ...actions, ret: false })
      if (hasErrorRetention)
        setAlert({
          ...handlerError(errorRetention.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setRetention({ show: false })
        setAlert({
          ...handlerSuccess('Retención solicitada exitosamente'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingRetention])

  useEffect(() => {
    if (loadingConfirm && orderId) setActions({ ...actions, confirmOrder: true })
    else if (actions.confirmOrder) {
      setActions({ ...actions, confirmOrder: false })
      if (hasErrorConfirm)
        setAlert({
          ...handlerError(errorConfirm.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setUp()

        let printInstructions = []
        if (invoices?.length > 0) printInstructions = invoices[0].printInstructions || []

        dispatch(
          sendPrintInstructions(
            PrintInstructionId.SELL,
            PrintInstructionType.SELL,
            printInstructions,
          ),
        )

        setAlertKolo({
          title: 'Orden confirmada exitosamente',
          show: true,
          type: 'success',
          onHide: () => {
            onHide(true)
          },
          custom: (
            <div>
              <Button
                style={{ marginTop: 0 }}
                loading={loadingRetention}
                disabled={loadingArchived}
                right
                color={'secondary'}
                onH
                onClick={() => {
                  onHide(false)
                }}>
                Cerrar Detalle
              </Button>
              <Button
                style={{ marginTop: 0 }}
                disabled={loadingRetention}
                loading={loadingArchived}
                right
                color={'primary'}
                onClick={() => {
                  onHide(true)
                }}>
                Ver Facturas
              </Button>
              <Button
                color={'accent'}
                onClick={async () =>
                  dispatch(
                    onPrintInvoice({
                      active: true,
                      orderId: orderId,
                      print: false,
                      documentType: 1,
                    }),
                  )
                }>
                <Icon icon={faPrint} hab tooltip={'Imprimir factura'} />
              </Button>
              <Button
                color={'accent'}
                onClick={() => {
                  dispatch(
                    onSetPrintData({
                      show: true,
                      element: 'ticket-invoice-by-order',
                      customData: invoiceTicketByInstructions(printInstructions),
                    }),
                  )
                }}>
                <Icon icon={faTicketAlt} hab tooltip={'Imprimir ticket'} />
              </Button>
            </div>
          ),
        })
      }
    }
  }, [loadingConfirm])

  useEffect(() => {
    if (loadingDTE) setActions({ ...actions, dte: true })
    else if (actions.dte) {
      setActions({ ...actions, dte: false })

      if (errorDTE) {
        setAlert({
          ...handlerError(errorDTE.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingDTE])

  useEffect(() => {
    if (loadingChangeDateVisit) setActions({ ...actions, changeDate: true })
    else if (actions.changeDate) {
      setActions({ ...actions, changeDate: false })
      if (hasErrorChangeDateVisit)
        setAlert({
          ...handlerError(
            errorChangeDateVisit.message || 'Ha ocurrido un error inesperado',
          ),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
      else
        setAlert({
          ...handlerSuccess('Visita pospuesta exitosamente'),
          onConfirm: () => {
            setDateVisit({ show: false, date: null, id: null })
            setUp()
            setAlert({ ...alert, show: false })
          },
        })
    }
  }, [loadingChangeDateVisit])

  const onHide = invoice => {
    setAlert({ ...alert, show: false })
    setAlertKolo({ ...alertKolo, show: false })
    setConfirmDate({ ...seeConfirm, show: false })
    setOrderId(null)
    if (invoice) {
      dispatch(
        onPrintInvoice({
          active: true,
          orderId: orderId,
          print: false,
          documentType: 1,
        }),
      )
    }
  }

  useEffect(() => {
    setOrdersFilters({ ...filters, size: 10 })
  }, [currentTab])

  useEffect(() => {
    if (orders && orders.length > 0) {
      if (actions.map) {
        setActions({ ...actions, map: false })
        setShowOrdersMap(true)
      } else setActions({ ...actions, map: true })
    }
  }, [loadingOrdersMap])

  useEffect(() => {
    if (loadingEmail) setActions({ ...actions, nullify: true })
    else if (actions.nullify) {
      setActions({ ...actions, nullify: false })
      if (hasErrorEmail)
        setAlert({
          ...handlerError(errorEmail.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Correo enviado exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
          },
        })
    }
  }, [loadingEmail])

  useEffect(() => {
    if (loadingAddCategorization) setActions({ ...actions, add: true })
    else if (actions.add) {
      setActions({ ...actions, add: false })
      if (hasErrorAddCategorization)
        setAlert({
          ...handlerError('No se pudo modificar la categoría'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else dispatch(getCategorizationsByEntity('clients', showCategorization.client))
      setAlert({
        ...handlerSuccess('Categoría modificada exitosamente'),
        onConfirm: () => {
          setAlert({ ...alert, show: false })
        },
      })
    }
  }, [loadingAddCategorization])

  useEffect(() => {
    if (loadingChangeDate) setActions({ ...actions, edit: true })
    else if (actions.edit) {
      setActions({ ...actions, edit: false })
      if (hasErrorChangeDate)
        setAlert({
          ...handlerError('Ocurrió un error al cambiar la fecha'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else
        setAlert({
          ...handlerSuccess('Se hicieron los cambios exitosamente'),
          onConfirm: () => {
            setAlert({ ...alert, show: false })
            setDates({ show: false })
            setUp()
          },
        })
    }
  }, [loadingChangeDate])

  useEffect(() => {
    if (loadingUpdateList) setActions({ ...actions, updateList: true })
    else if (actions.updateList) {
      setActions({ ...actions, updateList: false })
      if (hasErrorUpdateList)
        setAlert({
          ...handlerError(hasErrorUpdateList.message),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      else {
        setUp()
        setMassiveEditModal({ show: false })
        setAlert({
          ...handlerSuccess('Operación exitosa'),
          onConfirm: () => setAlert({ ...alert, show: false }),
        })
      }
    }
  }, [loadingUpdateList])

  const getQueryParams = receipt => {
    const {
      start,
      end,
      state,
      deliveryMan,
      dealerMan,
      skip,
      size,
      search,
      client,
      warehouse,
      payment,
      categories,
      pos,
      products,
      invoiced,
      dispatchStatus,
      dateType,
      deliveryUsers,
    } = filters

    const stateId =
      currentTab === 0
        ? 1
        : currentTab === 1
        ? 7
        : currentTab === 2
        ? 8
        : currentTab === 3
        ? 3
        : null

    let vendorId
    if (isVisit) vendorId = dealerMan.value
    else if (!canSeeAllOrder && !isDevolution) vendorId = user.id
    else vendorId = dealerMan.value
    if (!vendorId) vendorId = null

    let delivererId
    if (isVisit && canSeeAllVisits) delivererId = deliveryMan.value
    else if (isVisit && !canSeeAllVisits) delivererId = user.id
    else if (canSeeAllOrder) delivererId = deliveryMan.value
    else delivererId = canSeeConfirmedOrders ? null : user.id
    if (!delivererId) delivererId = null

    return {
      start,
      end,
      client: client.value,
      warehouse: warehouse.value,
      payment: payment.value,
      deliverer: delivererId,
      vendor: vendorId,
      type: isDevolution ? 4 : isVisit ? 2 : isQuote ? 5 : 1,
      skip,
      size,
      search,
      stateId: isKolo ? stateId : state.value,
      confirm: isKolo ? null : canSeeConfirmedOrders ? null : false,
      categories,
      products,
      receipt: !receipt || receipt === '' ? null : receipt,
      posId: pos.value,
      pos: pos.value,
      invoiced: invoiced?.value,
      dispatchStatus: dispatchStatus.value,
      dateType: dateType.value,
      deliveredUsers: deliveryUsers.map(u => u.value).join(','),
    }
  }

  const setUp = (excel, receipt, editMassive, itemsSum) => {
    setLoading(false)
    const updateBalance = filters.updateBalance

    const params = getQueryParams(receipt)

    if (itemsSum) {
      setShowOrderSummaryModal({
        show: true,
        params,
      })
    } else if (editMassive) {
      dispatch(updateListOrders(editMassive, params))
    } else if (!excel) {
      dispatch(getAllOrders(params))

      if (isKolo) dispatch(getOrdersCount(params.start, params.end))

      if (updateBalance && canSeeGeneralBalance) {
        dispatch(getBalanceReport(params))
      }
    } else dispatch(getExcelByOrders(params))
  }

  const exportReportFel = type => {
    const { start, end } = filters

    if (!(start && end)) {
      setAlert({
        ...handlerInfo('Debe seleccionar un rango de fechas'),
        onConfirm: () => setAlert({ ...alert, show: false }),
      })
      return
    }

    const params = getQueryParams()

    if (type === 1) dispatch(exportDTE(params))
    else dispatch(exportDTEProducts(params))
  }

  const loadMap = (sDate, eDate) => {
    const start = new Date(sDate).setHours(0, 0, 0, 0).valueOf()
    const end = new Date(eDate).setHours(23, 59, 59, 99).valueOf()
    const {
      allPreSale,
      deliveryMan,
      dealerMan,
      skip,
      size,
      search,
      client,
      warehouse,
      payment,
      invoiced,
    } = filters

    const stateId =
      currentTab === 0
        ? 1
        : currentTab === 1
        ? 7
        : currentTab === 2
        ? 8
        : currentTab === 3
        ? 3
        : 0

    dispatch(
      getAllOrdersMap({
        start,
        end,
        client: client.value,
        warehouse: warehouse.value,
        payment: payment.value,
        deliverer: isAdmin || canSeeAllOrder ? deliveryMan.value : user.id,
        vendor: isAdmin || canSeeAllOrder ? dealerMan.value : user.id,
        type: isVisit ? 2 : 1,
        pending: allPreSale,
        skip: all ? null : skip,
        size: all ? null : size,
        search,
        linked: isKolo,
        stateId: isKolo ? stateId : null,
        confirm: isKolo ? null : seeConfirm ? null : false,
        invoiced: invoiced?.value,
      }),
    )
  }

  const goTo = route => {
    history.push(`${match.url}${route}`)
  }

  const getButtons = () => {
    return (
      <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
        {isQuote && canAdjustQuote && (
          <Icon
            style={{ marginRight: '15px' }}
            icon={faTh}
            tooltip={'Realizar ajuste de cotizaciones'}
            onClick={() => {
              history.push('/distribucion/cotizaciones/ajustes')
            }}
          />
        )}
        <Icon
          style={{ marginRight: '15px' }}
          icon={faSync}
          tooltip={'Actualizar'}
          onClick={() => {
            setOrdersFilters(filters)
            setUp()
          }}
        />
        {!isDevolution && (
          <>
            <Icon
              style={{ marginRight: '15px' }}
              color={'blue'}
              icon={faPercent}
              tooltip={'Descuentos por porcentaje'}
              onClick={() => {
                setShowDiscountPercent(true)
              }}
            />
            {isSale && (
              <Icon
                style={{ marginRight: '15px' }}
                icon={faArchive}
                tooltip={'Ver órdenes archivadas'}
                onClick={() => {
                  setSeeArchive(true)
                  dispatch(getArchivedData('order'))
                  setSelected([])
                }}
              />
            )}
            {isSale && canDownloadReportSales && (
              <Dropdown
                style={{ textTransform: 'none' }}
                tooltip={'Exportar'}
                loading={loadingExcel || loadingDTE}
                items={[
                  {
                    title: 'Exportar ventas',
                    tooltip:
                      'Exporta un excel con las órdenes de venta con los filtros aplicados',
                    action: () => setUp(true),
                    icon: faFileExcel,
                    iconColor: 'green',
                  },
                  {
                    title: 'Exportar facturas electrónicas',
                    tooltip: 'Exporta un excel con las facturas electrónicas emitidas',
                    action: () => exportReportFel(1),
                    icon: faFileInvoice,
                    iconColor: 'green',
                  },
                  {
                    title: 'Exportar ítems facturados',
                    tooltip:
                      'Exporta un excel con las facturas electrónicas emitidas por producto',
                    action: () => exportReportFel(2),
                    icon: faFileInvoice,
                    iconColor: 'green',
                  },
                ]}
              />
            )}
          </>
        )}
      </Row>
    )
  }

  const isSelected = item => selected.some(s => item.id === s.id)

  const addOrRemoveSelected = item => {
    const newSelected = [...selected]
    if (isSelected(item)) {
      setSelected(newSelected.filter(s => s.id !== item.id))
    } else {
      newSelected.push(item)
      setSelected(newSelected)
    }
  }

  const getCoords = async order => {
    let latitude = null
    let longitude = null

    setCustomLoadingActionRow(true)
    if (order.warehouse) {
      const gpsCoords = await WarehouseService.getGPSCoords(order.warehouse.id)
      if (gpsCoords && gpsCoords.length > 0) {
        latitude = gpsCoords[0].latitude
        longitude = gpsCoords[0].longitude
      } else {
        latitude = order.warehouse.latitude
        longitude = order.warehouse.longitude
      }
    }
    setUser({
      show: true,
      ...order.deliverer,
      address: order.warehouse ? order.warehouse.address : null,
      latitude,
      longitude,
    })
    setCustomLoadingActionRow(false)
  }

  const getActions = item => {
    const loading =
      loadingActionRow ||
      customLoadingActionRow ||
      (loadingInvoice && (printDocument.printTicket || printDocument.printInvoice))

    return (
      <Dropdown
        onClick={() => setActionRow(item.id)}
        loading={loading && actionRow === item.id}
        disabled={loading && actionRow !== item.id}
        items={[
          {
            show: false,
            title: 'Despachar inventario',
            action: () => setOrderReturn({ ...item, open: true, returnType: 2 }),
          },
          {
            title: 'Detalle completo',
            action: () => dispatch(onSetModalOrder(item.id)),
          },
          {
            title: 'Ver historial',
            action: () => {
              dispatch(getOrderVersion(item.id))
              setVersions({ show: true, id: item.id })
            },
          },
          {
            title: `Posponer ${item.paymentType === 1 ? 'Despacho' : 'Visita'}`,
            show: isVisit && item.status === 1 && item.type === 2 && canChangeDate,
            action: () =>
              setDateVisit({ show: true, id: item.id, date: new Date(item?.enabledAt) }),
          },
          {
            show: false,
            title: 'Realizar pre venta',
            action: () => dispatch(onSetModalOrderPreSale(item.id)),
          },
          {
            show:
              !isVisit &&
              !isQuote &&
              item.status === 1 &&
              !isDevolution &&
              canConfirmWithCompleteDispatch,
            title: 'Confirmar venta',
            action: () => {
              confirmOrder(item)
            },
          },
          {
            show: false,
            title: 'Devolución de inventario',
            action: () => setOrderReturn({ ...item, open: true, returnType: 1 }),
          },
          {
            show: !!(item.images && item.images.length > 0),
            title: 'Ver imágenes adjuntas',
            action: () =>
              setOrderImagesModal({
                number: item.number,
                id: item.id,
                company: item.company,
                imgS: item.images,
                show: true,
              }),
          },
          {
            title: 'Imprimir ticket',
            show:
              !isVisit &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 15 ||
                item.status === 13 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18 ||
                item.status === 100) &&
              canSeeGeneralBalance,
            action: () => {
              setPrintDocument({ number: item.number, printTicket: true })
              dispatch(getPrintInstructionsByDocumentData(item.id, 1))

              // dispatch(getInvoiceByOrder(item.id, 1))
            },
          },
          {
            title: 'Imprimir factura',
            show:
              !isVisit &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 15 ||
                item.status === 13 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18 ||
                item.status === 100) &&
              canSeeGeneralBalance,
            action: async () => {
              setPrintDocument({ printInvoice: true })
              dispatch(
                onPrintInvoice({
                  active: true,
                  orderId: item.id,
                  print: true,
                  documentType: 1,
                }),
              )
            },
          },
          {
            show: !isQuote && canSeeGeneralBalance,
            title: 'Descargar orden en PDF',
            action: () => {
              dispatch(printOrder(item.id, item.number))
            },
          },
          {
            title: 'Descargar cotización en PDF',
            show: isQuote && canSeeGeneralBalance,
            action: () => dispatch(printOrder(item.id, item.number, true)),
          },
          {
            title: 'Descargar orden de trabajo en PDF',
            show: true,
            action: () => dispatch(printOrder(item.id, item.number, true, true)),
          },
          {
            show: !isLimit && !item.deleted && canSeeInventoryOrigin && item.status === 3,
            title: 'Ver procedencia de inventario',
            action: () =>
              setInventoryReport({
                title: item.number,
                documentId: item.id,
                groupId: item.groupId,
              }),
          },
          {
            show:
              !isVisit &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 15 ||
                item.status === 13 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18 ||
                item.status === 100 ||
                (isQuote && item.billPayments && item.balance < item.total)) &&
              canSeeGeneralBalance,
            title: 'Ver facturas',
            action: () =>
              dispatch(
                onPrintInvoice({
                  active: true,
                  orderId: item.id,
                  defaultName: item.number,
                  print: false,
                  documentType: 1,
                }),
              ),
          },
          {
            show:
              !isVisit &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18 ||
                item.status === 100 ||
                (isQuote && item.billPayments && item.balance < item.total)) &&
              canSeeGeneralBalance,
            title: 'Descargar facturas',
            action: () =>
              dispatch(downloadPrintableInvoiceByOrder(item.id, item.number, module)),
          },
          {
            show: !isVisit && !isQuote && item.status === 7,
            title: 'Transferir Orden',
            action: () =>
              dispatch(
                setModalOrderTransferInfo({
                  id: item.id,
                  number: item.number,
                  show: true,
                  groupId: item.groupId,
                }),
              ),
          },
          {
            show:
              !item.discountSpecial &&
              !isVisit &&
              !isDevolution &&
              !isKolo &&
              !item.deleted &&
              ((isSale &&
                ((canEdit && item.status === 1) ||
                  (canEditCompleted &&
                    item.status === 3 &&
                    fieldUpdateOrderCompleted.value === 'true'))) ||
                (isQuote && canEditQuote && item.status === 14)),
            title: 'Editar productos',
            action: () => goTo(`/editar/${item.id}/${true}`),
          },
          {
            show:
              !isLimit &&
              !isVisit &&
              !isQuote &&
              !item.deleted &&
              (item.status === 3 || item.status === 5 || item.status === 18) &&
              (item.paymentType === 1 || item.paymentType === 2) &&
              canAddRetention &&
              canSeeGeneralBalance,
            title: 'Agregar retención',
            action: () => setRetention({ ...item, show: true }),
          },
          {
            show:
              !isVisit &&
              !isQuote &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 5 ||
                item.status === 18 ||
                item.status === 100) &&
              canNullify &&
              !isKolo,
            title: 'Anular orden',
            action: () => {
              setCancel({ ...item, show: true, status: item.status })
            },
          },
          {
            show:
              !isLimit &&
              !isVisit &&
              !isQuote &&
              !item.deleted &&
              (item.status === 3 || item.status === 5) &&
              canCreateVoucher &&
              !isKolo &&
              !item.haveCreditNote &&
              canSeeGeneralBalance,
            title: 'Generar nota de crédito o Vale',
            action: () =>
              setCreateCreditNote({
                show: true,
                orderId: item.id,
                number: item.number,
                clientId: item.clientId,
              }),
          },
          {
            show:
              !isVisit &&
              !item.deleted &&
              (item.status === 3 ||
                item.status === 15 ||
                item.status === 13 ||
                item.status === 5 ||
                item.status === 17 ||
                item.status === 18 ||
                item.status === 100) &&
              canSeeGeneralBalance,
            title: 'Enviar factura por correo',
            action: () => setEmail({ show: true, ...item.client, order: item.id }),
          },
          {
            show: canDuplicateOrders && isSale,
            title: 'Duplicar orden',
            action: () => goTo(`/duplicar/${item.id}/${true}`),
          },
          {
            title: 'Ver pagos relacioandos',
            show:
              [PaymentType.CREDIT, PaymentType.MULTIPLE].some(
                paymentType => item.paymentType === paymentType,
              ) && canSeeGeneralBalance,
            action: () => {
              dispatch(
                setModalOrderPaymentsInfo({
                  show: true,
                  order: item,
                }),
              )
            },
          },
          {
            title: 'Abonar',
            show:
              item.balance > 0 &&
              (item.statusData.id === 3 || (isQuote && item.statusData.id === 14)) &&
              canSeeGeneralBalance,
            action: () => {
              setPaymentModal({
                show: true,
                order: item,
                amount: 0,
                client: item.client,
                id: item.client?.id,
              })
            },
          },
          {
            title: 'Ver cliente',
            divide: true,
            action: () => {
              let d
              let s
              if (item.client.birthDate != null) {
                d = new Date(item.client.birthDate)
                s = d.toLocaleDateString()
              }
              dispatch(getCategorizationsByEntity('clients', item.client.id))
              dispatch(getSingleClient(item.client.id))
              setShowCategorization({
                ...showCategorization,
                client: item.client.id,
                filter: false,
              })
              setClient({ show: true, ...item.client, date: s })
            },
          },
          {
            title: 'Ver categorías',
            action: () => {
              dispatch(getAllCategorizations(17))
              dispatch(getCustomCategorizationsByEntity(item.id, false, 17))
              setShowCategorization({
                ...showCategorization,
                order: item.id,
                filter: false,
                show: true,
              })
            },
          },
          {
            show: item.status === 1 && !isKolo && !isQuote,
            title: 'Actualizar cliente',
            action: () => setClientModal({ show: true, orderId: item.id }),
          },
          { title: 'Ver encargado', action: () => getCoords(item) },
          {
            title: 'Abrir en Waze',
            href: `https://www.waze.com/ul?ll=${item.client.latitude}%2C${item.client.longitude}&navigate=yes`,
            target: '_none',
          },
        ]}
      />
    )
  }

  const headers = [
    {
      checked: all && selected.length === 0,
      show: !isDevolution && !isQuote,
      select: true,
    },
    {
      label: 'Código',
      show: true,
      value: ['number'],
      type: 'code',
      classNameCustom: item => `text-center ${item.status === 7 ? 'kolo_blue' : ''}`,
      custom: item => (
        <>
          {item.number}
          {item.groupId && (
            <Icon
              icon={faBox}
              color={'white'}
              tooltip={'Está orden lleva los productos en contenedores'}
            />
          )}
        </>
      ),
    },
    {
      label: 'Cliente',
      show: true,
      value: ['client', 'name'],
      type: 'text',
      custom: item => (item.client ? item.client.name : 'Desconocido'),
    },
    {
      label: 'Bodega',
      show: !isVisit && !isQuote,
      value: ['warehouse', 'name'],
      type: 'text',
      custom: item => (item.warehouse ? item.warehouse.name : 'Desconocido'),
    },
    {
      label: 'Fecha',
      show: true,
      value: ['fechaC'],
      type: 'text',
      custom: item =>
        (item.fechaC = formatDateFromMillis(item.enabledAt || item.createdAt)),
    },
    {
      label: 'Pago',
      show: !isVisit && !isDevolution && !isQuote,
      value: ['paymentTypeData', 'name'],
      type: 'text',
    },
    {
      label: 'Estado',
      show: true,
      value: ['statusData', 'name'],
      type: 'text',
      select: true,
      custom: item =>
        item.statusData ? (
          <div className={'d-flex'}>
            <div>
              <div>{item.statusData.name}</div>
              {[3, 5, 13, 17, 100].includes(item.statusData?.id) && (
                <div>{item.invoiced ? 'Con FEL' : 'Sin FEL'}</div>
              )}
              {item.haveCreditNote && (
                <Paragraph size={'small'} dim>
                  Con nota de crédito / vale
                </Paragraph>
              )}
            </div>
          </div>
        ) : (
          'Desconocido'
        ),
    },
    {
      label: 'Despacho',
      show: !isVisit,
      value: ['dispatchStatus'],
      type: 'text',
      custom: item =>
        (item.status === 3 ? orderDispatchStatus[item.dispatchStatus] : '--') || '--',
    },
    {
      label: 'Encargado',
      show: true,
      value: isVisit ? ['deliverer', 'name'] : ['seller', 'name'],
      type: 'text',
    },
    {
      label: 'Confirmada por',
      show: !isDevolution,
      value: ['responsible', 'name'],
      type: 'text',
    },
    {
      label: 'Descripción',
      show: !isDevolution,
      value: ['description'],
      type: 'text',
      custom: item => item.description || 'Sin descripción',
    },
    {
      label: 'Total',
      show: !isVisit && canSeeGeneralBalance,
      value: ['total'],
      type: 'currency',
    },
    {
      label: 'Recargo',
      show: !isVisit && !isKolo && !isDevolution && canSeeGeneralBalance,
      value: ['surcharge'],
      type: 'currency',
    },
    {
      label: 'Descuento',
      show: !isVisit && !isKolo && !isDevolution && canSeeGeneralBalance,
      value: ['discount'],
      type: 'currency',
    },
    {
      label: 'Utilidad Bruta',
      show: canSeeUtility,
      value: ['utility'],
      type: 'currency',
    },
    {
      label: 'CXC',
      show: !isVisit && canSeeGeneralBalance,
      value: ['balance'],
      type: 'currency',
    },
    {
      show: true,
      config: true,
      type: 'text',
      value: ['button'],
      label: 'Acción',
      custom: getActions,
    },
  ]

  const depositHeaders = [
    { label: 'No', show: true, value: ['number'], type: 'text', className: 'mini' },
    {
      label: 'Cliente',
      show: true,
      value: ['client', 'name'],
      type: 'text',
      className: 'mini',
      custom: item => (item.client ? item.client.name : 'Sin registro'),
    },
    {
      label: 'Fecha confirmación',
      show: true,
      value: ['date'],
      type: 'date',
      className: 'mini',
    },
    {
      label: 'Asociada a un depósito',
      show: true,
      value: ['asociadaC'],
      type: 'text',
      className: 'mini',
      custom: item => (item.asociadaC = item.deposit ? 'Sí' : 'No'),
    },
    { config: true, show: true, label: '', className: 'mini' },
  ]

  const confirmOrder = order => {
    setAlert({
      type: 'info',
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#226095',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#B35796',
      show: true,
      title: `¿Desea confirmar la orden de venta: ${order.number}?`,
      text: 'Se tomaran los datos de referencia con los que se creo la orden.',
      showCancelButton: true,
      onCancel: () => {
        setAlert({ ...alert, show: false })
      },
      onConfirm: async () => {
        setAlert({ ...alert, show: false })

        const isFinalConsumer =
          order.client &&
          order.client.cotizapClient === 3000 &&
          canIgnoreFinalConsumerClient

        if (
          ((!canIgnoreCoords && !order.quote) ||
            (!canIgnoreQuoteCoords && order.quote)) &&
          !isFinalConsumer
        ) {
          setCustomLoadingActionRow(true)
          setActionRow(order.id)
          const { latitude, longitude } = order.client

          const gpsResponse = await validateGeoLocation(
            { isGeolocationAvailable, isGeolocationEnabled, coords },
            order.warehouseId,
            { latitude, longitude },
            'El usuario no se encuentra en el radio del cliente. Distancia actual: ',
          )

          setCustomLoadingActionRow(false)
          setActionRow(null)

          if (!gpsResponse.ok) {
            return setAlert({
              ...handlerError(gpsResponse.message),
              onConfirm: () => setAlert({ ...alert, show: false }),
            })
          }
        }

        if (order.paymentType === 2) {
          setConfirmDate({
            ...seeConfirm,
            id: order.id,
            show: true,
            number: order.number,
          })
        } else {
          setOrderId(order.id)
          dispatch(
            deliverOrder(
              order.id,
              module,
              {
                dontCertificate: !(
                  automaticFel.value === 'true' || automaticFel.value === '1'
                ),
                products: [],
                dispatchType: orderDispatchType.confirmWithFullDispatch,
              },
              null,
            ),
          )
        }
      },
    })
  }

  const getTable = () => {
    return (
      <TableV2
        loading={loading || loadingOrders}
        customClass={'scrollX'}
        headers={headers}
        dateFilter={!isKolo && !isQuote && canSeeDateFilters}
        items={orders}
        total={total}
        placeholder={'Buscar por Cliente/Bodega/Producto/No. Orden'}
        noItemsLegend={`No hay órdenes de ${
          isVisit ? 'visita' : 'venta'
        } con los filtros aplicados`}
        handleChange={search => setOrdersFilters({ ...filters, search })}
        onCheck={
          !isDevolution && !isQuote
            ? value => {
                setAll(value)
                setSelected([])
              }
            : null
        }
        customFilter={
          <div>
            {(isQuote || isKolo) && (
              <SelectedDates
                withOptionNull
                nonExecute
                noFormat
                onDateChange={(start, end) => setFilters({ ...filters, start, end })}
              />
            )}
            <Row>
              <Col className={'borderRight'}>
                <Row>
                  {!isDevolution && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <Select
                        label={'Por estado'}
                        value={filters.state}
                        info={'Filtra las órdenes por el estado'}
                        options={[
                          { value: null, label: '- Todos -' },
                          ...orderStatus.filter(
                            status => canSeeConfirmedOrders || status.id !== 3,
                          ),
                        ]}
                        onChange={value => setOrdersFilters({ ...filters, state: value })}
                      />
                    </Col>
                  )}
                  <Col xl={4} md={6} sm={6} xs={12}>
                    <SelectClient
                      actionType={clientActions.GET_CLIENT_POLYGON}
                      label={'Por cliente'}
                      onChange={value => {
                        setOrdersFilters({ ...filters, client: value })
                        setReceipt(null)
                      }}
                      value={filters.client}
                      info={'Filtra las órdenes por el cliente seleccionado'}
                      showAllOption
                      allClients={canSeeAllOrder}
                      init
                      noCreate
                    />
                  </Col>
                  {!isKolo &&
                    !isVisit &&
                    ((plan?.id !== 8 && plan?.id !== 9) || !isSale) && (
                      <Col xl={4} md={6} sm={6} xs={12}>
                        <Select
                          label={'Por bodega'}
                          value={filters.warehouse}
                          info={'Filtra las órdenes por la bodega seleccionada'}
                          options={[{ value: null, label: '- Todos -' }, ...warehouses]}
                          onChange={value => {
                            setOrdersFilters({ ...filters, warehouse: value })
                            setReceipt(null)
                          }}
                        />
                      </Col>
                    )}
                  {!isDevolution && !isVisit && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <Select
                        label={'Por tipo de pago'}
                        value={filters.payment}
                        info={'Filtra las órdenes por el tipo de pago'}
                        options={[{ value: null, label: '- Todos -' }, ...payments]}
                        onChange={value => {
                          setOrdersFilters({ ...filters, payment: value })
                          setReceipt(null)
                        }}
                      />
                    </Col>
                  )}
                  {!isDevolution && !isVisit && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <Select
                        label={'Por estado de facturación'}
                        value={filters.invoiced}
                        info={'Filtra las órdenes por su estado de facturación'}
                        options={[
                          { value: null, label: '- Todos -' },
                          { value: true, label: 'Completada con FEL' },
                          { value: false, label: 'Completada sin FEL' },
                        ]}
                        onChange={value => {
                          setOrdersFilters({ ...filters, invoiced: value })
                          setReceipt(null)
                        }}
                      />
                    </Col>
                  )}
                  {(isAdmin || canSeeAllOrder) && !isKolo && !isVisit && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <Select
                        label={'Por punto de venta'}
                        value={filters.pos}
                        info={'Filtra las órdenes por el punto de venta'}
                        options={[{ value: null, label: '- Todos -' }, ...posUser]}
                        onChange={value => {
                          setOrdersFilters({ ...filters, pos: value })
                        }}
                      />
                    </Col>
                  )}

                  {(isAdmin || canSeeAllOrder) && !isKolo && (
                    <>
                      <Col xl={4} md={6} sm={6} xs={12}>
                        <Select
                          label={'Creado por'}
                          info={'Filtra las órdenes por el usuario que la creo'}
                          value={filters.dealerMan}
                          options={[{ value: 0, label: '- Todos -' }, ...users]}
                          onChange={value => {
                            setOrdersFilters({ ...filters, dealerMan: value })
                            setReceipt(null)
                          }}
                        />
                      </Col>
                      <Col xl={4} md={6} sm={12} xs={12}>
                        <SelectUsers
                          label={'Confirmadas por'}
                          onChange={users =>
                            setOrdersFilters({ ...filters, deliveryUsers: users })
                          }
                        />
                      </Col>
                      <Col xl={4} md={6} sm={6} xs={12}>
                        <Select
                          label={'Entregado por'}
                          info={'Filtra las órdenes por el usuario que la entrego'}
                          value={filters.deliveryMan}
                          options={[{ value: 0, label: '- Todos -' }, ...users]}
                          onChange={value => {
                            setOrdersFilters({ ...filters, deliveryMan: value })
                            setReceipt(null)
                          }}
                        />
                      </Col>
                    </>
                  )}
                  {(isAdmin || canSeeAllOrder) && !isKolo && !isVisit && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <Select
                        label={'Estado de despacho'}
                        info={
                          'Filtra las órdenes por el estado del despacho del inventario'
                        }
                        value={filters.dispatchStatus}
                        options={[
                          { value: 0, label: '- Todos -' },
                          { value: 1, label: 'Sin despachar' },
                          { value: 2, label: 'Pendiente' },
                          { value: 3, label: 'Despachado' },
                        ]}
                        onChange={value => {
                          setOrdersFilters({ ...filters, dispatchStatus: value })
                          setReceipt(null)
                        }}
                      />
                    </Col>
                  )}
                  <Col xl={4} md={6} sm={6} xs={12}>
                    <Select
                      label={'Por fecha'}
                      info={'Filtra las órdenes por el tipo de fecha seleccionado'}
                      value={filters.dateType}
                      options={[
                        { value: 1, label: 'De confirmación' },
                        { value: 2, label: 'De creación' },
                      ]}
                      onChange={value => {
                        setOrdersFilters({ ...filters, dateType: value })
                      }}
                    />
                  </Col>
                </Row>
                <br />
                <hr />
                <Row>
                  <Col xl={4} md={6} sm={6} xs={12}>
                    <FormText
                      placeholder={'Buscar...'}
                      id={'number'}
                      label={'Por código de orden'}
                      info={
                        ' Se mostrara la Venta que tenga el código de orden que se ingresara en este campo.'
                      }
                      value={number || ''}
                      onChange={({ target }) => {
                        let { value } = target
                        setNumber(value)
                      }}
                      append={
                        <IconImg
                          style={{ padding: '5px', width: '30px' }}
                          icon={IconSearch}
                          loading={loadingOrders}
                          tooltip={'Buscar'}
                          onClick={() => {
                            if (number == null || number === '') setUp()
                            else dispatch(getOrderByNumber(number))
                          }}
                        />
                      }
                      aPadding
                    />
                  </Col>
                  {!isDevolution && (
                    <Col xl={4} md={6} sm={6} xs={12}>
                      <FormText
                        placeholder={'Buscar...'}
                        id={'auth_number'}
                        label={'Filtrar por datos de la factura'}
                        info={' Número, Número de autorización o serie.'}
                        value={authNumber || ''}
                        onChange={({ target }) => {
                          let { value } = target
                          setAuthNumber(value)
                        }}
                        append={
                          <IconImg
                            style={{ padding: '5px', width: '30px' }}
                            icon={IconSearch}
                            loading={loadingOrders}
                            tooltip={'Buscar'}
                            onClick={() => {
                              if (authNumber == null || authNumber === '') setUp()
                              else dispatch(getOrderByNumber(authNumber, true))
                            }}
                          />
                        }
                        aPadding
                      />
                    </Col>
                  )}
                  <Col xl={4} md={6} sm={6} xs={12}>
                    <CustomCreate
                      label={'Por producto'}
                      info={
                        'Se buscara todas las Ventas que tengan en su detalle de venta el producto seleccionado'
                      }
                      options={[{ value: '', label: '- Todos -' }, ...productSelector]}
                      onChange={value => {
                        setOrdersFilters({ ...filters, products: value.value.toString() })
                      }}
                      placeholder={'Seleccione un producto'}
                      textLabel={'Buscar producto: '}
                      isLoading={loadingProductsSelector}
                      async
                      dontClear
                      loadOptions={async search => {
                        if (!(search == null || search === '')) {
                          const data = await ProductsService.getProductsLite({ search })
                          return [
                            { value: '', label: '- Todos -' },
                            ...data.map(p => ({
                              value: p.id,
                              label: `${p.name} - ${p.code}`,
                            })),
                          ]
                        } else
                          return [{ value: '', label: '- Todos -' }, ...productSelector]
                      }}
                    />
                  </Col>

                  {!isKolo &&
                    !isVisit &&
                    !isQuote &&
                    !isDevolution &&
                    ((plan?.id !== 8 && plan?.id !== 9) || !isSale) && (
                      <Col xl={4} md={6} sm={6} xs={12}>
                        <div className={'column'}>
                          <label className={'ftf-form-label left mt-3'}>Reportería</label>
                          <Row
                            className={'container-buttons'}
                            style={{ paddingBottom: 2, display: 'flex' }}>
                            <Button
                              loading={loadingOrderProducts}
                              style={{ flex: 1 }}
                              color={'primary'}
                              onClick={() => {
                                setUp(false, false, false, true)
                              }}>
                              Sumatoria
                            </Button>
                            <Button
                              loading={loadingCashRegisterReport}
                              style={{ flex: 1 }}
                              color={'primary'}
                              onClick={() =>
                                dispatch(
                                  cashRegisterReport(
                                    filters.start.valueOf(),
                                    filters.end.valueOf(),
                                    !(isAdmin || canSeeAllOrder)
                                      ? user.id
                                      : filters.deliveryMan.value === 0
                                      ? null
                                      : filters.deliveryMan.value,
                                  ),
                                )
                              }>
                              Cierre
                            </Button>
                          </Row>
                        </div>
                      </Col>
                    )}
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xl={6} lg={6} sm={12}>
                <Row className={'pl-1'}>
                  {selectedCategories.map((p, i) => (
                    <div className={'user-tag product-t ml-2'} key={i}>
                      <label className={'label-user-tag'}>{p.name}</label>
                      <ButtonIcon
                        className={'delete-user-tag d-product-t'}
                        icon={faTimes}
                        tooltip={'Quitar'}
                        color={'white'}
                        onClick={() => assignCategorizationToFilters(p)}
                      />
                    </div>
                  ))}
                  <Button
                    style={{ marginLeft: 15 }}
                    color={'primary'}
                    onClick={() => {
                      dispatch(getAllCategorizations(16))
                      setShowCategorization({
                        ...showCategorization,
                        show: true,
                        filter: true,
                      })
                    }}>
                    Filtrar por categorías
                  </Button>
                </Row>
              </Col>
            </Row>
            <hr />
          </div>
        }
        onDateChange={(start, end, search) => {
          setOrdersFilters({
            ...filters,
            start: start.setHours(0, 0, 0, 0),
            end: end.setHours(23, 59, 59, 99),
            search,
          })
        }}
        onClick={() => setUp()}
        getItems={item => {
          if (item === null) {
            setSelected([])
          } else {
            setSelected([...selected, item])
          }
        }}
        getPagination={(skip, size, search) =>
          setOrdersFilters({ ...filters, skip, size, search }, false)
        }
        isSelected={item => (isSelected(item) && all ? false : all || isSelected(item))}
        mobileAuto
        storageKey={`ordersPage-${user.id}${
          isVisit ? 'Visita' : isQuote ? 'cotización' : isSale ? 'ventas' : 'A'
        }`}
        onClickTr={(e, item) => {
          if (!isDevolution && !isQuote) {
            addOrRemoveSelected(item)
          }
        }}
      />
    )
  }

  const uploadImage = (url, name) => {
    setImages([...images, { url, original: url, thumbnail: url, name }])
  }

  const deleteImage = (image, index) => {
    const { images } = this.state
    images.splice(index, 1)
    setImages(new Array(...images))
  }

  const depositValidation = () => {
    const users = []
    let id = 0
    selected.forEach(u => {
      if (id === 0) id = u.deliveredBy
      if (id === u.deliveredBy) users.push(u)
    })
    return users.length === selected.length
  }

  const assignCategorizationToClient = categorization => {
    const array = []
    array.push(showCategorization.client)
    dispatch(
      assignCategorizationToMultipleEntities(
        'clients',
        categorization,
        array,
        !!showCategorization.client,
      ),
    )
  }

  const assignCategorizationToOrder = categorization => {
    let array = []
    if (selected.length > 0) array = selected.map(s => s.id)
    else array.push(showCategorization.order)

    assignCategorizationToFilters(categorization, true)
    dispatch(
      assignCategorizationToMultipleEntities(
        null,
        categorization.id,
        array,
        !!showCategorization.order,
        false,
        17,
      ),
    )
  }

  const assignCategorizationToFilters = (categorization, ignoreFilter) => {
    const d = selectedCategories.find(d => d.id === categorization.id)

    let categories

    if (!d) {
      selectedCategories.push(categorization)
      setSelectedCategories([...selectedCategories])
      categories = selectedCategories
    } else {
      categories = selectedCategories.filter(f => f.id !== categorization.id)
      setSelectedCategories(categories)
    }

    if (!ignoreFilter)
      setOrdersFilters({ ...filters, categories: categories.map(p => p.id).join(',') })
  }

  const statusTabs = [
    {
      label: 'Solicitadas',
      quantity: ordersCount.length > 0 ? ordersCount[0] : 0,
      image: RightArrow,
    },
    {
      label: 'Preparadas',
      quantity: ordersCount.length > 0 ? ordersCount[1] : 0,
      image: RightArrow,
    },
    {
      label: 'En Tránsito',
      quantity: ordersCount.length > 0 ? ordersCount[2] : 0,
      image: RightArrow,
    },
    {
      label: 'Entregadas',
      quantity: ordersCount.length > 0 ? ordersCount[3] : 0,
      image: IconFlag,
    },
    { label: 'Otras', quantity: ordersCount.length > 0 ? ordersCount[4] : 0 },
  ]

  return (
    <div>
      <Title
        title={
          isDevolution
            ? 'Devoluciones'
            : isKolo
            ? 'Órdenes KOLO'
            : isVisit
            ? 'Órdenes de Visita'
            : isQuote
            ? 'Cotizaciones'
            : 'Órdenes de Venta'
        }
      />
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card
            title={`Listado de ${isSale ? 'órdenes' : 'cotizaciones'}`}
            white
            button={getButtons()}>
            {canSeeSummative && (
              <CustomSummary
                items={[
                  {
                    show: isVisit,
                    title: 'Total de órdenes con venta',
                    value: toMoney(balance.visitTotal),
                  },
                  { show: true, title: 'Total', value: toMoney(balance.total) },
                  {
                    show: selectedCategories.length > 0,
                    title: 'Total de Productos',
                    value: toMoney(balance.customTotal),
                  },
                  {
                    show: selectedCategories.length > 0,
                    title: 'Utilidad de Productos',
                    value: toMoney(balance.customPurchase),
                  },
                  {
                    show: isSale && canSeeUtility && plan?.id !== 8 && plan?.id !== 9,
                    title: 'Utilidad Bruta',
                    value: toMoney(balance.purchase),
                  },
                  {
                    show: isSale,
                    title: 'Descuentos',
                    value: toMoney(balance.discount),
                  },
                  {
                    show: isSale,
                    title: 'Propina',
                    value: toMoney(balance.gratification),
                  },
                  {
                    show: isSale && plan?.id !== 8 && plan?.id !== 9,
                    title: 'CXC',
                    value: toMoney(balance.balanceTotal || 0),
                  },
                ]}
              />
            )}
            {isKolo ? (
              <>
                <div>
                  <SelectedDates
                    nonExecute
                    onDateChange={(start, end) =>
                      setOrdersFilters({ ...filters, start, end })
                    }
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Tabs defaultIndex={0} onSelect={index => setCurrentTab(index)}>
                    <TabList>
                      {statusTabs.map((item, index) => (
                        <Tab key={index}>
                          <div style={{ display: 'flex' }}>
                            {item.quantity ? (
                              <div className={'badge-tab'}>{item.quantity}</div>
                            ) : (
                              <div className={'badge-tab-white'}>{item.quantity}</div>
                            )}
                            <div>{item.label}</div>
                            {item.image && (
                              <img className="tabs-img" src={item.image} alt={''} />
                            )}
                          </div>
                        </Tab>
                      ))}
                    </TabList>
                    {statusTabs.map((item, index) => (
                      <TabPanel key={index}>{getTable()}</TabPanel>
                    ))}
                  </Tabs>
                </div>
              </>
            ) : (
              getTable()
            )}
          </Card>
        </Col>
      </Row>
      <Modal show={orderImagesModal.show} centered size={'md'} onHide={() => undefined}>
        <Modal.Header closeButton>
          <Modal.Title>Orden: #{orderImagesModal.number}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <Gallery
                company={orderImagesModal.company}
                imageType={imageTypes.INVOICES}
                entityId={orderImagesModal.id}
                showUploader={false}
                maxWidth={450}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <OrderNullify
        show={cancel.show}
        number={cancel.number}
        orderId={cancel.id}
        onHide={update => {
          setCancel({ ...cancel, show: false })
          if (update) setUp()
        }}
      />

      <Modal
        show={seeUser.show}
        size={'md'}
        centered
        onHide={() => setUser({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{seeUser.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-card'}>
            {(seeUser.phone1 || seeUser.phone2) && (
              <Row style={{ justifyContent: 'center' }}>
                <a
                  className={'pp-phone'}
                  style={{ borderRadius: 10 }}
                  href={
                    (seeUser.phone1 || seeUser.phone2) &&
                    `tel:${seeUser.phone1 || seeUser.phone2}`
                  }>
                  <div
                    className={'big-button'}
                    style={{
                      alignSelf: 'cen-ter',
                      backgroundColor: 'rgba(80,170,70,0.8)',
                    }}>
                    Llamar encargado
                  </div>
                </a>
              </Row>
            )}
            <Row>
              <Col xl={2} md={2} sm={12} />
              <Col xl={8} md={8} sm={12}>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Teléfono</b>
                  </div>
                  <div>{seeUser.phone1 || seeUser.phone2 || 'Desconocido'}</div>
                </div>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Correo</b>
                  </div>
                  <div>{seeUser.email || 'Desconocido'}</div>
                </div>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>DPI</b>
                  </div>
                  <div>{seeUser.dpi || 'Desconocido'}</div>
                </div>
              </Col>
              <Col xl={2} md={2} sm={12} />

              {seeUser.latitude && seeUser.longitude && (
                <Col xl={12} md={12} xs={12}>
                  <Geocoding
                    lat={seeUser.latitude}
                    lng={seeUser.longitude}
                    auto={false}
                    defaultAddress={seeUser.address}
                    editable={false}
                    getData={() => undefined}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={seeClient.show}
        size={'md'}
        centered
        onHide={() => setClient({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>{seeClient.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={'custom-card'}>
            <Row style={{ justifyContent: 'center' }}>
              <img
                className={'picture-client'}
                src={singleClient?.image?.label || seeClient.picture || ClientIcon}
                alt={seeClient.name}
              />
            </Row>
            {seeClient.phone && (
              <Row style={{ justifyContent: 'center' }}>
                <a
                  className={'pp-phone'}
                  style={{ borderRadius: 10 }}
                  href={seeClient.phone && `tel:${seeClient.phone}`}>
                  <div
                    className={'big-button'}
                    style={{
                      alignSelf: 'cen-ter',
                      backgroundColor: 'rgba(80,170,70,0.8)',
                    }}>
                    Llamar cliente
                  </div>
                </a>
              </Row>
            )}
            <Row>
              <Col xl={2} md={2} sm={12} />
              <Col xl={8} md={8} sm={12}>
                {loadingSingleClient && <ProgressBar now={100} animated />}
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Teléfono</b>
                  </div>
                  <div>{singleClient.phone || 'Desconocido'}</div>
                </div>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Código</b>
                  </div>
                  <div>{singleClient.code || 'Desconocido'}</div>
                </div>
                {country.id === Country.GT ? (
                  <div className={'space-between'}>
                    <div className={'label'}>
                      <b>NIT</b>
                    </div>
                    <div>{singleClient.nit || 'Desconocido'}</div>
                  </div>
                ) : (
                  identificationTypes?.map(identificationType => (
                    <div key={identificationType.id} className={'space-between'}>
                      <div className={'label'}>
                        <b>{identificationType.name}</b>
                      </div>
                      <div>
                        {singleClient.identifications?.find(
                          identification =>
                            identification.identificationTypeId === identificationType.id,
                        )?.value || 'Desconocido'}
                      </div>
                    </div>
                  ))
                )}
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Dirección</b>
                  </div>
                  <div>{singleClient.address || 'Desconocido'}</div>
                </div>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Referencia</b>
                  </div>
                  <div>{singleClient.reference || 'Desconocido'}</div>
                </div>
                <div className={'space-between'}>
                  <div className={'label'}>
                    <b>Fecha de nacimiento</b>
                  </div>
                  <div>
                    {formatDateFromMillis(singleClient.birthDate) || 'Desconocido'}
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <Row style={{ justifyContent: 'center' }}>
              <Button
                className={'big-button'}
                style={{
                  width: 210,
                  height: 35,
                  lineHeight: 1,
                  alignSelf: 'cen-ter',
                }}
                onClick={() => {
                  dispatch(getAllCategorizations(5))
                  setShowCategorization({
                    ...showCategorization,
                    show: true,
                    client: seeClient.id,
                  })
                }}>
                <IconButton
                  icon={faPlusCircle}
                  color={'white'}
                  size={'1x'}
                  iconStyle={{ marginRight: 5 }}
                />
                Añadir Categorías
              </Button>
            </Row>
            <Row>
              <Col xl={12} md={12} sm={12}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexFlow: 'wrap',
                  }}>
                  {clientCategorization.map((c, index) => (
                    <div className="chip mb-3" key={index}>
                      <div className="chip-head">{c?.label?.charAt(0)}</div>
                      <div className="chip-content">{c.label}</div>
                      <div
                        className="chip-close"
                        onClick={() => {
                          dispatch(
                            assignCategorizationToEntity(
                              'clients',
                              c.value,
                              showCategorization.client,
                              true,
                            ),
                          )
                        }}>
                        <svg
                          className="chip-svg"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true">
                          <path
                            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7
                                      15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={2} md={2} sm={12} />

              {seeClient.latitude && seeClient.longitude && (
                <Col xl={12} md={12} xs={12}>
                  <Geocoding
                    lat={seeClient.latitude}
                    lng={seeClient.longitude}
                    auto={false}
                    defaultAddress={seeClient.address}
                    editable={false}
                    getData={() => undefined}
                    noEdit
                  />
                </Col>
              )}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={retention.show}
        size={'md'}
        centered
        onHide={() => setRetention({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar retención</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12}>
              <FormText
                disabled={loadingRetention}
                prependMoneySymbol
                name={'retention'}
                label={'Agregar retención'}
                value={retention.amount}
                type={'number'}
                onChange={e => {
                  const { value } = e.target
                  let amount = value || 0
                  if (amount < 0) amount = 0
                  amount = Number.parseFloat(amount)

                  if (value <= retention.total) {
                    setRetention({ ...retention, amount })
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingRetention}
              disabled={!retention.amount || retention.amount <= 0}
              color={'primary'}
              icon={faMoneyBill}
              right
              onClick={() => {
                dispatch(addRetention(retention.id, retention.amount))
              }}>
              Solicitar retención
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
      <ModalTable
        show={seeArchive}
        title={'Órdenes archivadas'}
        size={'lg'}
        onHide={() => {
          setSeeArchive(false)
          setSelected([])
        }}
        onClickTr={(e, item) => addOrRemoveSelected(item)}
        isSelected={selected => isSelected(selected)}
        loading={loadingArchivedData}
        items={archived
          .filter(a => a.type === 1 && a.status !== 12)
          .sort((a, b) => b.enabledAt - a.enabledAt)}
        headers={[
          {
            label: '',
            show: true,
            value: ['code'],
            simpleChecked: true,
            select: true,
            type: 'text',
            classNameCustom: item => `mini ${isSelected(item) ? 'active' : ''}`,
            custom: item => (
              <Checkbox
                name={'archive'}
                checked={isSelected(item)}
                onChange={() => {
                  addOrRemoveSelected(item)
                }}
              />
            ),
          },
          {
            label: 'Código',
            show: true,
            value: ['number'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Cliente',
            show: true,
            value: ['clientData', 'companyName'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Fecha',
            show: true,
            value: ['fechaC'],
            type: 'text',
            className: 'mini',
            custom: item =>
              (item.fechaC = formatDateFromMillis(item.enabledAt || item.createdAt)),
          },
          {
            label: 'Estado',
            show: true,
            value: ['statusData', 'name'],
            type: 'text',
            className: 'mini',
          },
          {
            label: 'Total',
            show: true,
            value: ['total'],
            type: 'currency',
            className: 'mini',
          },
          { config: true, show: true, label: '', className: 'mini center' },
        ]}
        balancePermission={canSeeGeneralBalance}
        renderRow={undefined}
        del
        onDelete={() =>
          setAlert({
            show: true,
            title: '¿Desea eliminar las órdenes seleccionadas?',
            text: 'Esta acción no podrá ser revertida.',
            type: 'warning',
            showCancelButton: true,
            onCancel: () => setAlert({ ...alert, show: false }),
            onConfirm: () => {
              setAlert({ ...alert, show: false })
              dispatch(
                changeDeletedStatusByList(
                  'order',
                  selected.map(s => s.id),
                ),
              )
            },
          })
        }
        button={'Recuperar'}
        onClick={() =>
          dispatch(
            changeArchivedStatusByList(
              'order',
              selected.map(s => s.id),
            ),
          )
        }
        bLoading={loadingArchived}
        bDisabled={loadingArchivedData || selected.length <= 0}
      />
      <InventoryReport
        showItem
        documentType={4}
        documentId={inventoryReport.documentId}
        productName={'Orden NO: '.concat(inventoryReport.title)}
        close={() => setInventoryReport({ documentId: null, title: '' })}
        canSeeMoneyValues={canSeeGeneralBalance}
      />
      {isQuote || isVisit || (isSale && selected.length === 0 && !all) || isDevolution ? (
        <FABV2
          title={
            'Crear ' +
            (isQuote
              ? 'Cotización'
              : isVisit
              ? 'Visita'
              : isDevolution
              ? 'Devolución'
              : 'Venta')
          }
          icon={faPlus}
          show={
            isQuote ||
            isDevolution ||
            (isVisit && canCreateVisit) ||
            (isSale && selected && selected.length === 0 && !all && !isVisit)
          }
          onClick={() => {
            if (isQuote || (selected && selected.length === 0 && !all)) {
              if (isVisit) setShowCreateVisitModal(true)
              else if (isDevolution) {
                history.push('/distribucion/ordenes/false/nueva')
              } else goTo('/nueva')
            }
          }}
        />
      ) : (
        <FAB
          show={!isDevolution || (isSale && canCreate)}
          icon={
            isQuote
              ? faPlus
              : (selected && selected.length > 0) || all
              ? faEllipsisV
              : null
          }
          items={[
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Nueva orden',
              icon: faPlus,
              action: () => {
                if (isVisit) setShowCreateVisitModal(true)
                else goTo('/nueva')
              },
              show: orders && orders.length > 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Ver órdenes en mapa',
              icon: faMapPin,
              action: () => loadMap(filters.start, filters.end),
              next: true,
              show: orders && orders.length > 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Crear depósito',
              icon: faWallet,
              show:
                !isLimit &&
                selected &&
                selected.length > 0 &&
                selected.filter(f => f.status !== 3).length === 0,
              action: () => {
                let amount = 0
                selected.forEach(p => {
                  amount += p.total
                })
                setDepositModal({ ...depositModal, show: true, amount })
              },
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              next: true,
              icon: faTags,
              show: selected && selected.length > 0,
              text: 'Asignar categorías',
              action: () => {
                dispatch(getAllCategorizations(17))
                setShowCategorization({ show: true, filter: false })
              },
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Archivar',
              icon: faArchive,
              action: () =>
                setAlert({
                  show: true,
                  title: 'Archivar órdenes seleccionados',
                  text: '¿Desea archivar las órdenes seleccionadas? Esta acción puede ser revertida en cualquier momento',
                  type: 'warning',
                  showCancelButton: true,
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Archivar',
                  confirmButtonColor: '#226095',
                  cancelButtonColor: '#B35796',
                  onCancel: () => setAlert({ ...alert, show: false }),
                  onConfirm: () => {
                    setAlert({ ...alert, show: false })
                    dispatch(
                      changeArchivedStatusByList(
                        'order',
                        selected.map(s => s.id),
                      ),
                    )
                  },
                }),
              show:
                selected.length > 0 &&
                selected.filter(f => f.status === 3 || f.status === 7 || f.status === 8)
                  .length === 0,
            },
            {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Edición masiva',
              icon: faEdit,
              show: canEditMassive,
              action: () => setMassiveEditModal({ show: true }),
            },
            canChangeOrderDate && {
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              text: 'Modificar fecha de confirmación',
              icon: faCalendar,
              action: () => {
                setDates({ show: true })
              },
              show: false,
            },
            {
              text: 'Confirmación masiva',
              show: canConfirmMassive && ((selected && selected.length > 0) || all),
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              icon: faCheckDouble,
              action: () => setMassiveConfirmationModal({ selected, show: true, all }),
            },
            {
              text: 'Rechazo masivo de ventas',
              show: canCancelMassive && ((selected && selected.length > 0) || all),
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              icon: faWindowClose,
              action: () =>
                setMassiveConfirmationModal({ selected, show: true, cancel: true, all }),
            },
            {
              text: 'Certificación masiva de ventas (FEL)',
              show: canCertifyAfter && ((selected && selected.length > 0) || all),
              style: { backgroundColor: 'rgb(179, 87, 150, 0.9)' },
              icon: faFileInvoice,
              action: () =>
                setMassiveConfirmationModal({ selected, show: true, certify: true, all }),
              next: true,
            },
          ]}
          onClick={() => {
            if (isQuote || (selected && selected.length === 0 && !all)) {
              if (isVisit) setShowCreateVisitModal(true)
              else goTo('/nueva')
            }
          }}
          mainButtonStyles={
            all || selected.length > 0 ? { backgroundColor: '#B35796' } : null
          }
        />
      )}
      <ModalOrderSummary
        show={showOrderSummaryModal.show}
        onClose={() => setShowOrderSummaryModal({ show: false, params: {} })}
        params={showOrderSummaryModal.params}
        seeMoneyValues={canSeeGeneralBalance}
      />
      <OrdersMap
        show={showOrdersMap}
        orders={selected.length > 0 ? selected : ordersMap}
        onHide={() => {
          setShowOrdersMap(false)
        }}
      />
      <Modal
        show={clientModal.show}
        size={'md'}
        centered
        onHide={() => setClientModal({ show: false })}>
        <Modal.Header>
          <Modal.Title>Editar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            label={'Cliente'}
            value={clientModal.client}
            info={'Selecciona el nuevo cliente de la orden'}
            options={clients}
            onChange={value => setClientModal({ ...clientModal, client: value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!clientModal.client}
            onClick={() => {
              dispatch(
                onUpdateOrderParameters(
                  { client: clientModal.client.value },
                  clientModal.orderId,
                ),
              )
              setClientModal({ show: false })
            }}>
            Actualizar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={paymentModal.show}
        size={'xl'}
        centered
        onHide={() =>
          setPaymentModal({
            ...paymentModal,
            show: false,
            amount: 0,
            client: null,
          })
        }>
        <Modal.Header closeButton>
          <Modal.Title>Realizar un pago</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <CreateOrderPayment
            show={paymentModal.show}
            clientId={paymentModal.id}
            balance={paymentModal.order.balance || 0}
            billPayments={Boolean(paymentModal.order.billPayments)}
            onAmountChange={amount => setPaymentModal({ ...paymentModal, amount })}
            loading={loadingConfirmPayment}
            client={{ id: 0 }}
            isQuote={isQuote}
            onCreateOrder={payment => {
              const params = {
                ...payment,
                client: paymentModal.client.id,
                payments: [{ orderId: paymentModal.order.id, amount: payment.amount }],
              }
              dispatch(onCreatePaymentSpecificOrder(paymentModal.order.id, params))
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={depositModal.show}
        size={'md'}
        centered
        onHide={() => setDepositModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Relacionar depósitos con las órdenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!depositValidation() && (
            <h5>SOLO SE PUEDE CREAR UN DEPÓSITO CON ÓRDENES DE UN MISMO VENDEDOR</h5>
          )}
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <Select
                label={'Cuenta de Banco'}
                isSearchable={false}
                placeholder={'Seleccione una cuenta'}
                options={accounts}
                value={depositModal.bank}
                info={'Seleccionar la cuenta bancaria'}
                onChange={value => setDepositModal({ ...depositModal, bank: value })}
                required
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormText
                disabled={!depositModal.bank}
                prepend={<Icon tooltip={'Referencia'} icon={faFileInvoice} />}
                label={'Número de referencia'}
                placeholder={'No. Referencia'}
                name={'number'}
                type={'number'}
                value={depositModal.number}
                info={'Se ingresa el número de boleta bancaria'}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value) value = 0
                  setDepositModal({ ...depositModal, number: value })
                }}
                max={16}
              />
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormText
                prependMoneySymbol
                disabled
                label={'Monto del deposito'}
                placeholder={'0.00'}
                name={'amount'}
                value={depositModal.amount}
                required
                info={'Monto del efectivo que se va a depositar'}
              />
            </Col>

            <Col xl={12} md={12} lg={12} sm={12}>
              <FormText
                as={'textarea'}
                rows={3}
                label={'Descripción'}
                placeholder={'opcional'}
                name={'description'}
                value={depositModal.description}
                max={300}
                onChange={({ target }) => {
                  setDepositModal({ ...depositModal, description: target.value })
                }}
              />
            </Col>
            <Col xl={12} md={12} lg={12} sm={12}>
              <Gallery
                imageType={imageTypes.DEPOSITS}
                images={images}
                onUploadSuccess={uploadImage}
                onDelete={deleteImage}
                title={'Agregar foto de depósito'}
                limit={1}
                maxWidth={256}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <TableV2
              items={selected}
              mobileAuto
              storageKey={`associatedDeposit`}
              headers={depositHeaders}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            loading={loadingCreateDeposit}
            disabled={
              !depositModal.bank ||
              !depositModal.number ||
              !depositModal.amount ||
              depositModal.amount <= 0 ||
              !depositValidation()
            }
            color={'primary'}
            icon={faSave}
            onClick={() =>
              setAlert({
                show: true,
                type: 'info',
                title: 'Registro del deposito',
                text: `¿Desea registrar el deposito: ${
                  depositModal.number ? depositModal.number : ''
                } con monto de: ${toMoney(depositModal.amount)} a la cuenta de banco: ${
                  depositModal.bank.label
                }?`,
                showCancelButton: true,
                onCancel: () => setAlert({ ...alert, show: false }),
                onConfirm: () => {
                  setAlert({ ...alert, show: false })
                  dispatch(
                    createDeposit(
                      {
                        amount: depositModal.amount,
                        bank: depositModal.bank.value,
                        number: depositModal.number,
                        own: !isKolo,
                        description: depositModal.description,
                        currency: depositModal.bank.currency,
                        change: depositModal.change,
                        list: selected.map(p => p.id),
                      },
                      images,
                    ),
                  )
                },
              })
            }>
            Crear depósito
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDiscountPercent && !modalTip.show}
        centered
        size={'md'}
        onHide={() => {
          setShowDiscountPercent(false)
        }}>
        <Modal.Header closeButton>
          <Modal.Title>Descuentos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              padding: 0,
              margin: 0,
              minHeight: '300px',
              maxHeight: '350px',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}>
            {discounts.length > 0 ? (
              discounts.map((item, i) => {
                return (
                  <Row key={i}>
                    <Col xl={5} lg={5} md={5} xs={5}>
                      <FormText
                        label={'Porcentaje'}
                        value={`${item.percentage}%`}
                        type={'text'}
                        disabled
                      />
                    </Col>
                    <Col xl={5} lg={5} md={5} xs={5}>
                      <FormText
                        label={'Alias'}
                        value={item.alias}
                        type={'text'}
                        disabled
                      />
                    </Col>
                    <Col xl={1} lg={1} md={1} sm={1} className={'mt-5'}>
                      <Button
                        loading={loadingTips && modalTip.id === item.id}
                        icon={faTrash}
                        right
                        color={'secondary'}
                        disabled={loadingTips}
                        onClick={() => {
                          setModalTip({
                            id: item.id,
                            percentage: 0,
                            show: false,
                          })
                          dispatch(deleteTip(item.id))
                        }}
                      />
                    </Col>
                  </Row>
                )
              })
            ) : (
              <Empty
                subtitle={'No se encontraron porcentajes de descuento'}
                loading
                hidebutton
                subtitleSecond={' '}
              />
            )}
          </div>

          <Button
            onClick={() => setModalTip({ show: true, percentage: 0, discount: true })}
            icon={faPlusCircle}>
            Agregar
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={modalTip.show}
        centered
        size={'md'}
        onHide={() => setModalTip({ show: false, percentage: 0 })}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Descuento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={6} lg={6} md={6} xs={6}>
              <FormText
                label={'Porcentaje'}
                prepend={'%'}
                value={modalTip.percentage}
                type={'number'}
                disabled={loadingTips}
                onChange={({ target }) => {
                  let { value } = target
                  if (!value || value < 0) value = 0
                  if (value > 100) value = 100
                  setModalTip({ ...modalTip, percentage: value })
                }}
              />
            </Col>
            <Col xl={6} lg={6} md={6} xs={6}>
              <FormText
                label={'Alias'}
                value={modalTip.alias}
                type={'text'}
                disabled={loadingTips}
                onChange={({ target }) => {
                  setModalTip({ ...modalTip, alias: target.value })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingTips}
              disabled={!modalTip.percentage}
              icon={faSave}
              color={'primary'}
              onClick={() => {
                dispatch(
                  createTip(
                    parseFloat(modalTip.percentage),
                    true,
                    false,
                    null,
                    modalTip.alias,
                  ),
                )
              }}>
              Guardar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showCreateVisitModal}
        size={'lg'}
        centered
        onHide={() => setShowCreateVisitModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Programar una visita</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateOrderVisit
            isRequiredClient={true}
            allClientsToSelect={[]}
            onCreateOrderVisit={visit => dispatch(onCreateOrderVisit(visit))}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={seeEmail.show}
        size={'md'}
        centered
        onHide={() => setEmail({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar facturas por correo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormTextField
            name={'email'}
            label={'Correo a donde se enviaran las facturas'}
            value={seeEmail.email}
            type={'text'}
            onChange={event => setEmail({ ...seeEmail, email: event.target.value })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!seeEmail.email}
            onClick={() => {
              dispatch(sendInvoiceEmail({ id: seeEmail.order, email: seeEmail.email }))
              setEmail({ show: false })
            }}>
            Enviar Correo
          </Button>
        </Modal.Footer>
      </Modal>

      <Folder
        noMessage
        list={selectedCategories.map(p => p.id)}
        onHide={() => {
          if (!showCategorization.filter) setSelectedCategories([])

          setShowCategorization({
            ...showCategorization,
            show: false,
            order: null,
            filter: false,
          })
        }}
        onAssign={item => {
          if (showCategorization.filter) {
            assignCategorizationToFilters(item)
          } else if (selected.length > 0 || showCategorization.order)
            assignCategorizationToOrder(item)
          else assignCategorizationToClient(item.id)
        }}
        data1={categorization?.children ? categorization.children[0] : {}}
        data2={categorization?.children ? categorization.children[1] : {}}
        show={showCategorization.show}
        loading={loadingCategorizations}
      />

      <Modal
        show={seeDates.show || dateVisit.show}
        size={'md'}
        centered
        onHide={() => {
          if (dateVisit.show) setDateVisit({ show: false })
          else setDates({ show: false })
        }}>
        <Modal.Header closeButton>
          <Modal.Title>
            {dateVisit.show ? 'Posponer visita' : 'Modificar fecha de confirmación'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomDate
            label={
              dateVisit.show
                ? 'Nueva fecha de visita'
                : 'Confirmar bajo la siguiente fecha: '
            }
            value={dateVisit.show ? dateVisit.date : seeDates.newDate}
            disabledDays={dateVisit.show ? { before: new Date() } : { after: new Date() }}
            onDayChange={e => {
              if (dateVisit.show) setDateVisit({ ...dateVisit, date: e })
              else setDates({ ...seeDates, date: e })
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={dateVisit.show ? !dateVisit.date : !seeDates.date}
            onClick={() => {
              if (dateVisit.show) {
                dispatch(onChangeDateVisit(dateVisit.id, dateVisit.date.valueOf()))
                return
              }
              const orders = []
              selected.forEach(s => {
                orders.push(s.id)
              })
              const request = { orders, newDate: seeDates.date.valueOf() }
              dispatch(changeDatesOrder(request))
            }}
            loading={loadingChangeDateVisit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={seeConfirm.show}
        size={'md'}
        centered
        onHide={() => setConfirmDate({ ...seeConfirm, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar fecha de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomDate
            label={'Programar fecha de pago: '}
            value={seeConfirm.newDate}
            disabledDays={{ before: new Date() }}
            onDayChange={e => {
              setConfirmDate({ ...seeConfirm, date: e })
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!seeConfirm.date}
            onClick={() => {
              const paymentDate = seeConfirm.date.valueOf()

              setOrderId(seeConfirm.id)
              dispatch(
                deliverOrder(
                  seeConfirm.id,
                  module,
                  {
                    dontCertificate: !(
                      automaticFel.value === 'true' || automaticFel.value === '1'
                    ),
                    products: [],
                    paymentDate,
                    dispatchType: orderDispatchType.confirmWithFullDispatch,
                  },
                  null,
                ),
              )
              setConfirmDate({ show: false })
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <ModalOrderReturn
        {...orderReturn}
        onClose={success => {
          setOrderReturn({ ...orderReturn, open: false })
          if (success) setUp()
        }}
      />

      <Modal
        show={massiveEditModal.show}
        centered
        size={'xl'}
        onHide={() => setMassiveEditModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Actualización masiva de órdenes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <div className={'b-user-name no-transform'}>
                La información seleccionada a continuación actualizará la información de
                las ventas seleccionadas
              </div>
            </Col>
            <Col xl={6} lg={6} md={12}>
              <Select
                label={'Cambiar método de pago'}
                options={[{ value: null, label: 'No cambiar' }, ...payments]}
                subText={
                  'Unicamente las órdenes de venta con estado: INGRESADO, podrá ser afectado por esta propiedad'
                }
                onChange={v => setMassiveEditModal({ ...massiveEditModal, payment: v })}
              />
            </Col>
            <Col xl={6} lg={6} md={12}>
              <Select
                label={'Asignar punto de venta'}
                options={[
                  { value: 0, label: 'No cambiar' },
                  { value: null, label: 'Quitar el punto de venta asignado' },
                  ...posUser,
                ]}
                subText={
                  'Se les cambiara el valor de punto de venta a las órdenes seleccionadas'
                }
                onChange={v => setMassiveEditModal({ ...massiveEditModal, pos: v })}
              />
            </Col>
            <Col xl={6} lg={6} md={12}>
              <CustomDate
                label={'Modificar la fecha de confirmación de las ventas'}
                value={massiveEditModal.enabledAt}
                disabledDays={{ after: new Date() }}
                onDayChange={e => {
                  setMassiveEditModal({ ...massiveEditModal, enabledAt: e })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingUpdateList}
              disabled={
                (!massiveEditModal.payment || !massiveEditModal.payment.value) &&
                (!massiveEditModal.pos || massiveEditModal.pos.value === 0) &&
                !massiveEditModal.enabledAt
              }
              icon={faEdit}
              onClick={() => {
                const { pos, payment, enabledAt } = massiveEditModal
                const request = {
                  posId: 0,
                  paymentType: null,
                  enabledAt: null,
                  orderIds: [],
                }

                if (!(!pos || pos.value === 0)) request.posId = pos.value

                if (!(!payment || !payment.value)) request.paymentType = payment.value
                if (enabledAt) request.enabledAt = enabledAt.valueOf()
                if (all) setUp(false, null, request)
                else {
                  request.orderIds = selected.map(s => s.id)
                  dispatch(updateListOrders(request, {}))
                }
              }}>
              Actualizar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <OrderMassiveConfirmation
        show={massiveConfirmationModal.show}
        selected={massiveConfirmationModal.selected}
        all={massiveConfirmationModal.all}
        filters={getQueryParams()}
        onHide={refresh => {
          setMassiveConfirmationModal({})
          if (refresh) setUp()
        }}
        cancel={massiveConfirmationModal.cancel}
        certify={massiveConfirmationModal.certify}
      />

      <Alert {...alert} />
      <AlertKolo {...alertKolo} />

      <CreateCreditNoteSell
        show={createCreditNote.show}
        orderId={createCreditNote.orderId}
        code={createCreditNote.number}
        clientId={createCreditNote.clientId}
        onHide={update => {
          setCreateCreditNote({ ...createCreditNote, show: false })
          if (update) setUp()
        }}
      />

      <OrderHistorical
        {...versions}
        canSeeMoneyValues={canSeeGeneralBalance}
        onHide={() => setVersions({ show: false, id: null })}
      />
    </div>
  )
}
export default geolocated({
  positionOptions: { enableHighAccuracy: true, userDecisionTimeout: 5000 },
})(OrdersPage)
