import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Title, Card, Switch, Select, FABV2 } from 'src/components'
import { itemTypes } from 'src/components/Items/ItemsToSelect'
import { faCheckDouble } from '@fortawesome/free-solid-svg-icons'

import { types } from 'src/actions/inventory.actions'

import { actionTypes as actionW, getWarehouseLite } from 'src/actions/warehouse.actions'
import { selectOwnWarehouseLite } from 'src/selectors/warehouse.selector'

import { loadingSelector } from 'src/selectors/loading.selector'
import { haveAnyValue } from 'src/utils/utilitiesV2'

import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import InventoryModalDescription from 'src/components/inventory/InventoryDescriptionModal/InventoryDescModal'
import { showAlert } from 'src/actions/alert.actions'
import { History, LocationState } from 'history'
import { ItemSelectionTable } from 'src/components/Transaction/ItemSelectionTable'

interface WasteRequestProps {
  history: History<LocationState>
}

const WasteRequestV2: React.FC<WasteRequestProps> = ({ history }: WasteRequestProps) => {
  const dispatch = useDispatch()

  const ownWarehouses = useSelector(selectOwnWarehouseLite)
  const loadingW = useSelector(state =>
    loadingSelector([actionW.GET_WAREHOUSE_LITE])(state),
  )
  const loadingC = useSelector(state => loadingSelector([types.ON_CREATE_REQUEST])(state))
  const hasErrorC = useSelector(state => hasErrors([types.ON_CREATE_REQUEST])(state))

  const [flags, setFlags] = useState({ create: false, getW: false })
  const [inventoryDescModal, setInventoryDescModal] = useState(false)
  const [isWaste] = useState(window.location.href.includes('/waste'))
  const [fromWarehouse, setFromWarehouse] = useState({
    value: null,
    label: 'Sin seleccionar',
  })
  const [selected, setSelected] = useState([])
  const [allInventory, setAllInventory] = useState(false)
  const [dataForModal, setDataForModal] = useState<dataRequestForInventory>({
    type: 0,
    warehouseId: 0,
    details: null,
    isAllInventory: false,
  })

  useEffect(() => {
    dispatch(getWarehouseLite())
  }, [])

  useEffect(() => {
    if (ownWarehouses.length === 0) return
    if (ownWarehouses.length > 0 && fromWarehouse.value === null)
      setFromWarehouse(ownWarehouses[0])
  }, [ownWarehouses])

  useEffect(() => {
    if (loadingC) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (hasErrorC) dispatch(showAlert(handlerError(hasErrorC.message)))
      else {
        setInventoryDescModal(false)
        dispatch(
          showAlert({
            ...handlerSuccess('Solicitud enviada satisfactorimante'),
            onConfirm: () => {
              history.goBack()
            },
          }),
        )
      }
    }
  }, [loadingC])

  const processDetails = (allInventory: boolean, selected?: SelectedItem[]): Detail[] => {
    const details: Detail[] = []
    if (allInventory) {
      details.push({ productId: 0, quantity: 0, variations: [], listIdLocations: [] })
    } else if (selected && selected.length > 0) {
      selected.forEach((s: SelectedItem) => {
        if (s.line && s.line.length > 0) {
          s.line.forEach((lineItem: LineItem) => {
            if (lineItem.quantity > 0) {
              details.push({
                productId: lineItem.productId,
                quantity: lineItem.quantity,
                amount: lineItem.ownPrice || 0,
                variations: s.listVariations ? s.listVariations.map(x => x.id) : [],
                listIdLocations: s.listLocations
                  ? s.listLocations.map(x => x.id || x.value)
                  : [],
                commentary: s.commentary,
                originDocumentId: s.originDocumentId,
                originDocumentTypeId: s.originDocumentTypeId,
                batch: s.inBatches ? s.batch : null,
                inBatches: s.inBatches,
                expDate: s.expirationDate != null ? s.expirationDate.valueOf() : null,
              })
            }
          })
        }
      })
    }
    return details
  }
  const processInventoryRequest = () => {
    const type = isWaste ? 3 : 1
    const wareHouseId = fromWarehouse.value
    const details = processDetails(allInventory, selected)
    let errorMessage = ''

    if (details?.length > 0) {
      if (
        details.some(
          x => x.inBatches && (!haveAnyValue(x.batch) || !haveAnyValue(x.expDate)),
        ) &&
        !isWaste
      )
        errorMessage += 'Existen productos que requieren lote o fecha de vencimiento'
    }
    if (!fromWarehouse.value) {
      errorMessage += 'Bodega saliente, '
    }
    if (!allInventory) {
      if (selected.length === 0) {
        errorMessage += `Ítems a ${isWaste ? 'mermar' : 'producir'}`
      } else {
        if (!validateQuantities()) {
          errorMessage += 'ítems sin cantidad asignada'
        }
      }
    }
    if (errorMessage.length > 0) {
      dispatch(showAlert(handlerError('Campos Obligatorios: ' + errorMessage)))
    } else {
      setDataForModal({
        ...dataForModal,
        type: type,
        warehouseId: wareHouseId,
        details: details,
        isAllInventory: allInventory,
      })
      setInventoryDescModal(true)
    }
  }

  const validateQuantities = () => {
    return (
      selected.filter(i => i.value !== undefined && i.haveQuantities === true).length ===
      selected.length
    )
  }

  const itemDisabled = allInventory || loadingC
  const itemType = isWaste ? itemTypes.WASTE : itemTypes.PRODUCTION
  const titleInvent = isWaste
    ? 'Solicitud de Reducción de Inventario'
    : 'Solicitud de Ingreso de Inventario'
  return (
    <>
      <Row>
        <Col xl={12}>
          <Title title={titleInvent} />
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          <Card>
            <Row>
              <Col xl={12}>
                <div className={'space-between'}>
                  <div>
                    <h6 className={'dashboard-title dashboard-f-color'}>Mis Bodegas</h6>
                  </div>
                  {isWaste && (
                    <div>
                      <Switch
                        label={'Inventario completo'}
                        mt={0}
                        checked={allInventory}
                        changeValue={value => setAllInventory(value)}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col xl={12}>
                <Select
                  label={'Seleccionar una bodega'}
                  options={ownWarehouses}
                  value={fromWarehouse}
                  loading={loadingW}
                  onChange={fw => {
                    setFromWarehouse(fw)
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={12} lg={12} md={12} sm={12}>
          {/* @ts-expect-error JS */}
          <ItemSelectionTable
            warehouseId={fromWarehouse.value}
            warehouseName={fromWarehouse.label}
            type={itemType}
            onSelected={selected => {
              setSelected(selected)
            }}
            allInventory={allInventory}
          />
        </Col>
      </Row>

      <FABV2
        show
        title={'Crear'}
        icon={faCheckDouble}
        loading={loadingC}
        onClick={processInventoryRequest}
      />

      <InventoryModalDescription
        show={inventoryDescModal}
        onHide={() => setInventoryDescModal(false)}
        data={dataForModal}
      />
    </>
  )
}
export default WasteRequestV2
