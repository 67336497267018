import { haveAnyValue } from 'src/utils/utilitiesV2'

export const mapFieldToEntity = (
  entityFields: EntityFields[],
  values?: DynamicFieldRequest[],
): DynamicFieldEntity[] => {
  const withValues = values ?? []
  return entityFields.map(({ dynamicField, options }) => {
    const preValue = withValues.find(wv => wv.fieldId === dynamicField.id)
    return {
      id: dynamicField.id,
      valueId: preValue?.id,
      dataType: dynamicField.dataType,
      description: dynamicField.description,
      label: dynamicField.label,
      name: dynamicField.name,
      required: dynamicField.required,
      value: preValue?.value,
      options: options?.map(op => ({
        value: op.value,
        label: op.value,
      })),
    }
  })
}

interface validationResponse {
  ok: boolean
  fields?: string
}
export const validateRequiredFields = (
  fields: DynamicFieldEntity[],
): validationResponse => {
  if (fields.length > 0) {
    const requiredAndEmptyFields = fields.filter(
      df => df.required && !haveAnyValue(df.value),
    )
    if (requiredAndEmptyFields.length > 0) {
      const emptyFields = requiredAndEmptyFields
        .filter(rf => !haveAnyValue(rf.value))
        .map(rf => rf.label)
        .join(', ')
      return {
        ok: false,
        fields: emptyFields,
      }
    }
  }
  return { ok: true }
}

export const mapFieldsToRequest = (
  fields: DynamicFieldEntity[],
): DynamicFieldRequest[] => {
  return fields.map(f => ({
    id: f.valueId,
    fieldId: f.id,
    value: f.value,
  }))
}

export const mapToLabel = (fields: DynamicFieldEntity[]): DynamicFieldValueLabel[] => {
  return fields.map(field => ({
    id: field.valueId,
    label: field.label,
    value: field.value,
    entityId: undefined,
    fieldId: field.id,
    type: field.dataType,
  }))
}
