import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAllowed } from 'src/selectors/modules.selector'
import { useMemo } from 'react'
import { OrderTypeEnum } from 'src/enums/order'

export interface MoneyPermissionsResponse {
  moneyValues: boolean
  summativeValues: boolean
}

interface MoneyPermissions {
  moneyPermissions: number[]
  summativePermissions: number[]
}

export const useMoneyPermission = (type?: OrderTypeEnum): MoneyPermissionsResponse => {
  const location = useLocation()

  const permissions: MoneyPermissions = useMemo(() => {
    let moneyPermissions: number[] = []
    let summativePermissions: number[] = []
    const path = location.pathname
    if (type ? type === OrderTypeEnum.SALE : path.includes('/ordenes')) {
      moneyPermissions = [1135]
      summativePermissions = [1107]
    } else if (type ? type === OrderTypeEnum.QUOTE : path.includes('cotizaciones')) {
      moneyPermissions = [6462]
      summativePermissions = [6459]
    } else if (type ? type === OrderTypeEnum.VISIT : path.includes('visita')) {
      moneyPermissions = [1602]
      summativePermissions = [1602]
    } else if (type ? type === OrderTypeEnum.DEVOLUTION : path.includes('devoluciones')) {
      moneyPermissions = [7202]
      summativePermissions = moneyPermissions
    }
    return {
      moneyPermissions,
      summativePermissions,
    }
  }, [location.pathname, type])

  const moneyValues = useSelector(state => isAllowed(state, permissions.moneyPermissions))
  const summativeValues = useSelector(state =>
    isAllowed(state, permissions.summativePermissions),
  )

  return {
    moneyValues: moneyValues,
    summativeValues: summativeValues && moneyValues,
  }
}
