export enum dynamicEntityType {
  CLIENTS = 1,
  ITEMS = 2,
  SALES = 3,
  PROVIDERS = 4,
  INVENTORY_CHANGE = 5,
  PURCHASE_EXPENSE = 6,
}

export const entityOptions = [
  { id: dynamicEntityType.CLIENTS, label: 'Clientes' },
  // { id: dynamicEntityType.ITEMS, label: 'Items' },
  { id: dynamicEntityType.SALES, label: 'Ventas' },
  { id: dynamicEntityType.INVENTORY_CHANGE, label: 'Ingreso y Reducción de inventario' },
  { id: dynamicEntityType.PURCHASE_EXPENSE, label: 'Compras y Gastos' },
  // { id: dynamicEntityType.PROVIDERS, label: 'Proveedores' },
]

export const enum DynamicFieldType {
  TEXT_FIELD = 1,
  SELECTOR = 2,
  SWITCH = 3,
  NUMBER = 4,
}

const dataTypeOptions = [
  { id: DynamicFieldType.TEXT_FIELD, label: 'Texto' },

  { id: DynamicFieldType.SELECTOR, label: 'Selector' },

  { id: DynamicFieldType.SWITCH, label: 'Switch' },

  { id: DynamicFieldType.NUMBER, label: 'Numero' },
]

export enum dynamicFieldsPermissions {
  viewDynamicFields = 8801,
  createDynamicFields = 8802,
  editDynamicFields = 8803,
  deleteDynamicFields = 8804,
  createDynamicFieldsValue = 8805,
  editDynamicFieldsValue = 8806,
  deleteDynamicFieldsValue = 8807,
}

export { dataTypeOptions }
