import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Row, Col, Modal } from 'react-bootstrap'
import {
  Title,
  TableV2,
  Card,
  CustomTabs,
  Dropdown,
  Select,
  SelectedDates,
  Button,
  FABV2,
  Paragraph,
  CustomDate,
  CustomSummary,
} from 'src/components'
import {
  faArrowsAltV,
  faCalendarPlus,
  faCheckCircle,
  faClock,
  faCog,
  faCogs,
  faEye,
  faFileExcel,
  faFileInvoice,
  faFilePdf,
  faPlus,
  faSave,
  faSearch,
  faUserClock,
  faUsers,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getCompanies,
  getCompaniesExcel,
  getPlanOrderDetails,
  getPlanOrders,
  updateDateAccount,
  getExpiredInvoicesReport,
} from 'src/actions/mentor.actions'
import {
  selectCompanyMentorPaginated,
  selectPlanOrderDetails,
  selectPlanOrders,
} from 'src/selectors/mentor.selector'

import { selectCurrentUser } from 'src/selectors/user.selector'

import { loadingSelector } from 'src/selectors/loading.selector'

import { setModalUsers } from 'src/actions/utilities.actions'

import {
  isAllowed,
  selectCurrentCompanyUsersForSelect,
} from 'src/selectors/modules.selector'

import { mentorPermissions } from 'src/enums/permissions'
import { PlanEnum, principalPlans } from 'src/enums/planTypes'

import { showAlert } from 'src/actions/alert.actions'
import {
  handlerError,
  handlerInfoWithButtons,
  handlerSuccess,
  hasErrors,
} from 'src/selectors/error.selector'

import { formatDaysFromMillis } from 'src/utils/formatters'

import FELCompanyConfig from './FELCompanyConfig'
import ManualConfiguration from './ManualConfiguration'
import MentorConfiguration from './MentorConfiguration'
import CompanyAddons from './Addon/CompanyAddons'
import Plans from 'src/content/Distrbution/Plans/Plans'
import CreateOrUpdateCompany from './Company/CreateOrUpdateCompany'
import FinishDemoAccount from './FinishDemoAccount'
import HistoryPlan from 'src/content/Distrbution/Plans/HistoryPlan'
import {
  getDTEReport,
  actionTypes as companyActionTypes,
  getDTEReportExcel,
} from 'src/actions/company.actions'
import { selectDTEReport } from 'src/selectors/company.selector'
import CompanyConfiguration from '../../Config/CompanyConfiguration'
import IconButton from 'src/components/buttons/IconButton'
import { ConfigNotificationFields } from 'src/content/Turns/Modal/ConfigNotificationFields'
import { getCurrentCompanyUsers } from 'src/actions/modules.actions'
import { CompanyActivity } from './CompanyActivity'

interface ModalDTE {
  show: boolean
  start?: Date
  end?: Date
}

const activeCompany = [
  { value: true, label: 'Activas' },
  { value: false, label: 'Inactivas' },
]

const today = new Date()
const start = new Date(today.getFullYear(), today.getMonth(), 1)
const end = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)

const initialDates = {
  start,
  end,
}

const reportDTEHeaders = [
  {
    label: 'Empresa',
    show: true,
    value: ['companyName'],
    type: 'text',
    className: 'name',
  },
  {
    label: 'DTE Emitidas',
    show: true,
    type: 'text',
    className: 'center',
    value: ['issued'],
  },
  {
    label: 'DTE Anuladas',
    show: true,
    value: ['anulated'],
    type: 'wholeNumber',
    className: 'center',
  },
  { config: true, show: true, label: '', className: 'mini center' },
]

/** APP of mentors where it is possible to manage DEMO and Operational accounts
 * @page
 * */
const Mentor = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const reportDTE = useSelector(selectDTEReport)
  const users = useSelector(selectCurrentCompanyUsersForSelect)
  const issuedDTE = reportDTE.reduce((acc, item) => acc + item.issued, 0)
  const nullifiedDTE = reportDTE.reduce((acc, item) => acc + item.anulated, 0)
  const totalDTE = issuedDTE + nullifiedDTE
  const planOrders = useSelector(selectPlanOrders)
  const planOrderDetails: MPlanOrderDetail = useSelector(selectPlanOrderDetails)

  const companyMentorPaginated: MCompanyPaginated = useSelector(
    selectCompanyMentorPaginated,
  )
  const loadingPlanOrders = useSelector(state =>
    loadingSelector([actionTypes.GET_PLAN_ORDERS])(state),
  )
  const loadingPlanOrderDetails = useSelector(state =>
    loadingSelector([actionTypes.GET_PLAN_ORDER_DETAILS])(state),
  )

  const loading = useSelector(state =>
    loadingSelector([actionTypes.GET_MENTOR_COMPANIES])(state),
  )

  const loadingUpdateDate = useSelector(state =>
    loadingSelector([actionTypes.UPDATE_FINISH_DATE_DEMO])(state),
  )
  const hasErrorUpdateDate = useSelector(state =>
    hasErrors([actionTypes.UPDATE_FINISH_DATE_DEMO])(state),
  )

  const loadingDTEReport = useSelector(state =>
    loadingSelector([companyActionTypes.DTE_REPORT])(state),
  )

  const loadingDTEReportExcel = useSelector(state =>
    loadingSelector([companyActionTypes.DTE_REPORT_EXCEL])(state),
  )

  const loadingCompaniesExcel = useSelector(state =>
    loadingSelector([actionTypes.GET_MENTOR_COMPANIES_EXCEL])(state),
  )

  const loadingInvoiceExpired = useSelector(state =>
    loadingSelector([actionTypes.GET_COMPANY_INVOICE_EXPIRED])(state),
  )

  /** PERMISSIONS **/
  const felConfig = useSelector(state => isAllowed(state, [mentorPermissions.felConfig]))
  const updatePlan = useSelector(state =>
    isAllowed(state, [mentorPermissions.updatePlan]),
  )
  const updateUsers = useSelector(state =>
    isAllowed(state, [mentorPermissions.updateUsers]),
  )
  const createAccount = useSelector(state =>
    isAllowed(state, [mentorPermissions.createAccount]),
  )
  const updateDemoDate = useSelector(state =>
    isAllowed(state, [mentorPermissions.updateDemoDate]),
  )
  const canFinishDemo = useSelector(state =>
    isAllowed(state, [mentorPermissions.canFinishDemo]),
  )
  const canPostponePayments = useSelector(state =>
    isAllowed(state, [mentorPermissions.canPostponePayments]),
  )
  const canSeeDTEReport = useSelector(state =>
    isAllowed(state, [mentorPermissions.seeDTEReport]),
  )
  const canEditCompanyConfig = useSelector(state =>
    isAllowed(state, [mentorPermissions.canEditCompanyConfig]),
  )

  const [flags, setFlags] = useState({
    updateDate: false,
    loadingPlanOrderDetails: false,
  })
  const [modalDTE, setModalDTE] = useState<ModalDTE>({ show: false })

  const [coreFilters, setCoreFilters] = useState({
    skip: 0,
    size: 10,
    isDemo: false,
    search: undefined,
    onRenewal: false,
  })

  const [filters, setFilters] = useState({
    plan: { value: null, label: 'TODOS' },
    dateType: { value: null, label: 'SIN APLICAR' },
    start: new Date().setHours(0, 0, 0).valueOf(),
    end: new Date().setHours(23, 59, 59, 59).valueOf(),
    active: { value: true, label: 'ACTIVOS' },
  })

  const [coreFiltersText, setCoreFiltersText] = useState(null)

  const demoBehavior = coreFilters.isDemo || coreFilters.onRenewal
  const dateTypes = [
    { value: null, show: true, label: 'SIN APLICAR' },
    { value: 1, show: true, label: 'CREACIÓN' },
    { value: 2, show: demoBehavior, label: 'FINALIZACIÓN DE DEMO' },
    { value: 2, show: !demoBehavior, label: 'INICIO DE PLAN' },
    { value: 3, show: !demoBehavior, label: 'PRÓXIMA FACTURACIÓN' },
  ]

  const [manual, setManual] = useState({ show: false, item: {} })
  const [mentores, setMentores] = useState({
    show: false,
    id: null,
    name: null,
    idCurrent: null,
  })
  const [configFEL, setModalConfigFEL] = useState({
    show: false,
    id: null,
    name: '',
    country: 1,
  })

  const [companyPlan, setCompanyPlan] = useState({
    show: false,
    id: null,
    name: null,
  })

  const [companyDetail, setCompanyDetail] = useState({
    show: false,
    showAddon: false,
    id: undefined,
    title: null,
    isDemo: false,
    isRenewal: false,
    showOrders: false,
    planId: null,
    addons: null,
    orderId: null,
    clientId: null,
  })

  const [updateDemoDateModal, setDemoDateModal] = useState({
    show: false,
    date: null,
    id: null,
    title: null,
  })

  const [finishDemoModal, setFinishDemoModal] = useState({
    show: false,
    id: null,
    title: null,
    isRenewal: false,
    isDemo: false,
    plan: null,
    addons: null,
    clientId: null,
  })

  const [stateAccount, setStateAccount] = useState({
    show: false,
    id: undefined,
    title: undefined,
  })
  const [showNotifications, setShowNotifications] = useState(false)
  const [showCompanyActivity, setShowCompanyActivity] = useState(false)

  const [companyConfiguration, setCompanyConfiguration] =
    useState<IMentorCompanyModalConfiguration>({
      show: false,
      id: null,
      name: null,
    })

  useEffect(() => {
    setCoreFiltersText(JSON.stringify(coreFilters))
    dispatch(getCurrentCompanyUsers())
  }, [])

  useEffect(() => {
    if (coreFiltersText === null || coreFiltersText === JSON.stringify(coreFilters))
      return
    setCoreFiltersText(JSON.stringify(coreFilters))
  }, [coreFilters])

  useEffect(() => {
    if (coreFiltersText === JSON.stringify(coreFilters)) {
      setUp()
    }
  }, [coreFiltersText])

  useEffect(() => {
    if (loadingUpdateDate) setFlags({ ...flags, updateDate: true })
    else if (flags.updateDate) {
      setFlags({ ...flags, updateDate: false })
      const alert = hasErrorUpdateDate
        ? { ...handlerError(hasErrorUpdateDate.message) }
        : {
            ...handlerSuccess(),
            onConfirm: () => {
              setDemoDateModal({ ...updateDemoDateModal, show: false })
              setUp()
            },
          }

      dispatch(showAlert(alert))
    }
  }, [loadingUpdateDate])

  useEffect(() => {
    if (loadingPlanOrderDetails) setFlags({ ...flags, loadingPlanOrderDetails: true })
    else if (flags.loadingPlanOrderDetails) {
      setFlags({ ...flags, loadingPlanOrderDetails: false })
      if (planOrderDetails !== undefined) {
        setFinishDemoModal({
          ...finishDemoModal,
          show: true,
          id: companyDetail.id,
          isRenewal: companyDetail.isRenewal,
          isDemo: companyDetail.isDemo,
          plan: planOrderDetails.plan,
          addons: planOrderDetails.addons,
          clientId: companyDetail.clientId,
        })
        setCompanyDetail({
          ...companyDetail,
          orderId: planOrderDetails.plan.orderId,
          showOrders: false,
        })
      }
    }
  }, [loadingPlanOrderDetails])

  const setUp = (excel?: boolean) => {
    const params = {
      ...coreFilters,
      ...filters,
      dateType: filters?.dateType?.value,
      plan: filters?.plan?.value,
      active: filters?.active?.value,
    }
    if (excel) dispatch(getCompaniesExcel(params))
    else dispatch(getCompanies(params))
  }

  const customFilter = (
    <Row className={'mb-3'}>
      <Col xl={4} lg={4} md={6} sm={12} xs={12}>
        <Select
          label={'Por Plan'}
          value={filters.plan}
          options={[{ value: null, label: 'TODOS' }, ...principalPlans]}
          onChange={value => setFilters({ ...filters, plan: value })}
        />
      </Col>
      <Col xl={4} lg={4} md={6} sm={12} xs={12}>
        <Select
          label={'Por Estado'}
          value={filters.active}
          options={activeCompany}
          onChange={value => setFilters({ ...filters, active: value })}
        />
      </Col>
      <Col xl={4} lg={4} md={6} sm={12} xs={12}>
        <Select
          label={'Por Fechas'}
          value={filters.dateType}
          options={dateTypes.filter(dt => dt.show === true)}
          onChange={value => setFilters({ ...filters, dateType: value })}
        />
      </Col>
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <SelectedDates
          legend={'Se habilita al filtrar por fechas'}
          disabled={filters.dateType?.value === null}
          onDateChange={(start, end) => setFilters({ ...filters, start, end })}
          initialPreset={{ value: 0, label: 'Hoy' }}
        />
      </Col>
      {canSeeDTEReport && (
        <Col xl={2} lg={4} md={6} sm={6}>
          <div className={'column'}>
            <label className={'ftf-form-label left mt-3'}>Reportería</label>
            <Row
              className={'container-buttons'}
              style={{ paddingBottom: 2, display: 'flex' }}>
              <Button
                style={{ flex: 2 }}
                color={'primary'}
                onClick={() => {
                  setModalDTE({ show: true, ...initialDates })
                  dispatch(
                    getDTEReport({
                      start: initialDates.start.valueOf(),
                      end: initialDates.end.valueOf(),
                    }),
                  )
                }}>
                Facturas emitidas
              </Button>
            </Row>
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <Row className={'container-buttons'}>
          <Button
            loading={loading}
            color={'accent'}
            icon={faSearch}
            onClick={() => setUp()}>
            Aplicar Filtros
          </Button>
        </Row>
      </Col>
    </Row>
  )

  const table = (
    <TableV2
      /* @ts-expect-error*/
      customFilter={customFilter}
      loading={loading}
      items={companyMentorPaginated.response}
      total={companyMentorPaginated.total}
      getPagination={(skip, size, search) =>
        setCoreFilters({ ...coreFilters, skip, size, search })
      }
      customClass={'scrollX'}
      handleChange={search => setCoreFilters({ ...coreFilters, search })}
      headers={[
        { show: true, label: 'País', value: ['country', 'ext'], type: 'text' },
        { show: true, label: 'Código', value: ['code'], type: 'text' },
        { show: true, label: 'Nombre', value: ['name'], type: 'text' },
        { show: true, label: 'Alias', value: ['shortName'], type: 'text' },
        { show: true, label: 'Email', value: ['email'], type: 'text' },
        { show: true, label: 'Plan', value: ['planName'], type: 'text' },
        { show: true, label: 'Creado', value: ['createdAt'], type: 'date' },
        {
          show: !demoBehavior,
          label: 'Inicio de plan',
          value: ['activationDate'],
          type: 'date',
        },
        {
          show: !demoBehavior,
          label: 'Próxima facturación',
          value: ['nextPaymentAt'],
          type: 'date',
        },
        {
          show: demoBehavior,
          label: 'Tiempo restante',
          value: [''],
          type: 'text',
          custom: item =>
            formatDaysFromMillis(item.activationDate - new Date().valueOf()),
        },
        { show: true, config: true, label: 'Acciones', custom: item => getActions(item) },
      ]}
      noItemsLegend={'No se encontraron cuentas con los filtros aplicados'}
      mobileAuto
    />
  )

  const getActions = (item: MCompanyMentorResponse) => {
    return (
      <Dropdown
        items={[
          {
            show: true,
            title: 'Ver información',
            icon: faEye,
            action: () =>
              setCompanyDetail({
                show: true,
                id: item.id,
                title: `${item.code} ${item.name}`,
                showAddon: false,
                isDemo: item.demo,
                isRenewal: item.renewal,
                showOrders: false,
                planId: null,
                addons: null,
                orderId: null,
                clientId: item.clientId,
              }),
          },
          {
            show: true,
            title: 'Ver estado de cuenta',
            icon: faEye,
            action: () =>
              setStateAccount({
                show: true,
                id: item.clientId,
                title: `${item.code} ${item.name}`,
              }),
          },
          {
            show: false,
            title: 'Configurar manuales',
            action: () => setManual({ show: true, item }),
          },
          {
            icon: faUserShield,
            title: 'Mentores',
            action: () => {
              setMentores({
                show: true,
                id: item.id,
                name: item.name,
                idCurrent: user,
              })
            },
          },
          {
            icon: faArrowsAltV,
            show: updatePlan,
            title: 'Cambiar plan',
            action: () =>
              setCompanyPlan({
                show: true,
                id: item.id,
                name: `${item.code} ${item.name}`,
              }),
          },
          {
            icon: faUsers,
            show: updateUsers,
            title: 'Ver usuarios',
            action: () => {
              dispatch(
                setModalUsers({
                  show: true,
                  companyId: item.id,
                }),
              )
            },
          },
          {
            icon: faPlus,
            show: item.planId !== PlanEnum.FREE,
            title: 'Ver Addons',
            action: () =>
              setCompanyDetail({
                show: false,
                id: item.id,
                title: `${item.code} ${item.name}`,
                showAddon: true,
                isDemo: item.demo,
                isRenewal: item.renewal,
                showOrders: false,
                planId: null,
                addons: null,
                orderId: null,
                clientId: item.clientId,
              }),
          },
          {
            icon: faCalendarPlus,
            show: updateDemoDate && (item.demo || item.renewal),
            title: 'Actualizar Fecha de Finalización',
            action: () =>
              setDemoDateModal({
                show: true,
                date: item.activationDate,
                id: item.id,
                title: `${item.code} ${item.name}`,
              }),
          },
          {
            icon: faCalendarPlus,
            show: canFinishDemo && (item.demo || item.renewal),
            title: item.renewal ? 'Activar renovación' : 'Finalizar DEMO',
            action: () =>
              setFinishDemoModal({
                show: true,
                id: item.id,
                title: `${item.code} ${item.name}`,
                isRenewal: item.renewal,
                isDemo: item.demo,
                plan: null,
                addons: null,
                clientId: item.clientId,
              }),
          },
          {
            icon: faCalendarPlus,
            show: canFinishDemo && item.renewal,
            title: 'Activar renovación con factura',
            action: () => {
              dispatch(getPlanOrders())
              setCompanyDetail({
                show: false,
                id: item.id,
                title: null,
                showAddon: false,
                isRenewal: item.renewal,
                isDemo: item.demo,
                showOrders: true,
                planId: null,
                addons: null,
                orderId: null,
                clientId: item.clientId,
              })
            },
          },
          {
            icon: faCog,
            show: felConfig,
            title: 'Configuración FEL',
            action: () =>
              setModalConfigFEL({
                show: true,
                id: item.id,
                name: item.name,
                country: item.country?.id,
              }),
          },
          {
            icon: faCogs,
            show: canEditCompanyConfig,
            title: 'Editar configuraciones de empresa',
            action: () =>
              setCompanyConfiguration({ show: true, id: item.id, name: item.name }),
          },
        ]}
      />
    )
  }

  const generalActions = (
    <Row className={'container-buttons'} style={{ alignItems: 'center' }}>
      <IconButton
        icon={faUserClock}
        style={{ marginRight: '15px' }}
        tooltip={'Configurar notificaciones'}
        onClick={() => setShowNotifications(true)}
      />
      <Dropdown
        loading={loadingCompaniesExcel || loadingInvoiceExpired}
        items={[
          {
            icon: faFileInvoice,
            title: 'Ver facturas emitidas',
            action: () => {
              setModalDTE({ show: true, ...initialDates })
              dispatch(
                getDTEReport({
                  start: initialDates.start.valueOf(),
                  end: initialDates.end.valueOf(),
                }),
              )
            },
          },
          {
            icon: faFileExcel,
            title: 'Descargar información de empresas',
            action: () => setUp(true),
          },
          {
            icon: faClock,
            title: 'Ver actividad de empresas',
            action: () => setShowCompanyActivity(true),
          },
          {
            icon: faFileExcel,
            title: 'Descargar empresas con facturas próximas a vencer',
            action: () => dispatch(getExpiredInvoicesReport()),
          },
        ]}
      />
    </Row>
  )

  return (
    <div>
      <Title title={'Dashboard de mentores'} />
      <Card white title={'Listado de empresas'} button={generalActions}>
        <CustomTabs
          setKey={key =>
            setCoreFilters({
              ...coreFilters,
              isDemo: Number(key) === 1,
              onRenewal: Number(key) === 2,
            })
          }
          noCard
          items={[
            { title: 'Empresas Activas', component: table },
            { title: 'Empresas Demo', component: table },
            { title: 'Empresas en renovación', component: table },
          ]}
        />
      </Card>

      <Modal
        show={updateDemoDateModal.show}
        size={'sm'}
        centered
        onHide={() => setDemoDateModal({ ...updateDemoDateModal, show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar Fecha demo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <Paragraph>
                Actualiza la fecha de finalización de demo de la cuenta{' '}
                {updateDemoDateModal.title}
              </Paragraph>
            </Col>
            <Col xl={12}>
              <CustomDate
                disabled={loadingUpdateDate}
                label={'Fecha de finalización'}
                required
                value={new Date(updateDemoDateModal.date)}
                onDayChange={e =>
                  setDemoDateModal({ ...updateDemoDateModal, date: e.valueOf() })
                }
                disabledDays={{ before: new Date() }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              icon={faSave}
              loading={loadingUpdateDate}
              onClick={() =>
                dispatch(
                  showAlert({
                    ...handlerInfoWithButtons(
                      'Actualizar Fecha',
                      'Está acción puedes realizarla solo una única vez.',
                    ),
                    onConfirm: () =>
                      dispatch(
                        updateDateAccount(
                          updateDemoDateModal.id,
                          updateDemoDateModal.date,
                        ),
                      ),
                  }),
                )
              }>
              Actualizar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <ManualConfiguration
        show={manual.show}
        company={manual.item}
        onHide={() => {
          setManual({
            show: false,
            item: {},
          })
        }}
      />
      <MentorConfiguration
        {...mentores}
        onHide={() => {
          setMentores({ ...mentores, show: false })
        }}
        mentor={true}
      />

      <FELCompanyConfig
        show={configFEL.show}
        id={configFEL.id}
        companyName={configFEL.name}
        onHide={() => setModalConfigFEL({ ...configFEL, show: false })}
        country={configFEL.country}
      />

      {/*@ts-ignore*/}
      <Plans
        // @ts-ignore
        show={companyPlan.show}
        companyId={companyPlan.id}
        name={companyPlan.name}
        isDemo={demoBehavior}
        onHide={() => setCompanyPlan({ ...companyPlan, show: false })}
      />

      <CreateOrUpdateCompany
        {...companyDetail}
        onHide={update => {
          setCompanyDetail({ ...companyDetail, show: false, showOrders: false })
          setFinishDemoModal({ ...finishDemoModal, show: false })
          if (update) setUp()
        }}
      />

      <CompanyAddons
        show={companyDetail.showAddon}
        companyId={companyDetail.id}
        title={companyDetail.title}
        onHide={() => setCompanyDetail({ ...companyDetail, showAddon: false })}
        demo={companyDetail.isDemo}
        renewal={companyDetail.isRenewal}
      />

      <FinishDemoAccount
        {...finishDemoModal}
        onHide={(update: boolean) => {
          setFinishDemoModal({ ...finishDemoModal, show: false })
          if (finishDemoModal.plan !== null)
            setCompanyDetail({ ...companyDetail, showOrders: !update })
          if (update) setUp()
        }}
        comparison={companyDetail.isRenewal && finishDemoModal.plan !== null}
        onSave={
          companyDetail.orderId
            ? () =>
                setCompanyDetail({
                  ...companyDetail,
                  show: true,
                  planId: finishDemoModal.plan.id,
                  addons: finishDemoModal.addons,
                  orderId: finishDemoModal.plan.orderId,
                })
            : null
        }
      />

      <HistoryPlan
        show={stateAccount.show}
        id={stateAccount.id}
        name={stateAccount.title}
        modal
        canSkipPayment={canPostponePayments}
        mentorMode
        onHide={() => setStateAccount({ ...stateAccount, show: false })}
      />

      <CompanyConfiguration
        {...companyConfiguration}
        modal
        onHide={() => setCompanyConfiguration({ ...companyConfiguration, show: false })}
        mentorMode
      />

      <Modal
        show={modalDTE.show}
        size={'lg'}
        centered
        onHide={() => setModalDTE({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Reporte de DTE emitidas y anuladas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <CustomSummary
                items={[
                  {
                    show: true,
                    title: 'Vigentes:',
                    value: `${issuedDTE} DTE`,
                  },
                  {
                    show: true,
                    title: 'Anuladas:',
                    value: `${nullifiedDTE} DTE`,
                  },
                  {
                    show: true,
                    title: 'Total:',
                    value: `${totalDTE} DTE`,
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <TableV2
                /* @ts-expect-error */
                dateFilter
                dateTo={modalDTE.end}
                dateFrom={modalDTE.start}
                onDateChange={(start, end) => {
                  const dates = {
                    start: start.setHours(0, 0, 0),
                    end: end.setHours(23, 59, 59),
                  }

                  setModalDTE({
                    show: true,
                    ...dates,
                  })
                  dispatch(
                    getDTEReport({
                      start: dates.start.valueOf(),
                      end: dates.end.valueOf(),
                    }),
                  )
                }}
                loading={loadingDTEReport}
                items={reportDTE}
                mobileAuto
                storageKey={`reportDTE`}
                headers={reportDTEHeaders}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingDTEReport || loadingDTEReportExcel}
              icon={faFilePdf}
              onClick={() => {
                dispatch(
                  getDTEReportExcel({
                    start: modalDTE.start.valueOf(),
                    end: modalDTE.end.valueOf(),
                  }),
                )
              }}>
              Exportar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={companyDetail.showOrders}
        size={'lg'}
        centered
        onHide={() => setCompanyDetail({ ...companyDetail, showOrders: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Facturas disponibles para crear DEMO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12} md={12} sm={12}>
              <TableV2
                /* @ts-expect-error JS */
                loading={loadingPlanOrders}
                items={planOrders}
                storageKey={`planOrders`}
                headers={[
                  {
                    label: 'Código',
                    show: true,
                    value: ['number'],
                    type: 'text',
                  },
                  {
                    label: 'Cliente',
                    show: true,
                    value: ['clientName'],
                    type: 'text',
                  },
                  {
                    label: 'Fecha',
                    show: true,
                    value: ['enabledAt'],
                    type: 'date',
                  },
                  {
                    label: 'Total',
                    show: true,
                    value: ['total'],
                    type: 'currency',
                  },
                  {
                    show: true,
                    config: true,
                    type: 'text',
                    value: ['button'],
                    label: 'Acción',
                    custom: item => (
                      <IconButton
                        icon={faCheckCircle}
                        spin={loadingPlanOrderDetails}
                        tooltip={'Usar'}
                        onClick={() => {
                          dispatch(getPlanOrderDetails(item.id))
                        }}
                      />
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <ConfigNotificationFields
        usersToSelect={users}
        show={showNotifications}
        notificationField={83}
        title={'Configuración de notificaciones para reporte de empresas'}
        onHide={() => setShowNotifications(false)}
      />

      <CompanyActivity
        filters={{
          ...coreFilters,
          ...filters,
          dateType: filters?.dateType?.value,
          plan: filters?.plan?.value,
          active: filters?.active?.value,
        }}
        show={showCompanyActivity}
        onHide={() => setShowCompanyActivity(false)}
      />

      <FABV2
        show={createAccount}
        title={'Crear'}
        icon={faPlus}
        items={[
          {
            text: 'Crear DEMO',
            icon: faPlus,
            onClick: () =>
              setCompanyDetail({
                show: true,
                id: undefined,
                title: null,
                showAddon: false,
                isDemo: true,
                isRenewal: false,
                showOrders: false,
                planId: null,
                addons: null,
                orderId: null,
                clientId: null,
              }),
          },
          {
            text: 'Crear en base a factura',
            icon: faPlus,
            onClick: () => {
              dispatch(getPlanOrders())
              setCompanyDetail({
                show: false,
                id: undefined,
                title: null,
                showAddon: false,
                isDemo: true,
                isRenewal: false,
                showOrders: true,
                planId: null,
                addons: null,
                orderId: null,
                clientId: null,
              })
            },
          },
        ]}
      />
    </div>
  )
}
export default Mentor
