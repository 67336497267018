import {
  posConfigurationGroupFields,
  posPanelConfigurationFields,
  posPanelConfigurations,
} from 'src/enums/posPanelConfigurations'
import { Country } from 'src/enums/countries'
import { haveAnyValue } from 'src/utils/utilitiesV2'

const getItemsGroupedByTag = (items: IItemPOS[]): IItemsPOSGrouped[] => {
  const response: IItemsPOSGrouped[] = []
  items?.forEach(item => {
    const tagIndex = response.findIndex(t => t.tag === (item.tag || 'sin asignar'))
    if (tagIndex === -1) response.push({ tag: item.tag || 'sin asignar', items: [item] })
    else response[tagIndex].items.push(item)
  })
  return response.sort((a, b) => a.tag.localeCompare(b.tag))
}

const getPanelConfigurationField = (
  country: number,
  posStep: number,
): IPOSPanelConfiguration[] => {
  const fieldsList: IPOSPanelConfigurationField[] = Object.values(
    posPanelConfigurationFields,
  )
  return Object.values(posConfigurationGroupFields).map(groupField => {
    const fields: IPOSPanelConfigurationField[] = fieldsList
      .filter(field => {
        let toList = true
        if (field.countries) toList = field.countries.some(c => c === country)
        if (field.posSteps) toList = field.posSteps.some(c => c === posStep)
        return toList && groupField.type === field.groupBy
      })
      .map(field => {
        let name: string
        switch (field.type) {
          case posPanelConfigurations.payBill:
            if (country === Country.SV) name = 'DTE'
            else name = field.name
            break
          case posPanelConfigurations.payReceipt:
            if (country === Country.UNI) name = 'Vender'
            else name = field.name
            break
          default:
            name = field.name
            break
        }
        return { ...field, name }
      })
    return { ...groupField, fields }
  })
}

/**
 * Calculates the total existence for each base product from a list of items. Each item's quantity is adjusted
 * by its presentation factor (if any), and the results are aggregated by base product ID.
 * This method initializes the existence to zero for each base product and increments it based on the item quantities
 * and their presentation factors. This is useful for determining the total stock of base products.
 *
 * @param {IItemPOS[]} items - Array of item objects. Each item includes properties like productId, baseProductId,
 *                             quantity, and an optional presentationFactor.
 * @returns {Map<number, IPOSBaseExistence>} - Returns a map where each key is a base product ID and the value is an object
 *                                             containing the base product's ID and its aggregated existence.
 */

const getBaseExistence = (items: IItemPOS[]): Map<number, IPOSBaseExistence> => {
  const baseExistences = new Map<number, IPOSBaseExistence>()

  items?.forEach(item => {
    const baseId = haveAnyValue(item.baseProductId) ? item.baseProductId! : item.productId
    const presentationFactor = haveAnyValue(item.presentationFactor)
      ? item.presentationFactor!
      : 1

    const baseExistence = baseExistences.get(baseId) || {
      id: baseId,
      existence: 0,
    }

    baseExistence.existence += presentationFactor * item.quantity
    baseExistences.set(baseId, baseExistence)
  })

  return baseExistences
}

/**
 * Processes an array of items, filtering and sorting them based on their presentation factors and existences.
 * Calculates and updates the existence for base products using additional data provided by `itemsSelected`.
 * It builds a map where each key is the base product ID and each value is an object containing the base product's ID,
 * its calculated existence, and optionally its name. This function also accounts for previously selected items
 * to adjust the calculated existences.
 *
 * @param {IItemPOS[]} items - Array of item objects with properties like id, existence, presentationFactor, baseProductId, and decimals.
 * @param {Map<number, IPOSBaseExistence>} itemsSelected - Map of selected items with base product ID as keys and objects containing
 *                                                         existence data as values. This data is used to adjust the calculations
 *                                                         for base product existences.
 * @returns {Map<number, IPOSBaseExistence>} - A map where each key is a base product ID and the value is an object containing
 *                                             the base product's ID, its updated existence, and optionally its name.
 */

const getPresentationExistence = (
  items: IItemPOS[],
  itemsSelected: Map<number, IPOSBaseExistence>,
): Map<number, IPOSBaseExistence> => {
  const baseExistences = new Map<number, IPOSBaseExistence>()
  const itemsExistences = new Map<number, IPOSBaseExistence>()

  Object.assign([], items)
    .sort((a, b) => (b.presentationFactor || 1) - (a.presentationFactor || 1))
    .forEach(item => {
      const baseId = haveAnyValue(item.baseProductId) ? item.baseProductId! : item.id
      const presentationFactor = haveAnyValue(item.presentationFactor)
        ? item.presentationFactor!
        : 1

      const existenceSelected = itemsSelected.get(baseId)

      const baseExistence = baseExistences.get(baseId) || {
        id: baseId,
        existence: item.existence - (existenceSelected?.existence || 0),
        totalExistence: item.existence - (existenceSelected?.existence || 0),
      }

      const existence = baseExistence.existence / presentationFactor
      const presentationExistence =
        item.decimals && item.decimals > 0 ? existence : Math.trunc(existence)

      const totalExistence = baseExistence.totalExistence / presentationFactor
      const presentationTotalExistence =
        item.decimals && item.decimals > 0 ? totalExistence : Math.trunc(totalExistence)

      baseExistence.existence -= presentationExistence * presentationFactor
      baseExistences.set(baseId, baseExistence)

      itemsExistences.set(item.id, {
        id: item.id,
        existence: presentationExistence,
        totalExistence: presentationTotalExistence,
      })
    })
  return itemsExistences
}

/**
 * Retrieves the existence value for a specified item ID from a map of existences. If the item ID is not found in the map,
 * it returns 0. This function is designed to be used with a map generated by the `getPresentationExistence` function.
 *
 * @param {number} id - The ID of the item for which to retrieve the existence.
 * @param {Map<number, IPOSBaseExistence>} existences - A map containing existence data for items, where each key is an item ID
 *                                                      and each value is an object containing the item's existence.
 * @returns {number} The existence of the specified item ID if found; otherwise, returns 0.
 */

const getExistenceByItemId = (
  id: number,
  existences: Map<number, IPOSBaseExistence>,
): IPOSBaseExistence => {
  const defaultResponse: IPOSBaseExistence = { id, existence: 0, totalExistence: 0 }

  if (!existences || !existences.get) return defaultResponse
  const itemExistence = existences?.get(id)
  return itemExistence ? itemExistence : defaultResponse
}

const getContextCanvasToPOSLogo = (w?: number, h?: number): CanvasRenderingContext2D => {
  const canvas = document.getElementById('canvas') as HTMLCanvasElement
  const context = canvas.getContext('2d')
  if (context) {
    const img = document.getElementById('ticket-logo') as HTMLImageElement
    if (img) context.drawImage(img, 0, 0, w ?? 300, h ?? 175)
  }
  return context
}

export {
  getItemsGroupedByTag,
  getPanelConfigurationField,
  getPresentationExistence,
  getExistenceByItemId,
  getBaseExistence,
  getContextCanvasToPOSLogo,
}
