import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormText, Select, SwitchV2 } from 'src/components'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDynamicFieldsByEntityClients } from 'src/actions/dynamicfield.actions'
import { selectEntityFields } from 'src/selectors/dynamicFields.selector'

interface ClientAdditionalInfoProps {
  client?: IClient
  onChange?: (index: number, idfield: number, value: string) => void

  mode?: 'edit' | 'create'
  extraFields?: EntityFields[]
  valuesEntity?: FieldValue[]
  valuesEntityForBooleanArray?: any
  onChangeValues?: (updatedValues: FieldValue[]) => void
}

interface DataBoolean {
  value: boolean
}

function mapArray(arr) {
  return arr.map(item => ({
    value: item.id.toString(),
    label: item.value,
  }))
}

export const ClientAdditionalInfo: React.FC<ClientAdditionalInfoProps> = ({
  mode,
  onChange,

  client,
  onChangeValues,

  extraFields,
  valuesEntity,
}: ClientAdditionalInfoProps) => {
  const dispatch = useDispatch()
  const entityFields = useSelector(selectEntityFields)
  const [fields, setFields] = useState<FieldValue[]>([])
  const [loading, setLoading] = useState(false)
  const [dataBooleanArray, setDataBooleanArray] = useState<DataBoolean[]>([])
  const [localValues, setLocalValues] = useState(valuesEntity)

  useEffect(() => {
    setLocalValues(valuesEntity)
  }, [valuesEntity])

  const handleValueChange = (fieldId, newValue) => {
    const updatedValues = localValues.map(value =>
      value.fieldId === fieldId ? { ...value, value: newValue } : value,
    )

    // Add new field value if it does not exist
    if (!updatedValues.some(value => value.fieldId === fieldId)) {
      updatedValues.push({ fieldId, value: newValue })
    }

    setLocalValues(updatedValues)
    onChangeValues(updatedValues)
  }

  const valuesDict = localValues?.reduce((acc, curr) => {
    acc[curr.fieldId] = curr.value.toString()
    return acc
  }, {})

  useEffect(() => {
    if (valuesEntity) {
      setFields(valuesEntity)
    }
  }, [valuesEntity])

  useEffect(() => {
    dispatch(fetchDynamicFieldsByEntityClients())
  }, [])

  useEffect(() => {
    if (entityFields) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [entityFields])

  useEffect(() => {
    if (mode === 'create') {
      const newDataBooleanArray: DataBoolean[] = entityFields.map(() => ({
        value: false,
      }))
      setDataBooleanArray(newDataBooleanArray)
    }
  }, [entityFields])

  const setValueAtIndex = (index: number) => {
    setDataBooleanArray(prevArray => {
      const newArray = [...prevArray]
      if (index >= 0 && index < newArray.length) {
        newArray[index].value = !newArray[index]?.value
      }
      return newArray
    })
  }

  const handleInputChangeFields = (index: number, idfield: number, value: string) => {
    setFields(prevFields => {
      const newFields = [...prevFields]
      // @ts-ignore
      newFields[index] = { value: value, idField: idfield }
      return newFields
    })
    onChange(index, idfield, value)
  }

  return (
    <>
      <div className={'d-flex flex-column pl-2 w-full'}>
        {mode === 'create' && (
          <>
            <Row>
              {loading &&
                entityFields.map((field, index) => {
                  const { dataType, id, name, label, description, required } =
                    field.dynamicField
                  const isReqString = required

                  switch (dataType) {
                    case 1:
                      return (
                        <Col key={index} xl={4} lg={4} md={6} sm={12}>
                          <FormText
                            name={name}
                            key={index}
                            label={name}
                            required={isReqString}
                            onChange={event => {
                              event.preventDefault()
                              handleInputChangeFields(index, id, event.target.value)
                            }}
                          />
                        </Col>
                      )
                    case 2:
                      return (
                        <Col key={index} xl={4} lg={4} md={6} sm={12}>
                          {isReqString ? (
                            <Select
                              key={index}
                              name={name}
                              label={label}
                              options={mapArray(field.options)}
                              required={isReqString}
                              onChange={e => {
                                handleInputChangeFields(index, id, e.value)
                              }}
                            />
                          ) : (
                            <Select
                              key={index}
                              name={name}
                              label={label}
                              placeholder={'Sin Seleccionar'}
                              options={mapArray(field.options)}
                              onChange={e => {
                                handleInputChangeFields(index, id, e.value)
                              }}
                            />
                          )}
                        </Col>
                      )
                    case 3:
                      return (
                        <Col key={index} xl={4} lg={4} md={6} sm={12}>
                          <SwitchV2
                            key={index}
                            // @ts-ignore
                            required={isReqString}
                            checked={dataBooleanArray[index]?.value}
                            onChange={() => {
                              setValueAtIndex(index)

                              handleInputChangeFields(
                                index,
                                id,
                                dataBooleanArray[index]?.value.toString(),
                              )
                            }}
                            label={label}
                            info={description}
                          />
                        </Col>
                      )
                    case 4:
                      return (
                        <Col key={index} xl={5} lg={5} md={6} sm={12}>
                          <FormText
                            name={name}
                            key={index}
                            label={name}
                            type={'number'}
                            required={isReqString}
                            onChange={event => {
                              event.preventDefault()
                              handleInputChangeFields(index, id, event.target.value)
                            }}
                          />
                        </Col>
                      )
                    default:
                      return null
                  }
                })}
            </Row>
          </>
        )}
        {mode === 'edit' && (
          <>
            <Row>
              {extraFields.map((field, index) => {
                const { dataType, id, name, label, description, required } =
                  field.dynamicField
                const currentValue = valuesDict[id]

                switch (dataType) {
                  case 1:
                    return (
                      <Col key={index} xl={4} lg={4} md={6} sm={12}>
                        <FormText
                          name={name}
                          key={index}
                          label={name}
                          id={name}
                          placeholder={currentValue || ''}
                          required={required}
                          value={currentValue || ''}
                          onChange={e => handleValueChange(id, e.target.value)}
                        />
                      </Col>
                    )
                  case 2:
                    // eslint-disable-next-line no-case-declarations
                    const options = field.options?.map(option => ({
                      value: option.id.toString(),
                      label: option.value,
                    }))
                    return (
                      <Col key={index} xl={4} lg={4} md={6} sm={12}>
                        <Select
                          name={name}
                          label={label}
                          options={options}
                          value={
                            options?.find(option => option.value === currentValue) || null
                          }
                          required={required}
                          placeholder={label}
                          onChange={selected => handleValueChange(id, selected?.value)}
                        />
                      </Col>
                    )

                  case 3:
                    return (
                      <Col key={index} xl={4} lg={4} md={6} sm={12}>
                        <SwitchV2
                          key={index}
                          required={required}
                          id={name}
                          checked={currentValue === 'true'}
                          onChange={checked => handleValueChange(id, checked.toString())}
                          label={label}
                          info={description}
                        />
                      </Col>
                    )
                  case 4:
                    return (
                      <Col key={index} xl={4} lg={4} md={6} sm={12}>
                        <FormText
                          name={name}
                          key={index}
                          type="number"
                          label={name}
                          placeholder={currentValue || ''}
                          required={required}
                          value={currentValue || ''}
                          onChange={e => handleValueChange(id, e.target.value)}
                        />
                      </Col>
                    )
                  default:
                    return null
                }
              })}
            </Row>
          </>
        )}
      </div>
    </>
  )
}
