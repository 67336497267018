import './Toolbar.scss'
import * as React from 'react'
import { connect } from 'react-redux'

import { Badge, Col, Container, Modal, Row } from 'react-bootstrap'
import { Td, Tr } from 'react-super-responsive-table'
import { Decimal } from 'decimal.js'
import {
  faHourglassStart,
  faQuestionCircle,
  faSpinner,
  faStop,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import QrReader from 'react-qr-reader'
import Tooltip from 'react-bootstrap/Tooltip'

import { history } from '../../App'

import IconCart from '../../assets/images/newDesign/icon-cart.svg'
import LogoKolo from '../../assets/v2/logo-kolo.svg'
import MenuToogle from './MenuToogle'

import IconDirect from '../../assets/v2/icon-direct.svg'

import AccessCard from '../cards/access-card/AccessCard'
import PaginatedTable from '../Pagination/PaginatedTable'
import IconButton from '../buttons/IconButton'

import { expandMenu } from '../../actions/dashboard.actions'
import { selectMenuCollapsed } from '../../selectors/dashboard.selector'

import {
  getDirectAction,
  getPermission,
  isAllowed,
  withPermission,
} from '../../selectors/modules.selector'
import {
  actionTypes as typeM,
  getCurrentUserRolesAction,
  revokeFavorite,
  setFavorite,
} from '../../actions/modules.actions'

import {
  selectCurrentModule,
  selectCurrentUser,
  selectUserProfile,
} from '../../selectors/user.selector'

import {
  actionTypes as types,
  getOrderByNotification,
  getRecentNotifications,
  markAsReadList,
} from '../../actions/notifications.actions'
import {
  selectOrderNotificiation,
  selectRecentNotifications,
} from '../../selectors/notifications.selector'

import {
  formatDateFromMillis,
  formatHourFromMillis,
  formatNumberWithCommas,
} from '../../utils/formatters'

import { getAllOrderTypes } from '../../actions/orders.actions'

import { loadingSelector } from '../../selectors/loading.selector'

import { selectShopping } from '../../selectors/shopping.selector'
import { actionTypes as imageType } from '../../actions/uploads.actions'
import { selectLogo } from '../../selectors/uploads.selector'

import {
  selectHideNav,
  selectModalPendingPayment,
} from '../../selectors/utilities.selector'

import { selectTurn } from '../../selectors/turns.selector'
import {
  actionTypes as turnTypes,
  createTurn,
  finishTurn,
} from '../../actions/turn.actions'

import { ModalOrder } from '../modal/Orders/ModalOrder'
import NewUser from '../../content/Permissions/newUser/NewUser'
import {
  actionTypes as type,
  getUserProfile,
  updateUserProfile,
} from '../../actions/user.actions'

import ClientIcon from '../../assets/images/svg/client.svg'
import ModalLog from '../modal/ModalLog'
import ModalTransferDetail from '../../content/Transfer/DetailTransfers/ModalTransferDetail'
import { toMoney } from '../../utils/utilities'
import Money from '../Money/Money'

import {
  handlerError,
  handlerInfo,
  handlerSuccess,
  hasErrors,
} from '../../selectors/error.selector'
import SweetAlert from 'sweetalert-react/lib/SweetAlert'
import moment from 'moment'
import ModalUsers from 'src/components/modal/Users/ModalUsers'
import ModalAddUser from 'src/components/modal/Users/ModalAddUser'
import { selectSetCurrentCompany } from 'src/selectors/company.selector'
import ModalInvoicePackages from 'src/components/modal/Invoices/ModalInvoicePackages'
import ModalManageCards from 'src/content/Documents/Account/ModalManageCards'
import ModalCreateCard from 'src/content/Documents/Account/ModalCreateCard'
import ModalCreateConcept from 'src/content/Restaurant/Concepts/ModalCreateConcept'
import ModalManageConcepts from 'src/content/Restaurant/Concepts/ModalManageConcepts'
import ModalCreateClient from 'src/components/modal/Clients/ModalCreateClient'
import ModalCreateCashTransfer from 'src/components/Balance/ModalCreateCashTransfer'
import ClientDetail from 'src/components/custom/ClientDetail/ClientDetail'
import { setModalPendingPayments } from 'src/actions/utilities.actions'
import { selectPlanPendingPayment } from 'src/selectors/plans.selector'
import ModalOrderDetail from 'src/components/custom/OrderDetail/ModalOrderDetail'
import HTMLBuilder from 'src/components/HtmlBuilder/HTMLBuilder'
import VersionNotificationWrapper from '../Tooltip/BannerVersionWrapper'

class ToolBar extends React.Component {
  state = {
    show: false,
    get: true,
    showNotification: false,
    selected: {},
    showScanner: false,
    access: false,
    modalLog: { show: false, id: null },
    showProfile: false,
    showVersion: false,
    change: 0,
    modalTransfer: { show: false, id: null },
    interval: [],
    alert: { title: 'default' },
  }

  expandMenu = val => this.props.expandMenu(val)

  componentDidMount() {
    const interval = setInterval(() => {
      this.refresh()
    }, 1000)
    return () => clearInterval(interval)
  }

  refresh = () => {
    this.setState({ ...this.state, interval: [{ asd: true }] })
    this.validatePendingPaymentPlan()
  }

  validatePendingPaymentPlan = () => {
    const { setModalPendingPayments, modalPaymentActive, planPendingPayment } = this.props

    const key = modalPaymentActive.key
    if (!planPendingPayment) return

    const object = localStorage.getItem(key)
    if (object === null) {
      const today = new Date()
      setModalPendingPayments(true)
      localStorage.setItem(
        key,
        JSON.stringify({
          time: today.getTime(),
        }),
      )
      return
    }

    if (modalPaymentActive.active) return

    const now = new Date()
    const item = JSON.parse(object)
    const hoursToWait = now.getMinutes() >= 0 && now.getMinutes() <= 30 ? 0.17 : 0.033 // TODO: remove this line is only for QA
    // const hoursToWait = now.getDate() >= 0 && now.getDate() <= 5 ? 24 : 2
    const expiration = new Date(item.time + hoursToWait * 3600 * 1000)

    if (now > expiration) setModalPendingPayments(true)
  }

  UNSAFE_componentWillReceiveProps(next) {
    const {
      loading,
      loadingProfile,
      loadingS,
      loadingR,
      loadingFinishingTurn,
      finishTurnHasError,
      createTurnHasError,
    } = this.props

    if (loading && !next.loading) {
      this.setState({ showNotification: true })
    }

    if (loadingProfile && !next.loadingProfile) {
      this.setState({ showProfile: true })
    }

    if (loadingS && !next.loadingS) {
      this.props.getCurrentRoles(this.props.module)
    }

    if (loadingR && !next.loadingR) {
      this.props.getCurrentRoles(this.props.module)
    }

    if (createTurnHasError && !next.createTurnHasError) {
      let { alert } = this.state
      alert = handlerError(
        createTurnHasError?.message || 'Ha ocurrido un error al crear el turno',
      )
      alert.onConfirm = () => {
        this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ ...this.state, alert })
    }

    if (loadingFinishingTurn && !next.loadingFinishingTurn) {
      let { alert } = this.state
      if (!finishTurnHasError && next.finishTurnHasError)
        alert = handlerError(
          next.finishTurnHasError?.message ||
            'Ha ocurrido un error al finalizar el turno',
        )
      else alert = handlerSuccess('Turno finalizado satisfactoriamente')

      alert.onConfirm = () => {
        this.setState({ alert: { ...alert, show: false } })
      }
      this.setState({ ...this.state, alert })
    }
  }

  getOrderTotal = (details, type, balace) => {
    if (type === 3) return formatNumberWithCommas(balace * -1)
    if (!details) return formatNumberWithCommas(0)
    let total = 0
    details.map(d => (total += parseFloat(d.total) - parseFloat(d.discount)))
    return formatNumberWithCommas(total)
  }

  getLinkedInfo = item => {
    if ((item.type === 1 || item.type === 4) && item.linkedId) {
      this.setState({ selected: item })
      this.props.getSingleOrder(item.linkedId)
    } else if (item.type === 3) {
      this.setState({ modalTransfer: { show: true, id: item.linkedId }, show: false })
    } else if (item.type === 0) {
      this.setState({ showVersion: true, show: false })
    } else if (item.type === 7)
      this.setState({ modalLog: { show: true, id: item.linkedId }, show: false })
    else this.setState({ selected: {} })
  }

  renderNotifications = (item, index) => {
    return (
      <div
        key={`${index}`}
        style={{ border: '1px solid rgba(41,78,181,0.64)', cursor: 'pointer' }}
        onClick={() => this.getLinkedInfo(item)}>
        <div>
          <h5 style={{ fontSize: 13 }}>{item.head}</h5>
        </div>
        <div style={{ color: 'black', paddingBottom: 10 }}>
          <h6 style={{ fontSize: 12 }}>{item.body}</h6>
          <span style={{ fontSize: 9, color: 'rgba(42,44,42,0.5)' }}>
            {formatDateFromMillis(item.date)}
          </span>
        </div>
      </div>
    )
  }

  renderRowDetail = (item, index) => {
    return (
      <Tr className={'data'} key={`${index}`}>
        <Td className={'quantity'}>{item.quantity}</Td>
        <Td className={'product'}>{item.product ? item.product.name : 'N/A'}</Td>
        <Td className={'unit-price'}>
          {toMoney(new Decimal(item.total / item.quantity).toFixed(2))}
        </Td>
        <Td className={'subtotal-without-discount'}>{toMoney(item.total)}</Td>
        <Td className={'discount'}>{toMoney(item.discount)}</Td>
        <Td className={'subtotal'}>
          {toMoney(new Decimal(item.total - item.discount).toFixed(2))}
        </Td>
      </Tr>
    )
  }

  getUnread = () => {
    const { notifications } = this.props
    let quantity = 0
    notifications.map(n => {
      if (!n.read) {
        quantity++
      }
    })
    return quantity
  }

  markAsRead = () => {
    const { notifications } = this.props
    const { show } = this.state
    let ids = []
    notifications.map(n => {
      if (n.read === false) {
        ids.push(n.id)
      }
    })
    if (ids.length > 0 && !show) this.props.markAsReadList(ids)
    this.props.getRecentNotifications()
  }

  handleError = err => {
    console.error(err)
  }

  handleScan = data => {
    if (data) {
      let route = data.substring(19, data.length) //com
      if (route[0] !== '/') route = data.substring(18, data.length) //tk
      this.setState({ showScanner: false })
      history.push(route)
    }
  }

  changeFavorite = action => {
    this.setState({ change: action.id })
    if (action.favorite === true) this.props.revokeFavorite(action.id)
    else this.props.setFavorite(action.id)
  }

  getTime = item => {
    let time = 0
    if (!item.endDate) time = moment.utc().valueOf()
    else time = item.endDate

    return time - item.startDate
  }

  render() {
    const {
      isSignedIn,
      collapsed,
      notifications,
      order,
      shopping,
      getPermission,
      module,
      companyLogo,
      hide,
      user,
      currentCompany,
      currentUser,
      turn,
      createTurn,
      finishTurn,
      handlerInfo,
      loadingCreatingTurn,
      loadingFinishingTurn,
      turnsAllowed,
    } = this.props
    const {
      show,
      showNotification,
      selected,
      showScanner,
      access,
      showProfile,
      modalLog,
      alert,
    } = this.state
    let hasShoppingCar = getPermission(3103)
    let showAccess = module === 1000 || module === 2000
    const isTurnActive = turn && turn.id && !turn.endDate

    const conditionsPerAction = {
      2452: {
        condition: currentCompany?.owner === currentUser?.id,
        roles: [2451],
      },
      12302: {
        condition: false,
      },
      1052: {
        condition: false,
      },
      1060: {
        condition: false,
      },
      12002: {
        condition: false,
      },
      12004: {
        condition: false,
      },
      12005: {
        condition: false,
      },
      1757: {
        condition: false,
      },
    }

    const actions = this.props.actions?.filter(
      action =>
        !Object.keys(conditionsPerAction).includes(String(action.id)) ||
        (conditionsPerAction[action.id].roles?.every(role =>
          this.props.withPermission(role),
        ) &&
          (conditionsPerAction[action.id].condition === undefined ||
            conditionsPerAction[action.id].condition)),
    )

    return (
      isSignedIn &&
      !hide && (
        <nav>
          <div className={'space-between'} style={{ width: '100%' }}>
            <div className="d-flex flex-row align-center">
              <MenuToogle
                onToogle={e => {
                  e.preventDefault()
                  this.expandMenu(!collapsed)
                }}
              />

              <div id="logo" onClick={() => this.props.getRecentNotifications()}>
                <Link to={`${process.env.PUBLIC_URL}`}>
                  <h1>
                    <img
                      src={companyLogo && companyLogo.url ? companyLogo.url : LogoKolo}
                      className={'toolbar-logo'}
                      alt="Logo"
                    />
                  </h1>
                </Link>
              </div>
            </div>
            <div style={{ display: currentCompany.defaulter ? 'none' : 'flex' }}>
              <nav id={'navDirectAccess'}>
                <ul>{/*<VersionNotificationWrapper />*/}</ul>
                {turnsAllowed && (
                  <ul>
                    <div
                      style={{
                        marginRight: 15,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '24px',
                      }}>
                      <IconButton
                        placement="bottom"
                        style={{ height: 'fit-content' }}
                        className={`icon-tb ${
                          loadingCreatingTurn || loadingFinishingTurn ? 'spinner' : ''
                        }`}
                        disabled={loadingCreatingTurn || loadingFinishingTurn}
                        color={isTurnActive && !loadingFinishingTurn ? '#c44' : '#0066b4'}
                        icon={
                          loadingCreatingTurn || loadingFinishingTurn
                            ? faSpinner
                            : isTurnActive
                            ? faStop
                            : faHourglassStart
                        }
                        tooltip={
                          isTurnActive
                            ? `Finalizar turno (${formatHourFromMillis(
                                this.getTime(turn),
                                true,
                              )})`
                            : 'Empezar turno'
                        }
                        alt={'img'}
                        onClick={() => {
                          if (!isTurnActive) return createTurn(currentUser?.id)
                          let alert = handlerInfo('¿Está seguro de finalizar el turno?')
                          alert.showCancelButton = true

                          alert.onConfirm = () => {
                            this.setState({
                              ...this.state,
                              alert: { ...alert, show: false },
                            })
                            finishTurn(turn.id, currentUser?.id)
                          }

                          alert.onCancel = () =>
                            this.setState({
                              ...this.state,
                              alert: { ...alert, show: false },
                            })
                          this.setState({ ...this.state, alert })
                        }}
                      />
                    </div>
                  </ul>
                )}
                <ul>
                  <div style={{ marginRight: 15, cursor: 'pointer' }}>
                    <OverlayTrigger
                      style={{ dataHtml: true }}
                      placement={'bottom'}
                      overlay={<Tooltip id="tooltip">Accesos directos</Tooltip>}>
                      <img
                        className="icon-tb"
                        src={IconDirect}
                        onClick={() => this.setState({ access: true })}
                        alt={'img'}
                      />
                    </OverlayTrigger>
                  </div>
                </ul>

                <ul>
                  {!hasShoppingCar && (
                    <IconButton
                      placement={'bottom'}
                      icon={faQuestionCircle}
                      tooltip={'Ver manuales de uso'}
                      size={'2x'}
                      onClick={() => history.push('/manuales')}
                      color={'secondary'}
                      style={{ marginTop: 12 }}
                    />
                  )}
                </ul>
                <ul className={'nav-l1'}>
                  {(() => {
                    if (hasShoppingCar) {
                      return (
                        <OverlayTrigger
                          placement={'left'}
                          overlay={<Tooltip id={'tooltip'}> Ir al carrito</Tooltip>}>
                          <li style={{ textAlign: 'center' }}>
                            <img
                              className={'icon-cart'}
                              src={IconCart}
                              onClick={() => {
                                history.push('/cliente/productos/compra')
                              }}
                              alt={'img'}
                            />
                            {shopping.length > 0 && (
                              <Badge pill variant="danger">
                                {shopping.length}
                              </Badge>
                            )}
                          </li>
                        </OverlayTrigger>
                      )
                    }
                  })()}
                </ul>
              </nav>
            </div>
          </div>

          <Modal
            show={access}
            size={'lg'}
            centered
            onHide={() => this.setState({ access: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Accesos directos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="toolbar-access-cards">
                {showAccess &&
                  actions.map((action, index) => (
                    <AccessCard
                      size={'small'}
                      invert
                      small
                      key={index}
                      icon={action.icon}
                      name={action.name}
                      link={action.route}
                      favorite={action.favorite}
                      id={action.id}
                      method={() => {
                        this.changeFavorite(action)
                      }}
                      onClick={() => this.setState({ access: false })}
                      change={this.state.change}
                      loading={
                        action.favorite === true
                          ? this.props.loadingR
                          : this.props.loadingS
                      }
                    />
                  ))}
                <AccessCard
                  size={'small'}
                  invert
                  small
                  icon={ClientIcon}
                  key={10}
                  link={'profile'}
                  name={'Ver perfil'}
                  onClick={() => {
                    this.setState({ access: false })
                    this.props.getUserProfile()
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={showNotification}
            size={'lg'}
            centered
            onHide={() => this.setState({ showNotification: false })}>
            <Modal.Header closeButton>
              <Modal.Title>{selected.body}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Container>
                <Row>
                  <Col xs={8}>
                    <h4>
                      <b>Cliente: </b>
                      {order.client ? order.client.companyName : 'N/A'}
                    </h4>
                  </Col>
                  <Col xs={4} className={'text-center'}>
                    <b>Última fecha de actualización </b>
                    {order.date ? formatDateFromMillis(order.date) : 'N/A'}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h4>
                      <b>Bodega: </b>
                      {order.warehouse ? order.warehouse.name : 'Sin Bodega'}
                    </h4>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <br />
                  </Col>
                </Row>

                <Row className="text-center">
                  <Col>
                    <b>Tipo</b>
                    <br /> {order.orderTypeData ? order.orderTypeData.name : 'N/A'}
                  </Col>
                  <Col>
                    <b>Estado</b> <br />{' '}
                    {order.statusData ? order.statusData.name : 'N/A'}
                  </Col>
                  <Col>
                    <b>Tipo de venta</b> <br />{' '}
                    {order.paymentType
                      ? order.paymentType === 1
                        ? 'Contado'
                        : 'Crédito'
                      : '---'}
                  </Col>
                  {order.enabledAt ? (
                    <Col>
                      <b>Fecha estimada de entrega</b> <br />{' '}
                      {formatDateFromMillis(order.enabledAt)}
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
                {order.description ? (
                  <Row>
                    <Col>
                      <b>Descripción</b>
                      <br /> {order.description}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
                <Row>
                  <br />
                  <Col className={'text-center'}>
                    {order.deleted ? <b className={'red-text'}>Orden Eliminada</b> : ''}
                  </Col>
                </Row>
                <hr />
                {order && showNotification && (
                  <>
                    <PaginatedTable
                      headers={[
                        'Cantidad',
                        'Producto',
                        'Precio',
                        'Subtotal',
                        'Descuento',
                        'Total',
                      ]}
                      renderRow={this.renderRowDetail}
                      items={order.details}
                    />
                    <Row>
                      <Col xs={10} />
                      <Money component={Col} xs="auto">
                        <h2>
                          Total:{' '}
                          {this.getOrderTotal(order.details, order.type, order.balance)}
                        </h2>
                      </Money>
                    </Row>
                  </>
                )}
              </Container>
            </Modal.Body>
            <Modal.Footer />
          </Modal>

          <Modal
            show={showScanner}
            size={'md'}
            centered
            onHide={() => this.setState({ showScanner: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Scanner QR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Container>
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%' }}
                />
              </Container>
            </Modal.Body>
            <Modal.Footer />
          </Modal>

          <Modal
            show={showProfile}
            size={'lg'}
            centered
            onHide={() => this.setState({ showProfile: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Ver perfil</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <NewUser
                user={user}
                save={request => this.props.updateUserProfile(request)}
                disabled
              />
            </Modal.Body>
          </Modal>

          {(modalLog.id || modalLog.access) && (
            <ModalLog
              id={modalLog.id}
              modal
              access={true}
              newlog
              logType={1}
              onHide={() =>
                this.setState({ modalLog: { show: false, id: null, access: false } })
              }
              title={'Registros del Cliente'}
            />
          )}

          <ModalOrder />

          <ModalOrderDetail />

          <ModalCreateClient />
          <ClientDetail modal />

          {/*<ModalPurchase {...this.props} />*/}

          <ModalUsers />
          <ModalAddUser />

          <ModalInvoicePackages />

          <ModalCreateCard />
          <ModalManageCards />

          <ModalManageConcepts />
          <ModalCreateConcept />

          <ModalCreateCashTransfer />

          <HTMLBuilder />

          {this.state.modalTransfer.id && (
            <ModalTransferDetail
              size={'md'}
              show={this.state.modalTransfer.show}
              onHide={() => this.setState({ modalTransfer: { show: false, id: null } })}
              id={this.state.modalTransfer.id}
              title={'Transferencia'}
              withoutCompany
            />
          )}

          <SweetAlert {...alert} />
        </nav>
      )
    )
  }
}

const mapStateToProps = state => ({
  collapsed: selectMenuCollapsed(state),
  getState: state.modules,
  getPermission: id => getPermission(state, id),
  currentCompany: selectSetCurrentCompany(state),
  user: selectUserProfile(state),
  currentUser: selectCurrentUser(state),
  loadingProfile: loadingSelector([type.GET_PROFILE])(state),
  notifications: selectRecentNotifications(state),
  module: selectCurrentModule(state),
  //order
  order: selectOrderNotificiation(state),
  loading: loadingSelector([types.GET_ORDER_BY_NOTIFICATION])(state),

  loadingS: loadingSelector([typeM.SET_FAVORITE])(state),
  loadingR: loadingSelector([typeM.REVOKE_FAVORITE])(state),

  shopping: selectShopping(state),
  actions: getDirectAction(state),
  companyLogo: selectLogo(state),
  loadingLogo: loadingSelector([imageType.GET_LOGO])(state),
  hide: selectHideNav(state),
  turn: selectTurn(state),
  handlerInfo: message => handlerInfo(message),
  loadingCreatingTurn: loadingSelector([turnTypes.CREATE_TURN])(state),
  createTurnHasError: hasErrors([turnTypes.CREATE_TURN])(state),
  loadingFinishingTurn: loadingSelector([turnTypes.FINISH_TURN])(state),
  finishTurnHasError: hasErrors([turnTypes.FINISH_TURN])(state),
  turnsAllowed: isAllowed(state, [6603]),
  withPermission: roleId => withPermission(state, roleId),
  modalPaymentActive: selectModalPendingPayment(state),
  planPendingPayment: selectPlanPendingPayment(state),
})

const mapDispatchToProps = dispatch => ({
  expandMenu: val => dispatch(expandMenu(val)),
  getRecentNotifications: () => dispatch(getRecentNotifications()),
  getCurrentRoles: module => dispatch(getCurrentUserRolesAction(module)),
  markAsReadList: id => dispatch(markAsReadList(id)),
  //order
  getSingleOrder: id => dispatch(getOrderByNotification(id)),
  getAllOrderTypes: () => dispatch(getAllOrderTypes()),
  getUserProfile: () => dispatch(getUserProfile()),
  updateUserProfile: request => dispatch(updateUserProfile(request)),
  setFavorite: id => dispatch(setFavorite(id)),
  revokeFavorite: id => dispatch(revokeFavorite(id)),
  createTurn: userId => dispatch(createTurn(userId)),
  finishTurn: (id, userId) => dispatch(finishTurn(id, userId)),
  setModalPendingPayments: show => dispatch(setModalPendingPayments(show)),
})

const connectedToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBar)
export { connectedToolBar as ToolBar }
