import { extract, successState } from '../actions/global.actions'
import { actionTypes } from '../actions/dynamicfield.actions'

const initialState: DynamicFieldsState = {
  dynamicFields: [],
  status: 'idle',
  error: null,
  loading: false,
  dynamicFieldsByEntity: [],
  dynamicFieldsByEntitySales: [],
  dynamicFieldsByEntityWaste: [],
  dynamicFieldsByEntityPurchaseExpense: [],
  createdField: {},
  editedField: {},
  deletedField: null,
  dynamicFieldsOptions: [],
  dynamicFieldsValueByEntity: [],
}

const DynamicFieldsReducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ALL_FIELDS_BY_ENTITY_PURCHASE_EXPENSE):
      return {
        ...state,
        dynamicFieldsByEntityPurchaseExpense: extract(action.dynamicFieldsByEntity, []),
      }
    case successState(actionTypes.GET_ALL_FIELDS_BY_ENTITY_WASTE):
      return {
        ...state,
        dynamicFieldsByEntityWaste: extract(action.dynamicFieldsByEntity, []),
      }
    case successState(actionTypes.GET_ALL_FIELDS):
      return {
        ...state,
        dynamicFields: extract(action.dynamicFields, []),
      }
    case successState(actionTypes.GET_FIELDS_OPTIONS):
      return {
        ...state,
        dynamicFieldsOptions: extract(action.dynamicFieldsOptions, []),
      }
    case successState(actionTypes.GET_ALL_FIELDS_BY_ENTITY_SALES):
      return {
        ...state,
        dynamicFieldsByEntitySales: extract(action.dynamicFieldsByEntity, []),
      }
    case successState(actionTypes.GET_ALL_FIELDS_BY_ENTITY):
      return {
        ...state,
        dynamicFieldsByEntity: extract(action.dynamicFieldsByEntity, []),
      }
    case successState(actionTypes.GET_ALL_FIELDS_BY_VALUE_ENTITY):
      return {
        ...state,
        dynamicFieldsValueByEntity: extract(action.dynamicFieldsValueByEntity, []),
      }

    case successState(actionTypes.EDIT_FIELD_WITH_OPTIONS):
      return {
        ...state,
        dynamicFieldsOptions: extract(action.dynamicFieldsOptions, []),
      }
    case successState(actionTypes.DELETE_OPTIONS):
      return {
        ...state,
        dynamicFieldsOptions: extract(action.dynamicFieldsOptions, []),
      }
    case successState(actionTypes.CREATE_FIELD_FROM_EDIT_WITH_OPTIONS):
      return {
        ...state,
        dynamicFieldsOptions: extract(action.dynamicFieldsOptions, []),
      }

    case successState(actionTypes.CREATE_FIELD):
      return {
        ...state,
        createdField: extract(action.createdField, {}),
      }
    case successState(actionTypes.CREATE_FIELD_WITH_OPTIONS):
      return {
        ...state,
        createdField: extract(action.createdField, {}),
      }
    case successState(actionTypes.UPDATE_FIELD):
      return {
        ...state,
        editedField: extract(action.editedField, {}),
      }
    case successState(actionTypes.DELETE_FIELD):
      return {
        ...state,
        deletedField: extract(action.deletedField, null),
      }
    default:
      return state ? state : initialState
  }
}

export default DynamicFieldsReducer
