import './RecipeItems.scss'

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Media } from 'react-breakpoints'
import { Modal, Row, Col } from 'react-bootstrap'
import { FormText, SwitchV2, CustomTabs, Button } from 'src/components'
import { faCheck, faEdit, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { haveAnyValue } from 'src/utils/utilitiesV2'

import { getProductsSimple } from 'src/actions/products.actions'

import RecipeItems from './RecipeItems'
import RecipeItemsSelection from './RecipeItemsSelection'
import RecipeItemDetail from './RecipeItemDetail'

import {
  types,
  createEnumeration,
  updateEnumeration,
} from 'src/actions/enumeration.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'
import { showAlert } from 'src/actions/alert.actions'

interface IFlag {
  createOrUpdate: boolean
}
interface IUpdateItemProps {
  show: boolean
  from: boolean
  item: IRecipeItem
}

interface Props {
  title: string
  show: boolean
  onHide: (update?: boolean) => void
  onCallBack?: (recipe: IRecipe) => void
  createOrUpdate?: boolean
  update?: boolean
  fast?: boolean
  customRecipe?: IRecipe
}

const fromTitle = 'Insumos'
const toTitle = 'Resultado'

/**
 * This component can be used to create, edit or view a recipe
 * @param {Object} props - Component props
 * @param {string} props.title - Title of modal
 * @param {boolean} props.show - Open Modal with component
 * @param {function} props.onHide - Function to close modal
 * @param {function} props.onCallBack - Return Recipe
 * @param {boolean} props.createOrUpdate - Indicates whether the recipe should be created or edited
 * @param {boolean} props.fast - Indicates if it will be used for free conversions
 * @param {IRecipe} props.customRecipe - Recipe that will be assigned to the recipe displayed in the component
 */

const CreateOrUpdateRecipe = ({
  title,
  show,
  onHide,
  onCallBack,
  createOrUpdate = false,
  fast = false,
  customRecipe,
}: Props) => {
  const dispatch = useDispatch()

  const createOrUpdateLoading = useSelector(state =>
    loadingSelector([types.ON_CREATE_OR_UPDATE])(state),
  )
  const createOrUpdateError = useSelector(state =>
    hasErrors([types.ON_CREATE_OR_UPDATE])(state),
  )

  const [flags, setFlags] = useState<IFlag>({
    createOrUpdate: false,
  })

  const [recipe, setRecipe] = useState<IRecipe>({ items: [] })
  const [recipeErrors, setRecipeErros] = useState<IRecipeItemError>({})
  const [addItem, setAddItem] = useState({ from: false, show: false })
  const [updateItem, setUpdateItem] = useState<IUpdateItemProps>({
    show: false,
    from: false,
    item: {},
  })

  useEffect(() => {
    if (!show) return
    dispatch(getProductsSimple())
    if (customRecipe) setRecipe({ ...customRecipe, save: false })
  }, [show])

  useEffect(() => {
    if (createOrUpdateLoading) setFlags({ ...flags, createOrUpdate: true })
    else if (flags.createOrUpdate) {
      setFlags({ ...flags, createOrUpdate: false })

      const alert = createOrUpdateError
        ? { ...handlerError(createOrUpdateError.message) }
        : {
            ...handlerSuccess('Operación exitosa'),
            onConfirm: () => {
              onClose(true)
              if (onCallBack) onCallBack(recipe)
            },
          }

      dispatch(showAlert(alert))
    }
  }, [createOrUpdateLoading])

  const onClose = (update?: boolean) => {
    onHide(update)
    setRecipe({ items: [] })
  }

  const onSave = () => {
    const errors: IRecipeItemError = {}
    if (createOrUpdate || recipe.save)
      if (!haveAnyValue(recipe.name)) errors.name = 'Valor requerido'

    const input = recipe.items.filter(item => item.type === 1)
    const output = recipe.items.filter(item => item.type === 0)

    if (input.length === 0) errors.input = 'Es necesario seleccionar insumos'
    if (output.length === 0) errors.output = 'Es necesario seleccionar el resultado'
    if (recipe.items.some(item => !item.quantity || item.quantity <= 0))
      errors.items = 'Todos los ítems deben tener una cantidad mayor a 0'

    setRecipeErros(errors)
    if (Object.keys(errors).length === 0) {
      if (createOrUpdate || recipe.save) {
        const request: IRecipeRequest = {
          name: recipe.name,
          description: recipe.description,
          input,
          output,
        }
        if (recipe.id) dispatch(updateEnumeration(recipe.id, request))
        else dispatch(createEnumeration(request))
      }
      if (!createOrUpdate) {
        if (fast ? !recipe.save : true) {
          onClose(true)
          if (onCallBack) onCallBack(recipe)
        }
      }
    }
  }

  const renderRecipeItems = (type: number, title?: string) => {
    return (
      <RecipeItems
        disabled={createOrUpdateLoading}
        items={recipe.items.filter(item => item.type === type)}
        title={title}
        onAdd={() => setAddItem({ from: type === 1, show: true })}
        onEdit={item => setUpdateItem({ show: true, item, from: type === 1 })}
        onDelete={item => {
          const customItems = Object.assign([], recipe.items)

          const index = customItems.findIndex(
            customItem => customItem.product === item.product,
          )
          customItems.splice(index, 1)
          setRecipe({ ...recipe, items: customItems })
        }}
      />
    )
  }

  const renderMobileItems = (
    <CustomTabs
      items={[
        { title: fromTitle, component: renderRecipeItems(1) },
        { title: toTitle, component: renderRecipeItems(0) },
      ]}
    />
  )

  const renderDesktopItems = (
    <Row>
      <Col xl={6} lg={6} md={6}>
        {renderRecipeItems(1, fromTitle)}
      </Col>
      <Col xl={6} lg={6} md={6}>
        {renderRecipeItems(0, toTitle)}
      </Col>
    </Row>
  )

  return (
    <div>
      <Modal show={show && !addItem.show} centered size={'xl'} onHide={() => onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'custom-modal-body'}>
          <Row>
            <Col xl={fast ? 4 : 6} lg={fast ? 4 : 6} md={6} sm={12} xs={12}>
              <FormText
                label={'Nombre'}
                value={recipe.name}
                changeValue={name => setRecipe({ ...recipe, name })}
                required={createOrUpdate || recipe.save}
                error={recipeErrors.name}
              />
            </Col>
            <Col xl={fast ? 4 : 6} lg={fast ? 4 : 6} md={6} sm={12} xs={12}>
              <FormText
                label={'Descripción'}
                value={recipe.description}
                changeValue={description => setRecipe({ ...recipe, description })}
              />
            </Col>
            {fast && (
              <Col xl={4} lg={4} md={12} sm={12} xs={12} style={{ paddingTop: '15px' }}>
                <SwitchV2
                  checked={recipe.save}
                  label={'Guardar receta'}
                  info={'Al seleccionar está opción la receta construida será guardada'}
                  onChange={save => setRecipe({ ...recipe, save })}
                />
              </Col>
            )}
            <Col xl={12} xs={12}>
              <Media>
                {({ breakpoints, currentBreakpoint }) => {
                  const mobile =
                    breakpoints[currentBreakpoint] <= breakpoints.mobileTable2

                  if (mobile) return renderMobileItems
                  else return renderDesktopItems
                }}
              </Media>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              color={'secondary'}
              icon={faWindowClose}
              onClick={() => onClose()}
              disabled={createOrUpdateLoading}>
              Cerrar
            </Button>
            <Button
              icon={createOrUpdate ? (recipe.id ? faEdit : faSave) : faCheck}
              onClick={onSave}
              loading={createOrUpdateLoading}>
              {createOrUpdate ? (!recipe.id ? 'Crear' : 'Actualizar') : 'Continuar'}
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <RecipeItemsSelection
        show={addItem.show && !updateItem.show}
        title={addItem.from ? fromTitle : toTitle}
        onHide={() => setAddItem({ ...addItem, show: false })}
        selected={recipe.items}
        addItem={(item: IRecipeItem) =>
          setUpdateItem({ show: true, item, from: addItem.from })
        }
      />

      <RecipeItemDetail
        show={updateItem.show}
        item={updateItem.item}
        onHide={() => setUpdateItem({ ...updateItem, show: false })}
        showDate={!createOrUpdate && !updateItem.from}
        inBatches={!updateItem.from && updateItem.item.inBatches}
        onChange={(item: IRecipeItem) => {
          const customItems = Object.assign([], recipe.items)
          const index = customItems.findIndex(i => i.product === item.product)
          if (index > -1) customItems[index] = item
          else customItems.push({ ...item, type: updateItem.from ? 1 : 0 })
          setRecipe({ ...recipe, items: customItems })
          setUpdateItem({ ...updateItem, show: false })
        }}
      />
    </div>
  )
}
export default CreateOrUpdateRecipe
