export const getAllFields = (state: any) => state?.dynamicFields
export const selectDynamicFields = state => state?.dynamicFields?.dynamicFields
export const loadingFields = state => state?.dynamicFields.LOADING_FIELDS
export const fetchDynamicFieldsOptionsSelector = state =>
  state?.dynamicFields.dynamicFieldsOptions
export const selectEntityFields = state => state?.dynamicFields?.dynamicFieldsByEntity
export const selectEntityFieldsValueEntity = state =>
  state?.dynamicFields?.dynamicFieldsValueByEntity

export const selectEntitySalesFields = (state: IGlobalState) =>
  state.dynamicFields.dynamicFieldsByEntitySales
export const selectEntityWasteFields = (state: IGlobalState) =>
  state.dynamicFields.dynamicFieldsByEntityWaste
export const selectEntityPurchaseExpenseFields = (state: IGlobalState) =>
  state.dynamicFields.dynamicFieldsByEntityPurchaseExpense
