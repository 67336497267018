import React, { ReactElement, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Col, Row } from 'react-bootstrap'
import { ListCategories, Select } from 'src/components'

import { selectUsers } from 'src/selectors/modules.selector'

interface SelectUsersProps {
  label: string
  onChange: (users: ISelect[]) => void
}

const SelectUsers = ({ label, onChange }: SelectUsersProps): ReactElement => {
  const users: IUser[] = useSelector(selectUsers)

  const [items, setItems] = useState<ISelect[]>([])

  const onRemove = (item: ISelect) => {
    const index = items.findIndex(i => i.id === item.id)
    const newItems = [...items]
    newItems.splice(index, 1)
    onCallBack(newItems)
  }

  const onCallBack = (response: ISelect[]) => {
    setItems(response)

    onChange(response)
  }

  const filteredUsers = useMemo(() => {
    return users.filter(u => items.findIndex(i => i.value === u.id) === -1)
  }, [items, users])

  return (
    <Row>
      <Col xl={12}>
        <Select
          label={label}
          options={filteredUsers}
          value={{ label: 'Selecciona una opción', value: null }}
          onChange={(user: IUser) => {
            const newItems = [...items, { value: user.id, label: user.name }]
            onCallBack(newItems)
          }}
        />
      </Col>

      <Col xl={12}>
        <ListCategories items={items} onRemove={onRemove} />
      </Col>
    </Row>
  )
}
export default SelectUsers
