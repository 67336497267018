import CompanyDynamicfieldsService from '../services/companyDynamicfields.service'
import { executeAction } from './global.actions'
import { dynamicEntityType } from 'src/enums/dynamicFieldsEnum'
import { getState } from 'src/utils/utilities'
import {
  selectEntityPurchaseExpenseFields,
  selectEntitySalesFields,
  selectEntityWasteFields,
} from 'src/selectors/dynamicFields.selector'

export const actionTypes = {
  GET_ALL_FIELDS: 'GET_ALL_FIELDS',
  GET_FIELD_BY_ID: 'GET_FIELD_BY_ID',

  GET_ALL_FIELDS_BY_ENTITY: 'GET_ALL_FIELDS_BY_ENTITY_CLIENTS',
  GET_ALL_FIELDS_BY_ENTITY_SALES: 'GET_ALL_FIELDS_BY_ENTITY_SALES',
  GET_ALL_FIELDS_BY_ENTITY_WASTE: 'GET_ALL_FIELDS_BY_ENTITY_WASTE',
  GET_ALL_FIELDS_BY_ENTITY_PURCHASE_EXPENSE: 'GET_ALL_FIELDS_BY_ENTITY_PURCHASE_EXPENSE',

  GET_ALL_FIELDS_BY_VALUE_ENTITY: 'GET_ALL_FIELDS_BY_VALUE_ENTITY',
  CREATE_FIELD: 'CREATE_FIELD',

  CREATE_FIELD_ERROR: 'CREATE_FIELD_ERROR',

  CREATE_FIELD_WITH_OPTIONS: 'CREATE_FIELD_WITH_OPTIONS',

  CREATE_FIELD_WITH_OPTIONS_ERROR: 'CREATE_FIELD_WITH_OPTIONS_ERROR',

  CREATE_FIELD_FROM_EDIT_WITH_OPTIONS: 'CREATE_FIELD_FROM_EDIT_WITH_OPTIONS',
  EDIT_FIELD_WITH_OPTIONS: 'EDIT_FIELD_WITH_OPTIONS',
  DELETE_OPTIONS: 'DELETE_OPTIONS',
  UPDATE_FIELD: 'UPDATE_FIELD',
  DELETE_FIELD: 'DELETE_FIELD',
  GET_FIELD_BY_ENITITY: 'GET_FIELD_BY_ENITITY',
  CREATE_OPTION: 'CREATE_OPTION',
  GET_FIELDS_OPTIONS: 'GET_FIELDS_OPTIONS',
}

/**
 * Creates a new dynamic field and dispatches actions to update the state.
 * @param error
 *
 */
const createDynamicFieldError = error => ({
  type: actionTypes.CREATE_FIELD_ERROR,
  error,
})

const createDynamicWithOptionsFieldError = error => ({
  type: actionTypes.CREATE_FIELD_WITH_OPTIONS_ERROR,
  error,
})

/**
 * Fetches all dynamic fields and dispatches an action to update the state.
 * @function
 * @returns {Function} A function that dispatches the GET_ALL_FIELDS action.
 */
export const fetchDynamicFields = () => dispatch => {
  const process = async () => ({
    dynamicFields: await CompanyDynamicfieldsService.readAllExtraFields(),
  })
  dispatch(executeAction(actionTypes.GET_ALL_FIELDS, process))
}

export const fetchDynamicFieldsByEntityClients = () => dispatch => {
  const process = async () => ({
    dynamicFieldsByEntity: await CompanyDynamicfieldsService.readAllExtraFieldsByEntity(
      dynamicEntityType.CLIENTS,
    ),
  })
  dispatch(executeAction(actionTypes.GET_ALL_FIELDS_BY_ENTITY, process))
}

export const fetchDynamicFieldsByEntitySales = () => dispatch => {
  const fields = getState(selectEntitySalesFields)

  const process = async () => ({
    dynamicFieldsByEntity:
      fields.length > 0
        ? fields
        : await CompanyDynamicfieldsService.readAllExtraFieldsByEntity(
            dynamicEntityType.SALES,
          ),
  })
  dispatch(executeAction(actionTypes.GET_ALL_FIELDS_BY_ENTITY_SALES, process))
}

export const fetchDynamicFieldsByEntityWaste = () => dispatch => {
  const fields = getState(selectEntityWasteFields)

  const process = async () => ({
    dynamicFieldsByEntity:
      fields.length > 0
        ? fields
        : await CompanyDynamicfieldsService.readAllExtraFieldsByEntity(
            dynamicEntityType.INVENTORY_CHANGE,
          ),
  })
  dispatch(executeAction(actionTypes.GET_ALL_FIELDS_BY_ENTITY_WASTE, process))
}

export const fetchDynamicFieldsByEntityPurchaseExpense = () => dispatch => {
  const fields = getState(selectEntityPurchaseExpenseFields)

  const process = async () => ({
    dynamicFieldsByEntity:
      fields.length > 0
        ? fields
        : await CompanyDynamicfieldsService.readAllExtraFieldsByEntity(
            dynamicEntityType.PURCHASE_EXPENSE,
          ),
  })
  dispatch(executeAction(actionTypes.GET_ALL_FIELDS_BY_ENTITY_PURCHASE_EXPENSE, process))
}

/**
 *
 */
export const fetchDynamicFieldsValueByEntity =
  (entitytype: number, idclient: number) => dispatch => {
    const process = async () => ({
      dynamicFieldsValueByEntity:
        await CompanyDynamicfieldsService.readEntityDynamicFieldsValues(
          entitytype,
          idclient,
        ),
    })
    dispatch(executeAction(actionTypes.GET_ALL_FIELDS_BY_VALUE_ENTITY, process))
  }

/**
 * Creates a new dynamic field and dispatches actions to update the state.
 * @param extraField
 */
export const createDynamicField = extraField => dispatch => {
  const process = async () => {
    try {
      const createdField = await CompanyDynamicfieldsService.createExtraField(extraField)
      if (createdField) dispatch(fetchDynamicFields())
    } catch (error) {
      dispatch(createDynamicFieldError(error))
    }

    // const createdField = await CompanyDynamicfieldsService.createExtraField(extraField)
    // if (createdField)
    //   dispatch(fetchDynamicFields())
  }
  dispatch(executeAction(actionTypes.CREATE_FIELD, process))
}
/**
 * Creates a new dynamic field with selector options in bulk and dispatches an action to update the state.
 * @function
 * @param {Object} extraField - The dynamic field to be created.
 * @param {Array} options - The selector options to be created in bulk.
 * @returns {Function} A function that dispatches the CREATE_FIELD_WITH_OPTIONS action.
 */
export const createDynamicFieldWithSelectorOptionsBulk =
  (extraField, options) => dispatch => {
    const process = async () => {
      const createdField = await CompanyDynamicfieldsService.createWithOptions(
        extraField,
        options,
      )
      if (createdField) dispatch(fetchDynamicFields())
    }

    dispatch(executeAction(actionTypes.CREATE_FIELD_WITH_OPTIONS, process))
  }
/**
 * Edits a dynamic field with selector options in bulk and dispatches an action to update the state.
 * @function
 * @param {Object} extraField - The dynamic field to be edited.
 * @param {Array} options - The selector options to be edited in bulk.
 * @returns {Function} A function that dispatches the EDIT_FIELD_WITH_OPTIONS action.
 */
export const editDynamicFieldWithSelectorOptionsBulk =
  (extraField, options) => dispatch => {
    const process = async () => {
      const dynamicFieldsOptions =
        await CompanyDynamicfieldsService.editExtraFieldOptionsBulk(extraField, options)
    }

    dispatch(executeAction(actionTypes.EDIT_FIELD_WITH_OPTIONS, process))
  }
/**
 * Creates selector options in bulk from an edited dynamic field and dispatches an action to update the state.
 * @function
 * @param {Object} extraField - The edited dynamic field.
 * @param {Array} options - The selector options to be created in bulk.
 * @returns {Function} A function that dispatches the CREATE_FIELD_FROM_EDIT_WITH_OPTIONS action.
 */
export const createFromEditDynamicFieldWithSelectorOptionsBulk =
  (extraField, options) => dispatch => {
    const process = async () => {
      const dynamicFieldsOptions = await CompanyDynamicfieldsService.createOptionsBulk(
        extraField,
        options,
      )
    }
    dispatch(executeAction(actionTypes.CREATE_FIELD_FROM_EDIT_WITH_OPTIONS, process))
  }

/**
 * Deletes selector options in bulk from a dynamic field and dispatches an action to update the state.
 * @function
 * @param {Object} extraField - The dynamic field whose options are to be deleted.
 * @param {Array} options - The selector options to be deleted in bulk.
 * @returns {Function} A function that dispatches the DELETE_OPTIONS action.
 */
export const deleteDynamicFieldWithSelectorOptionsBulk =
  (extraField, options) => dispatch => {
    const process = async () => {
      const dynamicFieldsOptions =
        await CompanyDynamicfieldsService.deleteOptionsExtraBulk(extraField, options)
    }

    dispatch(executeAction(actionTypes.DELETE_OPTIONS, process))
  }

/**
 * Fetches the options for a specific dynamic field and dispatches an action to update the state.
 * @function
 * @param {string} fieldId - The ID of the dynamic field whose options are to be fetched.
 * @returns {Function} A function that dispatches the GET_FIELDS_OPTIONS action.
 */
export const fetchDynamicFieldsOptions = (fieldId: number) => dispatch => {
  const process = async () => ({
    dynamicFieldsOptions: await CompanyDynamicfieldsService.readExtraFieldOptions(
      fieldId,
    ),
  })

  dispatch(executeAction(actionTypes.GET_FIELDS_OPTIONS, process))
}
/**
 * Edits a dynamic field and dispatches actions to update the state.
 * @function
 * @param {Object} extraField - The dynamic field to be edited.
 * @param {string} idField - The ID of the dynamic field to be edited.
 * @returns {Function} A function that dispatches actions for editing a field and fetching all fields.
 */
export const editDynamicField = (extraField: DynamicField, idField) => dispatch => {
  const process = async () => {
    const editedField = await CompanyDynamicfieldsService.editExtraField(
      extraField,
      idField,
    )
    if (editedField) dispatch(fetchDynamicFields())
  }
  dispatch(executeAction(actionTypes.UPDATE_FIELD, process))
}

/**
 * Deletes a dynamic field and dispatches actions to update the state.
 * @function
 * @param {string} id - The ID of the dynamic field to be deleted.
 * @returns {Function} A function that dispatches actions for deleting a field and fetching all fields.
 */
export const deleteDynamicField = id => dispatch => {
  const process = async () => {
    const deletedField = await CompanyDynamicfieldsService.deleteExtraField(id)
    return deletedField
  }
  dispatch(executeAction(actionTypes.DELETE_FIELD, process))
}
