import React, { ReactElement } from 'react'
import { Col, Row } from 'react-bootstrap'

import { FormText, Select, SwitchV2 } from 'src/components/index'

import { DynamicFieldType } from 'src/enums/dynamicFieldsEnum'

export interface DynamicFieldSizes {
  xl?: number
  lg?: number
  md?: number
  sm?: number
  xs?: number
}

interface DynamicFieldInFormProps {
  fields: DynamicFieldEntity[]
  sizes?: DynamicFieldSizes
  onUpdate?: (fields: DynamicFieldEntity[]) => void
  disabled?: boolean
}

export const DynamicFieldInForm = ({
  fields,
  sizes,
  onUpdate,
  disabled = false,
}: DynamicFieldInFormProps) => {
  const renderDynamicField = ({
    id,
    dataType,
    label,
    description,
    value,
    required,
    options,
  }: DynamicFieldEntity): ReactElement => {
    switch (dataType) {
      case DynamicFieldType.TEXT_FIELD:
      case DynamicFieldType.NUMBER:
        return (
          <FormText
            label={label}
            info={description}
            type={DynamicFieldType.TEXT_FIELD === dataType ? 'text' : 'number'}
            value={value}
            required={required}
            changeValue={value => onChange(id, value)}
            disabled={disabled}
          />
        )
      case DynamicFieldType.SWITCH:
        return (
          <SwitchV2
            label={label}
            info={description}
            checked={value === 'true'}
            required={required}
            onChange={value => onChange(id, String(value))}
            disabled={disabled}
          />
        )
      case DynamicFieldType.SELECTOR:
        return (
          <Select
            label={label}
            info={description}
            required={required}
            value={{
              value,
              label: value ?? 'Sin seleccionar',
            }}
            options={options}
            onChange={value => onChange(id, value.value)}
            disabled={disabled}
          />
        )
      default:
        return <div />
    }
  }

  const onChange = (fieldId: number, value: string) => {
    const customFields = [...fields]
    const fieldIndex = customFields.findIndex(f => f.id === fieldId)
    customFields[fieldIndex].value = value
    console.log(value)
    if (onUpdate) onUpdate(customFields)
  }

  const { xl, lg, md, sm, xs }: DynamicFieldSizes = sizes ?? {
    xl: 4,
    lg: 4,
    md: 6,
    sm: 12,
    xs: 12,
  }

  return (
    <Row>
      {fields.map(field => (
        <Col key={field.id} xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
          {renderDynamicField(field)}
        </Col>
      ))}
    </Row>
  )
}
