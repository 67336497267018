import { useEffect, useState } from 'react'
import {
  mapFieldToEntity,
  validateRequiredFields,
} from 'src/components/CompanyDynamicFields/DynamicFieldFunctions'
import { showAlert } from 'src/actions/alert.actions'
import { handlerError } from 'src/selectors/error.selector'
import { useDispatch } from 'react-redux'

export const useDynamicFields = (
  fields: EntityFields[],
  withValues?: DynamicFieldRequest[],
) => {
  const dispatch = useDispatch()

  const [dynamicFields, setFields] = useState<DynamicFieldEntity[]>([])

  useEffect(() => {
    if (fields.length === 0) return
    setFields(mapFieldToEntity(fields, withValues))
  }, [fields.length > 0, JSON.stringify(withValues)])

  const validateFields = (): boolean => {
    const dynamicValidation = validateRequiredFields(dynamicFields)

    if (!dynamicValidation.ok) {
      dispatch(
        showAlert(
          handlerError(
            `Es necesario llenar los siguientes campos: ${dynamicValidation.fields}`,
          ),
        ),
      )
    }
    return dynamicValidation.ok
  }

  return { dynamicFields, setFields, validateFields }
}
