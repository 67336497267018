import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadingFields } from 'src/selectors/dynamicFields.selector'
import { TableV2 } from 'src/components'
import CreateDynamicFieldModal from './CreateDynamicFieldModal'
import {
  actionTypes as typeT,
  deleteDynamicField,
  fetchDynamicFields,
} from 'src/actions/dynamicfield.actions'
import { showAlert } from 'src/actions/alert.actions'
import { handlerInfoWithButtons, handlerSuccess } from 'src/selectors/error.selector'
import { isAllowed } from 'src/selectors/modules.selector'
import {
  dataTypeOptions,
  dynamicFieldsPermissions,
  entityOptions,
} from 'src/enums/dynamicFieldsEnum'
import { Dropdown } from 'src/components'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { loadingSelector } from '../../selectors/loading.selector'

interface DynamicFieldTableProps {
  dynamicFields?: DynamicField[]
}

const DynamicFieldTable: React.FC<DynamicFieldTableProps> = ({
  dynamicFields,
}: DynamicFieldTableProps) => {
  const dispatch = useDispatch()

  const allowEditCreateDynamicFields = useSelector(state =>
    isAllowed(state, [dynamicFieldsPermissions.editDynamicFields]),
  )
  const allowDeleteCreateDynamicFields = useSelector(state =>
    isAllowed(state, [dynamicFieldsPermissions.deleteDynamicFields]),
  )

  const deleteLoadingStattus = useSelector(state =>
    loadingSelector([typeT.DELETE_FIELD])(state),
  )
  const statusDynamicFieldReq = useSelector(loadingFields)

  const [modalCreateDymShow, setModalCreateDymShow] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [actions, setActions] = useState({ deleted: false })

  const deleteCurrent = item => {
    dispatch(
      showAlert({
        ...handlerInfoWithButtons('Eliminar Campo', 'Esta Seguro de Eliminar Este Campo'),
        onConfirm: () => {
          dispatch(deleteDynamicField(item))
        },
      }),
    )
  }

  useEffect(() => {
    if (deleteLoadingStattus) {
      setActions({ ...actions, deleted: true })
    }
    if (actions.deleted) {
      setActions({ ...actions, deleted: false })

      dispatch(showAlert(handlerSuccess('Campo Dinamico Eliminado')))
      dispatch(fetchDynamicFields())
    }
  }, [deleteLoadingStattus])

  const getDataType = id => {
    return dataTypeOptions?.find(item => item.id === Number(id))?.label
  }

  const headerFields: ITableHeader[] = [
    {
      label: 'Nombre',
      show: true,
      value: ['name'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Tipo de Entidad',
      show: true,
      type: 'text',
      value: [],
      className: 'medium',
      custom: item => (
        <p>{entityOptions?.find(client => client.id === item?.entityType)?.label}</p>
      ),
    },
    {
      label: 'Tipo de Dato',
      show: true,
      value: [],

      type: 'text',

      className: 'medium',
      custom: item => <p>{getDataType(item.dataType)}</p>,
    },

    {
      label: 'Descripcion',
      show: true,
      value: ['description'],
      type: 'text',
      className: 'medium',
    },
    {
      label: 'Requerido',
      show: true,
      value: [],
      type: 'text',
      className: 'medium',
      custom: item => <p>{returnStringRequired(item.required)}</p>,
    },

    {
      label: 'Acciones',
      show: true,
      type: '',
      value: [],
      className: 'medium',
      custom: item => (
        <>
          <Dropdown
            data-testid={'dropdownTab'}
            items={[
              {
                title: 'Editar',

                action: () => {
                  setCurrentItem(item)
                  setModalCreateDymShow(true)
                },
                icon: faEdit,
                show: allowEditCreateDynamicFields,
              },
              {
                title: 'Eliminar',

                action: () => deleteCurrent(item.id),
                icon: faTrash,
                show: allowDeleteCreateDynamicFields,
              },
            ]}
          />
        </>
      ),
    },
  ]

  const returnStringRequired = value => {
    return value ? 'SI' : 'NO'
  }

  return (
    <>
      <TableV2
        // @ts-ignore
        headers={headerFields}
        items={dynamicFields}
        mobileAuto
        loading={statusDynamicFieldReq}
      />

      <CreateDynamicFieldModal
        show={modalCreateDymShow}
        onHide={() => setModalCreateDymShow(false)}
        mode={'edit'}
        item={currentItem}
      />
    </>
  )
}
export default DynamicFieldTable
