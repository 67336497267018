import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Row, Col, Modal, ModalHeader, ModalTitle, ModalBody } from 'react-bootstrap'
import {
  TableV2,
  Select,
  SelectedDates,
  Switch,
  Title,
  Card,
  FAB,
  Button,
  CustomDate,
  FormText,
  ClientDetailResume,
  Paragraph,
  Icon,
} from 'src/components'
import {
  faBoxes,
  faCheckDouble,
  faEllipsisV,
  faTrash,
  faWarehouse,
  faPlus,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons'

import {
  actionTypes,
  getPendingMissingRequest,
  onUpdateStatusPendingMissingRequest,
  onCreateDispatchVisits,
  getWarehouseLite,
  onCreateTransferMissingRequest,
} from 'src/actions/warehouse.actions'
import {
  selectInventoryMissingResponse,
  selectOwnWarehouseLite,
} from 'src/selectors/warehouse.selector'

import {
  types as typesInventory,
  getPendingMissingRequestExcel,
} from 'src/actions/inventory.actions'

import { loadingSelector } from 'src/selectors/loading.selector'
import { handlerError, handlerSuccess, hasErrors } from 'src/selectors/error.selector'

import { getUsersChildrenByModule } from 'src/actions/modules.actions'
import {
  isAllowed,
  selectCurrentUserRoles,
  selectUsers,
} from 'src/selectors/modules.selector'

import { formatNumberWithCommas } from 'src/utils/formatters'
import { showAlert } from 'src/actions/alert.actions'

import DispatchPartial from './DispatchPartial'
import { dispatchPermissions } from 'src/enums/permissions'
import { selectCurrentUser } from 'src/selectors/user.selector'

interface IStateProps {
  value: number
  state?: number
  label: string
  otherSteps?: IStateProps[]
}

interface IVisitDispatchProp {
  show?: boolean
  transfer?: boolean
  description?: string
  toWarehouse?: ISelect
  attendant?: ISelect
  date?: Date
}

interface IInventoryTransfer {
  show?: boolean
  selected?: any[]
  return?: boolean
  returnValue?: boolean
  warehouse?: ISelect
  description?: string
}

const states: IStateProps[] = [
  { value: 1, state: 1, label: 'Pendientes', otherSteps: [] },
  {
    value: 2,
    label: 'Ruta',
    otherSteps: [
      { value: 3, state: 2, label: 'Transferencia pendiente' },
      { value: 4, state: 3, label: 'Transferencia aprobada' },
      { value: 5, state: 4, label: 'En tránsito' },
    ],
  },
  { value: 6, state: 5, label: 'Aprobados' },
  {
    value: 7,
    label: 'Rechazadas',
    otherSteps: [
      {
        value: 8,
        state: 6,
        label: 'Pendientes de devolución',
      },
      {
        value: 9,
        state: 7,
        label: 'Devueltas',
      },
    ],
  },
]

const types: ISelectNumber[] = [
  { value: 1, label: 'Ventas' },
  { value: 7, label: 'Manufacturas' },
]

const dateTypes: ISelectNumber[] = [
  { value: 0, label: 'Fecha de Ingreso' },
  { value: 1, label: 'Fecha de Confirmación' },
  { value: 2, label: 'Fecha de Despacho' },
]

const validateStates = (states, currentState): boolean =>
  states.some(s => s === currentState)
const getDocumentType = (type: number): string =>
  types.find(t => t.value === type)?.label || 'Desconocido'

const Dispatch = () => {
  const dispatch = useDispatch()

  const user = useSelector(selectCurrentUser)
  const users = useSelector(selectUsers)
  const warehouses = useSelector(state => selectOwnWarehouseLite(state))
  const roles = useSelector(selectCurrentUserRoles)

  const viewAll: boolean = useSelector(state =>
    isAllowed(state, [dispatchPermissions.viewAll]),
  )
  const canApprove: boolean = useSelector(state =>
    isAllowed(state, [dispatchPermissions.approve]),
  )
  const canCancel: boolean = useSelector(state =>
    isAllowed(state, [dispatchPermissions.reject]),
  )
  const canDownloadFile: boolean = useSelector(state =>
    isAllowed(state, [dispatchPermissions.downloadFile]),
  )

  const response = useSelector(selectInventoryMissingResponse)
  const { items, total } = response

  const loading: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_INVENTORY_MISSING_ITEMS])(state),
  )

  const loadingWarehouses: boolean = useSelector(state =>
    loadingSelector([actionTypes.GET_WAREHOUSE_LITE])(state),
  )

  const loadingUpdate: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_UPDATE_STATUS_PENDING_MISSING_REQUEST])(state),
  )
  const errorUpdate = useSelector(state =>
    hasErrors([actionTypes.ON_UPDATE_STATUS_PENDING_MISSING_REQUEST])(state),
  )

  const loadingDispatch: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_VISIT_DISPATCH])(state),
  )
  const errorDispatch = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_VISIT_DISPATCH])(state),
  )

  const loadingTransfer: boolean = useSelector(state =>
    loadingSelector([actionTypes.ON_CREATE_TRANSFER_MISSING_REQUEST])(state),
  )
  const hasErrorTransfer = useSelector(state =>
    hasErrors([actionTypes.ON_CREATE_TRANSFER_MISSING_REQUEST])(state),
  )

  const loadingExcel: boolean = useSelector(state =>
    loadingSelector([typesInventory.GET_PENDING_MISSING_REQUEST_EXCEL])(state),
  )
  const hasErrorExcel = useSelector(state =>
    hasErrors([typesInventory.GET_PENDING_MISSING_REQUEST_EXCEL])(state),
  )

  const [flags, setFlags] = useState({
    create: false,
    update: false,
    allType: null,
    id: null,
    dispatch: false,
    warehouses: false,
    excel: false,
  })

  const [filters, setFilters] = useState({
    start: null,
    end: null,
    documentType: { value: null, label: 'Todos' },
    state: { value: 1, label: 'Pendientes' },
    initial: 0,
    size: 10,
    search: null,
    user: { value: null, label: 'Todos' },
    dateType: dateTypes[0],
  })

  const [filtersTxt, setFiltersTxt] = useState<string>(null)
  const [seeBase, setSeeBase] = useState<boolean>(false)
  const [all, setAll] = useState<boolean>(false)
  const [showChange, setShowChange] = useState<boolean>(false)
  const [showDispatchSelected, setDispatchSelected] = useState({
    show: false,
    selected: [],
    state: 0,
  })
  const [clientId, setClientId] = useState<number>(0)

  const [warehouse, setWarehouse] = useState<ISelect>({})
  const [visitDispatch, setVisitDispatch] = useState<IVisitDispatchProp>({})
  const [inventoryTransferModal, setInventoryTransferModal] =
    useState<IInventoryTransfer>({})
  const [newWarehouse, setNewWarehouse] = useState<ISelect>({})
  const [selected, setSelected] = useState([])

  const showApprove: boolean =
    canApprove && (selected.length > 0 || all) && (!loadingUpdate || flags.allType === 3)
  const showCancel: boolean =
    canCancel && (selected.length > 0 || all) && (!loadingUpdate || flags.allType === 2)

  useEffect(() => {
    if (!roles || roles.length <= 0) return

    setFiltersTxt(JSON.stringify(filters))
    const presentationFactor = localStorage.getItem('presentation-factor-missing')
    setSeeBase(presentationFactor === 'true')

    if (users.length === 0) dispatch(getUsersChildrenByModule(1000, true))

    if (warehouses.length === 0) dispatch(getWarehouseLite())
    else setWarehouse(warehouses[0])
  }, [roles?.length])

  useEffect(() => {
    if (!warehouse.id) return
    setUp()
  }, [warehouse.id])

  useEffect(() => {
    if (
      filtersTxt === null ||
      filtersTxt === '' ||
      JSON.stringify(filters) === filtersTxt
    )
      return
    setFiltersTxt(JSON.stringify(filters))
  }, [filters])

  useEffect(() => {
    if (filtersTxt === null || filtersTxt === '') return
    setUp()
  }, [filtersTxt])

  useEffect(() => {
    if (loadingWarehouses) setFlags({ ...flags, warehouses: true })
    else if (flags.warehouses && warehouses.length > 0) setWarehouse(warehouses[0])
  }, [loadingWarehouses])

  useEffect(() => {
    if (loadingUpdate) setFlags({ ...flags, update: true })
    else if (flags.update) {
      setFlags({ ...flags, update: false, id: null, allType: null })
      setTimeout(() => {
        if (errorUpdate)
          dispatch(
            showAlert({
              ...handlerError(errorUpdate.message),
            }),
          )
        else if (
          visitDispatch.show &&
          visitDispatch.transfer &&
          filters.state?.value === 1
        ) {
          onCreateTransfer({
            description: visitDispatch.description,
            fromWarehouseId: warehouse.value,
            toWarehouseId: visitDispatch?.toWarehouse?.value,
            returnInventory: false,
          })
        } else {
          setDispatchSelected({ ...showDispatchSelected, show: false })
          setAll(false)
          setSelected([])
          setUp()
          dispatch(
            showAlert({
              ...handlerSuccess('Despachos pendientes actualizados satisfactoriamente'),
            }),
          )
          setVisitDispatch({})
        }
      }, 300)
    }
  }, [loadingUpdate])

  useEffect(() => {
    if (loadingDispatch) setFlags({ ...flags, dispatch: true })
    else if (flags.dispatch) {
      setFlags({ ...flags, dispatch: false })
      if (errorDispatch)
        dispatch(
          showAlert({
            ...handlerError(errorDispatch.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Operación exitosa'),
            onConfirm: () => {
              setVisitDispatch({ show: false })
              setAll(false)
              setSelected([])
              setUp()
            },
          }),
        )
    }
  }, [loadingDispatch])

  useEffect(() => {
    if (loadingTransfer) setFlags({ ...flags, create: true })
    else if (flags.create) {
      setFlags({ ...flags, create: false })
      if (hasErrorTransfer)
        dispatch(
          showAlert({
            ...handlerError(hasErrorTransfer.message),
          }),
        )
      else
        dispatch(
          showAlert({
            ...handlerSuccess('Operación realizada con éxito'),
            onConfirm: () => {
              setInventoryTransferModal({ show: false })
              setVisitDispatch({})
              setAll(false)
              setSelected([])
              setUp()
            },
          }),
        )
    }
  }, [loadingTransfer])

  useEffect(() => {
    if (loadingExcel) setFlags({ ...flags, excel: true })
    else if (flags.excel) {
      setFlags({ ...flags, excel: false })
      if (hasErrorExcel) dispatch(showAlert({ ...handlerError(hasErrorExcel.message) }))
    }
  }, [loadingExcel])

  const setUp = (excel?: boolean) => {
    if (!warehouse.id) return
    const params = {
      ...filters,
      documentType: filters?.documentType?.value || null,
      state: filters?.state?.value || null,
      userId: viewAll ? filters?.user?.value : user.id,
      dateType: filters.dateType.value,
    }
    delete params.user

    if (excel) dispatch(getPendingMissingRequestExcel(warehouse.id, params))
    else dispatch(getPendingMissingRequest(warehouse.id, params))
  }

  const getSubItems = (subItems, type, defaultValue) => {
    if (!subItems || subItems.length <= 0) {
      return <div>{defaultValue}</div>
    }
    return (
      <div>
        {seeBase && <div>{defaultValue}</div>}
        <ul className={'base-sub-items'}>
          {subItems.map(subItem => (
            <li key={subItem.id}>
              <div>
                {type === 1 && subItem.code}
                {type === 2 && subItem.name}
                {type === 3 && formatNumberWithCommas(subItem.existence)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const headers = [
    {
      show: true,
      checked: all,
      label: 'Código',
      value: ['documentCode'],
      type: 'text',
      custom: item => `${getDocumentType(item.documentType)}: ${item.documentCode}`,
    },
    {
      show: true,
      label: 'Cliente',
      value: ['referenceName'],
      type: 'text',
      custom: item => {
        const split = item.referenceName?.split(',') || []
        return <div>{split.length > 0 ? split[0] : null}</div>
      },
    },
    {
      show: true,
      label: 'Referencia',
      value: ['url'],
      type: 'text',
    },
    {
      show: true,
      label: 'SKU',
      value: ['code'],
      type: 'text',
      style: { minWidth: 120 },
      custom: item => getSubItems(item.subItems, 1, item.code),
    },
    {
      show: true,
      label: 'Nombre',
      value: ['name'],
      type: 'text',
      style: { minWidth: 200 },
      custom: item => getSubItems(item.subItems, 2, item.name),
    },
    {
      show: true,
      label: 'Cantidad',
      type: 'number',
      style: { minWidth: 120 },
      custom: item =>
        getSubItems(item.subItems, 3, formatNumberWithCommas(item.existence)),
    },
    {
      show: false,
      label: 'Variaciones',
      value: ['categories'],
      type: 'text',
      style: { minWidth: 120 },
      custom: item => item.listCategorization?.map(c => c.name).join(', '),
    },
    {
      show: false,
      label: 'Ubicaciones',
      value: ['location'],
      type: 'text',
      style: { minWidth: 120 },
      custom: item => item.listLocations?.map(c => c.name).join(', '),
    },
    {
      show: true,
      label: 'Ingresado Por',
      value: ['createdData', 'name'],
      type: 'text',
    },
    {
      show: true,
      label: 'Ingresado en',
      value: ['audit', 'createdAt'],
      type: 'date',
    },
    {
      show: true,
      label: 'Confirmado en',
      value: ['audit', 'confirmedAt'],
      type: 'date',
    },
    {
      show: true,
      label: 'Fecha de Despacho',
      value: ['audit', 'dispatchAt'],
      type: 'date',
    },
    {
      show: true,
      label: 'Actualizado por',
      value: ['updatedData', 'name'],
      type: 'text',
    },
    {
      show: true,
      label: 'Actualizado en',
      value: ['updatedAt'],
      type: 'date',
      config: true,
    },
  ]

  const isSelected = item => {
    const select = selected.find(s => item.id === s.id)
    return select !== undefined
  }

  const addOrRemoveSelected = item => {
    setAll(false)
    const cs = Object.assign([], selected)
    if (isSelected(item) && !all) {
      setSelected(cs.filter(s => s.id !== item.id))
    } else {
      cs.push(item)
      setSelected(cs)
    }
  }

  const onUpdate = (
    state: number,
    items?: any[],
    withoutAlert?: boolean,
    desc?: string,
  ) => {
    const onAction = (desc?: string) => {
      setFlags({ ...flags, allType: state })
      const data = []
      if (items) data.push(...items)
      else if (!all) {
        data.push(...selected)
      }

      const request = []

      data.reduce(
        (value, item) =>
          request.push({
            inventoryId: item.id,
            type: item.documentType,
            productId: item.saleRecipe,
            invoice: item.documentCode,
            typeAdjust: item.typeAdjust,
            quantity: item.quantity,
          }),
        [],
      )

      dispatch(
        onUpdateStatusPendingMissingRequest(request, {
          state,
          description: desc,
          start: filters.start,
          end: filters.end,
          search: filters.search,
          warehouseId: warehouse.id,
          userId: filters?.user.value,
        }),
      )
    }

    if (withoutAlert) {
      onAction(desc)
      return
    }

    const title = state === 7 ? 'Rechazar' : 'Despachar'
    const text =
      state === 7
        ? '¿Desea rechazar el despacho pendiente? Está acción no podrá ser revertida.'
        : '¿Desea realizar el despacho pendiente? Está acción no podrá ser revertida.'

    dispatch(
      showAlert({
        show: true,
        title,
        text,
        type: 'input',
        inputPlaceholder: 'Descripción',
        showCancelButton: true,
        cancelButtonText: 'Cerrar',
        confirmButtonText: 'Continuar',
        confirmButtonColor: '#226095',
        onConfirm: desc => onAction(desc),
      }),
    )
  }

  const createDispatchVisits = () => {
    const data = []
    if (!all) data.push(...selected)

    const request = []
    data
      .filter(item => item.referenceId && item.documentType === 1)
      .forEach(item =>
        request.push({
          id: item.id,
          existence: item.existence,
          subProductId: item.subProductId,
          saleRecipeId: item.saleRecipeId,
          referenceId: item.referenceId,
          documentCode: item.documentCode,
          categories: item.categories,
          locations: item.locations,
        }),
      )

    dispatch(
      onCreateDispatchVisits(request, {
        start: filters.start,
        end: filters.end,
        search: filters.search,
        warehouseId: warehouse.id,
        attendantId: visitDispatch.attendant.value,
        date: visitDispatch.date.valueOf(),
        userId: filters?.user.value,
      }),
    )
  }

  const customFilter = (
    <Col xl={12}>
      <Row>
        <Col xl={4} lg={4} md={6} sm={12}>
          <Select
            label={'Tipo de fecha'}
            options={dateTypes}
            value={filters.dateType}
            onChange={dateType => setFilters({ ...filters, dateType })}
          />
        </Col>

        {viewAll && (
          <Col xl={4} lg={4} md={6} sm={12}>
            <Select
              label={'Usuario'}
              options={[{ label: 'Todos', value: null }, ...users]}
              value={filters.user}
              onChange={user => setFilters({ ...filters, user })}
              info={'Quien confirmó la venta'}
            />
          </Col>
        )}
        <Col xl={4} lg={4} md={6} sm={12}>
          <Switch
            mt={3}
            topLabel
            label={'Ver cantidad respecto al ítem base'}
            info={'Se visualizaran las cantidades de los ítems en unidades del ítem base'}
            checked={seeBase}
            changeValue={value => {
              localStorage.setItem('presentation-factor-missing', value)
              setSeeBase(value)
            }}
          />
        </Col>
      </Row>
    </Col>
  )

  const customTabs = steps => (
    <Tabs
      defaultIndex={0}
      onSelect={index => {
        setSelected([])
        setAll(false)
        const step = steps[index]
        const newStatus =
          step.otherSteps && step.otherSteps.length > 0
            ? step.otherSteps[0].state
            : step.state
        setFilters({ ...filters, state: { value: newStatus, label: 'undefined' } })
      }}>
      <TabList>
        {steps.map((item, index) => (
          <Tab key={index}>
            <div className={'d-flex'}>
              <div>{item.label}</div>
            </div>
          </Tab>
        ))}
      </TabList>
      {steps.map((item, index) => (
        <TabPanel key={index}>
          {item.otherSteps && item.otherSteps.length > 0
            ? customTabs(item.otherSteps)
            : customTable()}
        </TabPanel>
      ))}
    </Tabs>
  )

  const customTable = () => (
    <TableV2
      // @ts-ignore
      loading={loading}
      customClass={'scrollX'}
      headers={headers}
      items={items}
      total={total}
      placeholder={'Buscar por nombre/código'}
      noItemsLegend={`No hay despachos pendientes con los filtros aplicados`}
      getPagination={(initial, size, search) =>
        setFilters({ ...filters, initial, size, search })
      }
      handleChange={search => setFilters({ ...filters, search })}
      storageKey={'warehouseInventoryMissing'}
      mobileAuto
      checked={all}
      onCheck={value => {
        setAll(value)
        setSelected([])
      }}
      isSelected={item => isSelected(item) || all}
      onClickTr={(e, item) => addOrRemoveSelected(item)}
      customFilter={customFilter}
      getItems={item => {
        if (item === null) setSelected([])
        else addOrRemoveSelected(item.item)
      }}
    />
  )

  const onCreateTransfer = ({
    description,
    fromWarehouseId,
    toWarehouseId,
    returnInventory,
  }) => {
    const transferItems = (all ? [] : inventoryTransferModal.selected).map(item => ({
      productId: item.subProductId,
      quantity: item.existence,
      variations: item.listCategorization?.map(c => c.id) || [],
      listIdLocations: item.listLocations?.map(l => l.id) || [],
      orderId: item.id,
      cost: item.price,
    }))

    dispatch(
      onCreateTransferMissingRequest(transferItems, {
        description,
        fromWarehouseId,
        toWarehouseId,
        returnInventory,
        start: filters.start,
        end: filters.end,
        search: filters.search,
        userId: filters?.user.value,
        state: filters?.state?.value,
      }),
    )
  }

  return (
    <>
      <Title title={'Despachos pendientes'} />

      <Card
        title={'Solicitudes de despachos'}
        white
        button={
          canDownloadFile && (
            <Icon
              spin={loadingExcel}
              icon={faFileExcel}
              tooltip={'Descargar reporte'}
              onClick={() => setUp(true)}
            />
          )
        }>
        {warehouses.length === 0 && (
          <h3 style={{ margin: '0 auto' }}>No tiene ninguna bodega asignada</h3>
        )}
        <Row>
          <Col xl={12} md={12}>
            <SelectedDates
              withOptionNull
              onDateChange={(start, end) => setFilters({ ...filters, start, end })}
            />
          </Col>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Select
              label={'Bodega'}
              options={warehouses}
              value={warehouse}
              onChange={warehouse => {
                setWarehouse(warehouse)
              }}
            />
          </Col>

          <Col xl={12}>{customTabs(states)}</Col>
        </Row>
      </Card>

      <Modal
        show={showChange}
        size={'lg'}
        centered
        aria-labelledby={'contained-modal-title-vcenter'}
        onHide={() => {
          setNewWarehouse({})
          setShowChange(false)
        }}>
        <ModalHeader closeButton>
          <ModalTitle></ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <h4>{'Actualizar bodega'}</h4>
            <br />
            <h5>{'Ingrese una bodega destino'}</h5>
          </div>
          <Select
            loading={loadingWarehouses}
            label={'Bodegas'}
            options={warehouses.filter(x => x.id !== warehouse.id)}
            value={newWarehouse}
            onChange={warehouse => {
              setNewWarehouse(warehouse)
            }}
          />
        </ModalBody>
        <Modal.Footer>
          <Button
            loading={loadingUpdate}
            color={'secondary'}
            onClick={() => {
              setNewWarehouse({})
              setShowChange(false)
            }}>
            Cancelar
          </Button>

          <Button
            loading={loadingUpdate}
            color={'primary'}
            disabled={!newWarehouse.id}
            onClick={() => {
              const data = []
              if (!all) {
                data.push(...selected)
              }

              const request = []

              data.reduce(
                (value, item) =>
                  request.push({
                    inventoryId: item.id,
                    type: item.documentType,
                    productId: item.saleRecipe,
                    invoice: item.url,
                  }),
                [],
              )
              setShowChange(false)
              dispatch(
                onUpdateStatusPendingMissingRequest(request, {
                  start: filters.start,
                  end: filters.end,
                  search: filters.search,
                  userId: filters?.user.value,
                  warehouseId: warehouse.id,
                  updateW: true,
                  newWarehouseId: newWarehouse.id,
                }),
              )
            }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={visitDispatch.show}
        size={'lg'}
        centered
        onHide={() =>
          loadingDispatch || loadingUpdate || loadingTransfer
            ? undefined
            : setVisitDispatch({})
        }>
        <Modal.Header
          closeButton={!loadingDispatch && !loadingUpdate && !loadingTransfer}>
          <Modal.Title>
            {filters.state?.value === 1
              ? 'Ruta de preparación de despacho'
              : 'Programar visitas de despacho'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {filters.state?.value === 1 ? (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <Paragraph>
                      Esta acción creará una ruta de preparación de despacho con los items
                      seleccionados. Los ítems tendrán un estado nuevo llamado: &#34;Ruta
                      &#34; y tendrán como procesos a seguir: transferencia de inventario
                      y generar visitas de despachos de los ítems ya transferidos.
                    </Paragraph>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <Select
                      disabled={loadingDispatch}
                      label={'Responsable'}
                      options={users}
                      value={
                        visitDispatch.attendant || {
                          value: null,
                          label: 'Sin seleccionar',
                        }
                      }
                      onChange={v => setVisitDispatch({ ...visitDispatch, attendant: v })}
                      required
                    />
                  </Col>
                  <Col xl={12}>
                    <CustomDate
                      disabled={loadingDispatch}
                      label={'Fecha de las visitas'}
                      value={visitDispatch.date}
                      disabledDays={{}}
                      onDayChange={v => setVisitDispatch({ ...visitDispatch, date: v })}
                      required
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row className={'container-buttons'}>
            <Button
              loading={loadingDispatch || loadingUpdate || loadingTransfer}
              disabled={
                filters.state?.value === 1
                  ? visitDispatch.transfer && !visitDispatch.toWarehouse
                  : !visitDispatch.attendant || !visitDispatch.date
              }
              onClick={() => {
                if (filters.state?.value === 1) {
                  onUpdate(2, null, true)
                } else createDispatchVisits()
              }}
              icon={faBoxes}>
              Generar
            </Button>
          </Row>
        </Modal.Footer>
      </Modal>

      <Modal
        show={inventoryTransferModal.show}
        centered
        size={'lg'}
        onHide={() => setInventoryTransferModal({ show: false })}>
        <Modal.Header closeButton>
          <Modal.Title>
            {inventoryTransferModal.return
              ? 'Rechazar y devolución de inventario'
              : 'Transferencia de inventario'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {inventoryTransferModal.return && (
              <Col xl={12}>
                <Row>
                  <Col xl={12}>
                    <Paragraph>
                      Está acción rechazara todos los despachos seleccionados y si se
                      tiene la configuración de reserva de inventario, al rechazarlas, los
                      ítems quedarán disponibles en bodega.
                    </Paragraph>
                  </Col>

                  <Col xl={12}>
                    <div className={'mt-3 column'}>
                      <Paragraph>
                        ¿Desea realizar una devolución de inventario? Los items
                        seleccionados quedarán en el estado &quot;Pendiente de
                        devolución&quot; y creará una transferencia de inventario. Cuando
                        está transferencia sea aprobada, los ítems cambiarán al estado
                        &quot;Devueltas&quot;.
                      </Paragraph>
                      <Switch
                        checked={inventoryTransferModal.returnValue}
                        changeValue={v =>
                          setInventoryTransferModal({
                            ...inventoryTransferModal,
                            returnValue: v,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            <Col xs={12}>
              <Select
                disabled
                label={'Bodega a despachar'}
                options={warehouses}
                value={warehouse}
                onChange={warehouse => {
                  setWarehouse(warehouse)
                }}
              />
            </Col>
            <Col xs={12}>
              <Select
                disabled={
                  inventoryTransferModal.return
                    ? !inventoryTransferModal.returnValue
                    : false
                }
                label={'Bodega receptora'}
                options={warehouses.filter(w => w.value !== warehouse.value)}
                value={inventoryTransferModal.warehouse}
                onChange={warehouse => {
                  setInventoryTransferModal({ ...inventoryTransferModal, warehouse })
                }}
              />
            </Col>
            <Col xs={12}>
              <FormText
                disabled={
                  inventoryTransferModal.return
                    ? !inventoryTransferModal.returnValue
                    : false
                }
                label={'Descripción'}
                placeholder={'Descripción o concepto para la transferencia (Opcional)'}
                value={inventoryTransferModal.description}
                onChange={({ target }) => {
                  const { value } = target
                  setInventoryTransferModal({
                    ...inventoryTransferModal,
                    description: value,
                  })
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className={'container-buttons'}>
          <Button
            loading={loadingTransfer}
            disabled={!inventoryTransferModal.warehouse}
            color={'primary'}
            onClick={() =>
              onCreateTransfer({
                description: inventoryTransferModal.description,
                fromWarehouseId: warehouse?.value,
                toWarehouseId: inventoryTransferModal?.warehouse?.value,
                returnInventory: inventoryTransferModal.return,
              })
            }>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <DispatchPartial
        {...showDispatchSelected}
        onHide={() => setDispatchSelected({ ...showDispatchSelected, show: false })}
        onConfirm={(items, desc, state) => onUpdate(state, items, true, desc)}
      />

      <ClientDetailResume id={clientId} onHide={() => setClientId(0)} />

      <FAB
        show={showApprove || showCancel}
        loading={loadingUpdate || loadingDispatch || loadingTransfer}
        items={[
          {
            text: 'Crear transferencia de inventario',
            icon: faPlus,
            show: validateStates([2, 3], filters.state.value),
            loading: (selected.length > 0 || all) && loadingUpdate && flags.allType === 2,
            action: () => setInventoryTransferModal({ show: true, selected }),
          },
          {
            text: 'Actualizar Bodega',
            icon: faWarehouse,
            show: false, // selected.length > 0 || all,
            loading: (selected.length > 0 || all) && loadingUpdate && flags.allType === 2,
            action: () => setShowChange(true),
          },
          {
            text:
              filters.state?.value === 1
                ? 'Generar ruta de preparación de despachos'
                : 'Generar ruta de visitas',
            icon: faBoxes,
            show: validateStates([1, 3], filters.state.value),
            loading: (selected.length > 0 || all) && loadingUpdate && flags.allType === 2,
            action: () => setVisitDispatch({ show: true, date: new Date() }),
          },
          {
            text: 'Rechazar despachos',
            icon: faTrash,
            show: validateStates([1, 3, 4, 6], filters.state.value),
            loading: (selected.length > 0 || all) && loadingUpdate && flags.allType === 2,
            action: () => {
              if (filters.state.value === 1) onUpdate(7)
              else
                setInventoryTransferModal({
                  show: true,
                  return: true,
                  selected,
                })
            },
          },
          {
            text: 'Despachar',
            icon: faCheckDouble,
            show: filters.state.value === 1,
            loading: (selected.length > 0 || all) && loadingUpdate && flags.allType === 3,
            action: () => {
              if (selected.length > 0)
                setDispatchSelected({
                  show: true,
                  selected: selected.map(s => ({
                    ...s,
                    documentTypeName: getDocumentType(s.documentType),
                  })),
                  state: 5,
                })
              else onUpdate(5)
            },
          },
        ]}
        icon={faEllipsisV}
      />
    </>
  )
}

export default Dispatch
