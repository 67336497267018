import React, { ReactElement } from 'react'
import { DynamicFieldSizes } from 'src/components/CompanyDynamicFields/presentations/DynamicFieldInForm'
import { Col, Row } from 'react-bootstrap'
import { DynamicFieldType } from 'src/enums/dynamicFieldsEnum'

export const ReadDynamicFieldInForm = ({
  fields,
  sizes,
}: {
  sizes?: DynamicFieldSizes
  fields: DynamicFieldValueLabel[]
}): ReactElement => {
  const { xl, lg, md, sm, xs }: DynamicFieldSizes = sizes ?? {
    xl: 4,
    lg: 4,
    md: 6,
    sm: 12,
    xs: 12,
  }

  return (
    <Row>
      {fields.map(({ id, label, value, type }) => {
        let v = value
        if (type === DynamicFieldType.SWITCH) v = value === 'true' ? 'Activo' : 'Inactivo'

        return (
          <Col key={id} xl={xl} lg={lg} md={md} sm={sm} xs={xs}>
            <div className={'column'}>
              <b>{label}</b>
              {v ?? '-'}
            </div>
          </Col>
        )
      })}
    </Row>
  )
}
